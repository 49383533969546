import React, { useEffect, useState, useContext } from "react";
import { FiCheck } from "react-icons/fi";
import { getWebsiteConfig, openStripeCheckout } from "../utils/apis";
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemInLocalStorage } from "../utils/utils";
import { useHistory, withRouter } from "react-router-dom";
import LeftNavbar from "../utils/left-navbar";
import { PageHeading, SectionHeading } from "../utils/typography";
import { PrimaryButton } from "../utils/buttons";
import WebsiteContext from "../context/website-context";
import { PulseLoader } from "react-spinners";

function Upgrade(props) {
    const history = useHistory();

    const [billingCycle, setBillingCycle] = useState("monthly");
    const [errorMessage, setErrorMessage] = useState(null);
    const [activateBtnIsLoading, setActivateBtnIsLoading] = useState(false);

    const websiteConfig = useContext(WebsiteContext);

    const openCheckout = async (billingCycle) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            history.push("/");
            return;
        }

        setActivateBtnIsLoading(true);

        const resp = await openStripeCheckout(billingCycle);
        // console.log(resp);
        if (resp.error === "Signature has expired.") {
            history.push("/login");
            return;
        }
        window.open(resp.data.checkout_session_url, "_self", "noreferrer");
        setActivateBtnIsLoading(false);
    };

    useEffect(() => {
        setItemInLocalStorage("billing-cycle", billingCycle);
    }, [billingCycle]);

    useEffect(() => {
        document.title = "Upgrade - hagrid";
    }, []);

    return (
        <>
            <div className="flex">
                <LeftNavbar />

                <div className="pr-12 pl-28 lg:pl-[17rem] pb-20 w-full">
                    <div className="pt-12 pb-8">
                        <PageHeading text="Upgrade Now" />
                    </div>
                    {websiteConfig.websiteConfigIsLoading ? (
                        <div className="flex justify-center mt-40">
                            <PulseLoader color="#e879f9" />
                        </div>
                    ) : (
                        <div className="bg-white rounded-2xl px-12 py-10 w-full max-w-2xl shadow-container">
                            <SectionHeading text="One simple plan. Unlimited everything, always." />
                            {websiteConfig.trialStatus === "active" ? (
                                <p className="antialiased mt-2 mb-12 text-base text-zinc-800">
                                    {websiteConfig.trialDaysLeft} days left in your free trial.
                                    <br />
                                    Activate the paid plan today, to continue using hagrid after the free trial.
                                    <br />
                                    Save 20% with the annual plan.
                                </p>
                            ) : (
                                <p className="antialiased mt-2 mb-12 text-base text-zinc-800">
                                    Your free trial is over. The hagrid widget on your site is now hidden.
                                    <br />
                                    Activate the paid plan today, to continue using hagrid.
                                    <br />
                                    Save 20% with the annual plan.
                                </p>
                            )}

                            <div className="flex flex-row items-center">
                                <div
                                    className={`${
                                        billingCycle === "monthly"
                                            ? "bg-fuchsia-50 border border-purple-200"
                                            : "hover:bg-zinc-50 cursor-pointer border border-zinc-200"
                                    } w-1/2 text-sm text-zinc-800 py-4 px-8 rounded-lg bg-white relative font-normal antialiased mr-2`}
                                    onClick={() => setBillingCycle("monthly")}
                                >
                                    <div
                                        className={`${
                                            billingCycle === "monthly"
                                                ? "border-purple-500 bg-purple-500"
                                                : "border-zinc-200"
                                        } flex items-center justify-center rounded-full border absolute w-7 h-7 right-3`}
                                    >
                                        <FiCheck
                                            className={`${
                                                billingCycle === "monthly" ? "block" : "hidden"
                                            } text-lg text-white`}
                                        />
                                    </div>
                                    <p className="font-semibold text-base text-zinc-600">Monthly Plan</p>
                                    <p className="text-zinc-800 text-3xl font-semibold mt-4 tracking-tight">
                                        $ 9.99{" "}
                                        <span className="text-base font-medium tracking-normal text-zinc-600">
                                            /month
                                        </span>
                                    </p>
                                    <p className="font-normal text-sm text-zic-600 mt-2">billed monthly</p>
                                </div>
                                <div
                                    className={`${
                                        billingCycle === "yearly"
                                            ? "bg-fuchsia-50 border border-purple-200"
                                            : "hover:bg-zinc-50 cursor-pointer border border-zinc-200"
                                    } w-1/2 text-sm text-zinc-800 py-4 px-8 rounded-lg bg-white relative font-normal antialiased ml-2`}
                                    onClick={() => setBillingCycle("yearly")}
                                >
                                    <div
                                        className={`${
                                            billingCycle === "yearly"
                                                ? "border-purple-500 bg-purple-500"
                                                : "border-zinc-200"
                                        } flex items-center justify-center rounded-full border absolute w-7 h-7 right-3`}
                                    >
                                        <FiCheck
                                            className={`${
                                                billingCycle === "yearly" ? "block" : "hidden"
                                            } text-lg text-white`}
                                        />
                                    </div>
                                    <p className="font-semibold text-base text-zinc-600">Yearly Plan</p>
                                    <p className="text-zinc-800 text-3xl font-semibold mt-4 tracking-tight">
                                        $ 7.99{" "}
                                        <span className="text-base font-medium tracking-normal text-zinc-600">
                                            /month
                                        </span>
                                    </p>
                                    <p className="font-normal text-sm text-zic-600 mt-2">billed at $95.88 /year</p>
                                </div>
                            </div>
                            <div className="flex flex-col mt-8 antialiased">
                                <PrimaryButton
                                    buttonText={"Activate"}
                                    onClick={() => openCheckout(billingCycle)}
                                    isLoading={activateBtnIsLoading}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default withRouter(Upgrade);
