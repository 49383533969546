import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { getThemeConfigValue } from "../../../utils/utils";

function ColorSelection(props) {
    const [isColorPickerOpen, openColorPicker] = React.useState(false);
    function useOutsideClick(ref, openColorPicker) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    openColorPicker(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, openColorPicker]);
    }

    const colorPickerRef = useRef(null);
    useOutsideClick(colorPickerRef, openColorPicker);

    const [isChecked, setChecked] = useState(props.isSameForAllDevices);

    let elementName, elementKeyForValue;
    if (props.deviceLevelConfig) {
        elementKeyForValue = props.selectorName + "-" + props.device;

        if (isChecked) {
            elementName = props.selectorName;
        } else {
            elementName = props.selectorName + "-" + props.device;
        }
    } else {
        elementName = props.selectorName;
        elementKeyForValue = props.selectorName;
    }

    return (
        <div className="my-8">
            <div className="flex flex-row justify-between">
                <p className="text-mine-shaft text-base">{props.propertyName}</p>
                <label
                    className={`${props.deviceLevelConfig ? "block" : "hidden"} flex flex-row items-end cursor-pointer`}
                >
                    <input
                        type="checkbox"
                        className="h-4 w-4 cursor-pointer text-mine-shaft rounded-sm border-mine-shaft"
                        defaultChecked={isChecked}
                        onChange={(e) => {
                            setChecked(e.target.checked);
                        }}
                    />
                    <span className="pl-2 text-mine-shaft text-xs font-normal">Same for all devices</span>
                </label>
            </div>
            <div className="relative" ref={colorPickerRef}>
                <div
                    className="cursor-pointer mt-4 flex flex-row items-center focus-within:border-purple-300 focus-within:ring-1 focus-within:ring-purple-300 border border-slate-400 rounded-md w-40 px-4"
                    onClick={() => openColorPicker(true)}
                >
                    <input
                        type="text"
                        name={elementName}
                        className="cursor-pointer text-center w-24 outline-none focus:outline-none text-base text-mine-shaft border-none focus:border-none focus:ring-0"
                        value={getThemeConfigValue(props.themeConfig, elementKeyForValue)}
                        readOnly
                    />
                    <div
                        className="w-6 h-6 rounded-full flex shrink-0 ml-1"
                        style={{ backgroundColor: getThemeConfigValue(props.themeConfig, elementKeyForValue) }}
                    ></div>
                </div>
                {isColorPickerOpen ? (
                    <div className="absolute z-10">
                        <SketchPicker
                            onChangeComplete={
                                isChecked && props.deviceLevelConfig
                                    ? props.changeColorOnAllDevices(elementName)
                                    : props.changeColor(elementName)
                            }
                            color={getThemeConfigValue(props.themeConfig, elementKeyForValue)}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default ColorSelection;
