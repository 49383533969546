import React from "react";
import axios from "axios";
import { getAPIBaseUrl } from "../../utils/utils";
import { getItemFromLocalStorage } from "../../utils/utils";
import { PulseLoader } from "react-spinners";
import DesignWidgetView from "./design-widget-view";
import defaultThemeConfig from "../../utils/default-theme-config.json";

// Display toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class DesignWidgetContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            designStep: "select-layout",
            selectedTheme: null,
            device: "desktop",
            fonts: [],
            themeConfig: {}
        };
        this.changeDesignStep = this.changeDesignStep.bind(this);
        this.changeDevice = this.changeDevice.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.changeColorOnAllDevices = this.changeColorOnAllDevices.bind(this);
        this.changeDimensionInput = this.changeDimensionInput.bind(this);
        this.changeDimensionInputOnAllDevices = this.changeDimensionInputOnAllDevices.bind(this);
        this.changeSelectInput = this.changeSelectInput.bind(this);
        this.changeSelectInputOnAllDevices = this.changeSelectInputOnAllDevices.bind(this);
        this.changeSelectedTheme = this.changeSelectedTheme.bind(this);
        this.updateThemeLayout = this.updateThemeLayout.bind(this);
        this.updateThemeConfig = this.updateThemeConfig.bind(this);
        this.resetThemeConfig = this.resetThemeConfig.bind(this);
        this.getFonts = this.getFonts.bind(this);
    }

    changeDesignStep = (step) => {
        this.setState({
            designStep: step
        });
    };

    changeDevice = (device) => {
        this.setState({
            device: device
        });
    };

    changeSelectedTheme = (theme) => {
        this.setState({
            selectedTheme: theme
        });
    };

    changeColor = (selectorName) => (color) => {
        this.setState((prevState) => ({
            themeConfig: {
                ...prevState.themeConfig,
                [selectorName]: color.hex
            }
        }));
    };

    changeColorOnAllDevices = (selectorName) => (color) => {
        this.setState((prevState) => ({
            themeConfig: {
                ...prevState.themeConfig,
                [`${selectorName}-mobile`]: color.hex,
                [`${selectorName}-tablet`]: color.hex,
                [`${selectorName}-desktop`]: color.hex
            }
        }));
    };

    changeDimensionInput = (event) => {
        let val;
        if (event.target.value === "auto") {
            val = "auto";
        } else {
            val = event.target.value + "px";
        }

        this.setState((prevState) => ({
            themeConfig: {
                ...prevState.themeConfig,
                [event.target.name]: val
            }
        }));
    };

    changeDimensionInputOnAllDevices = (event) => {
        let val;
        if (event.target.value === "auto") {
            val = "auto";
        } else {
            val = event.target.value + "px";
        }

        this.setState((prevState) => ({
            themeConfig: {
                ...prevState.themeConfig,
                [`${event.target.name}-mobile`]: val,
                [`${event.target.name}-tablet`]: val,
                [`${event.target.name}-desktop`]: val
            }
        }));
    };

    changeSelectInput = (event) => {
        this.setState((prevState) => ({
            themeConfig: {
                ...prevState.themeConfig,
                [event.target.name]: event.target.value
            }
        }));
    };

    changeSelectInputOnAllDevices = (event) => {
        this.setState((prevState) => ({
            themeConfig: {
                ...prevState.themeConfig,
                [`${event.target.name}-mobile`]: event.target.value,
                [`${event.target.name}-tablet`]: event.target.value,
                [`${event.target.name}-desktop`]: event.target.value
            }
        }));
    };

    getFonts = async () => {
        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .get(`${apiBaseUrl}/posts/font-families/`)
            .then((response) => {
                // console.log(response);
                this.setState({
                    fonts: response.data.fonts
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getWebsiteConfig = async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!getItemFromLocalStorage("access-token")) {
            this.props.history.push("/login");
        }

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .get(`${apiBaseUrl}/posts/config/`, { headers: { accessToken: accessToken } })
            .then((response) => {
                // console.log(response);
                if (response.data.website.configuration.theme_config) {
                    this.setState({
                        themeConfig: response.data.website.configuration.theme_config
                    });
                } else {
                    this.setState({
                        themeConfig: defaultThemeConfig
                    });
                }

                this.setState({
                    selectedTheme: response.data.website.configuration.widget_type
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    updateThemeLayout = async (selectedTheme) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!getItemFromLocalStorage("access-token")) {
            this.props.history.push("/login");
        }

        let payload = {
            widget_type: selectedTheme
        };

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .post(`${apiBaseUrl}/posts/config/`, payload, { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Updated theme layout");
                    this.setState({
                        selectedTheme: response.data.website.configuration.widget_type
                    });
                    toast.success("Layout updated!");
                }
            })
            .catch((error) => {
                console.log("Error in updating theme layout");
                console.log(error);
            });
    };

    updateThemeConfig = async (themeConfig) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!getItemFromLocalStorage("access-token")) {
            this.props.history.push("/login");
        }

        let payload = {
            theme_config: themeConfig
        };

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .post(`${apiBaseUrl}/posts/config/`, payload, { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Updated theme layout");
                    this.setState({
                        themeConfig: response.data.website.configuration.theme_config
                    });
                    toast.success("Theme updated!");
                }
            })
            .catch((error) => {
                console.log("Error in updating theme config");
                console.log(error);
            });
    };

    resetThemeConfig = () => {
        this.getWebsiteConfig();
        toast.info("Changes reverted");
    };

    componentDidMount() {
        document.title = "Customise - hagrid";
        if (!getItemFromLocalStorage("access-token")) {
            this.props.history.push("/login");
        }

        // Update the themeConfig state with the JSON from default-theme-config.json
        this.getWebsiteConfig();
        this.getFonts();
    }

    render() {
        // console.log(this.state);
        return (
            <>
                <DesignWidgetView
                    designStep={this.state.designStep}
                    changeDesignStep={this.changeDesignStep}
                    device={this.state.device}
                    changeDevice={this.changeDevice}
                    color={this.state.themeConfig}
                    changeColor={this.changeColor}
                    changeColorOnAllDevices={this.changeColorOnAllDevices}
                    themeConfig={this.state.themeConfig}
                    changeDimensionInput={this.changeDimensionInput}
                    changeDimensionInputOnAllDevices={this.changeDimensionInputOnAllDevices}
                    changeSelectInput={this.changeSelectInput}
                    changeSelectInputOnAllDevices={this.changeSelectInputOnAllDevices}
                    selectedTheme={this.state.selectedTheme}
                    changeSelectedTheme={this.changeSelectedTheme}
                    updateThemeLayout={this.updateThemeLayout}
                    updateThemeConfig={this.updateThemeConfig}
                    resetThemeConfig={this.resetThemeConfig}
                    fonts={this.state.fonts}
                    getWebsiteConfig={this.getWebsiteConfig}
                />
                <ToastContainer />
            </>
        );
    }
}

export default DesignWidgetContainer;
