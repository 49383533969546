import React, { useEffect } from 'react';
import { CgChevronDoubleLeft, CgChevronDoubleRight, CgChevronLeft, CgChevronRight} from 'react-icons/cg'

function Pagination(props){
    const totalPages = Math.ceil(props.totalEntries/props.entriesPerPage);

    useEffect(() => {
        if(props.currentPage>totalPages){
            props.changePageNumber(totalPages);
        }
    });
    
    
    const pageNumbers = [];
    for(let i = 1; i <= totalPages; i++){
        pageNumbers.push(i);
    }

    let startPage, endPage;
    // Config to set the number of pages to show
    let numPagesToShow = 5;
    let staticPageIndex = Math.ceil((numPagesToShow+1)/2);

    if(totalPages <= numPagesToShow){
        // If total pages is less than or equal to the number of pages to show, then show all pages
        startPage = 1;
        endPage = totalPages;
    } else{
        // If total pages is more than the number of pages to show, calculate start and end pages
        if(props.currentPage<=staticPageIndex){
            startPage = 1;
            endPage = numPagesToShow;
        } else if(props.currentPage+(staticPageIndex-1)>=totalPages){
            startPage = totalPages - (numPagesToShow-1);
            endPage = totalPages;
        } else{
            if(numPagesToShow%2===0){
                // When pages to show is even
                startPage = props.currentPage - (staticPageIndex-1);
                endPage = props.currentPage + (staticPageIndex-2)
            } else{
                // When pages to show is odd
                startPage = props.currentPage - (staticPageIndex-1);
                endPage = props.currentPage + (staticPageIndex-1)
            }            
        }
    }    

    const renderPageNumbers = pageNumbers.map((pageNumber) => 
        {
            if(pageNumber>=startPage && pageNumber<=endPage){
                return(
                    <div key={ pageNumber } className = { `rounded-full ${pageNumber===props.currentPage ? 'bg-fuchsia-100 text-purple-500 font-bold' : 'text-masala'} h-8 w-8 flex items-center justify-center cursor-pointer text-sm font-medium hover:bg-fuchsia-100`} onClick={ () => props.changePageNumber(pageNumber) }>{ pageNumber }</div>
                )
            } else return null;
        }
    );

    let renderFirstPage;
    let renderLastPage;
    
    if(props.currentPage===1){
        renderFirstPage = <div className='h-8 w-8 flex items-center justify-center text-2xl opacity-20 cursor-not-allowed rounded-full text-masala'> <CgChevronDoubleLeft /> </div>
    } else{
        renderFirstPage = <div className='h-8 w-8 flex items-center justify-center cursor-pointer text-2xl hover:bg-fuchsia-100 rounded-full text-masala' onClick={ () => props.changePageNumber(1) }> <CgChevronDoubleLeft /> </div>
    }

    if(props.currentPage===totalPages){

        renderLastPage = <div className='h-8 w-8 flex items-center justify-center text-2xl opacity-20 cursor-not-allowed rounded-full text-masala'> <CgChevronDoubleRight /> </div>
    } else{
        renderLastPage = <div className='h-8 w-8 flex items-center justify-center cursor-pointer text-2xl hover:bg-fuchsia-100 rounded-full text-masala' onClick={ () => props.changePageNumber(totalPages) }> <CgChevronDoubleRight /> </div>
    }


    
    
    let renderPreviousPage;
    let renderNextPage;

    if(props.currentPage===1){
        renderPreviousPage = <div className = 'h-8 w-8 flex items-center justify-center text-xl opacity-20 cursor-not-allowed rounded-full text-masala'> <CgChevronLeft /> </div>

    } else{
        renderPreviousPage = <div className = 'h-8 w-8 flex items-center justify-center cursor-pointer text-xl hover:bg-fuchsia-100 rounded-full text-masala' onClick={ () => props.changePageNumber(props.currentPage - 1) }> <CgChevronLeft /> </div>
    }

    if(props.currentPage===totalPages){
        renderNextPage = <div className = 'h-8 w-8 flex items-center justify-center text-xl opacity-20 cursor-not-allowed rounded-full text-masala'> <CgChevronRight /> </div>
    } else{
        renderNextPage = <div className = 'h-8 w-8 flex items-center justify-center cursor-pointer text-xl hover:bg-fuchsia-100 rounded-full text-masala' onClick={ () => props.changePageNumber(props.currentPage + 1) }> <CgChevronRight /> </div>
    }

    return(
        props.totalEntries>0
        ? (
            <div className = 'flex justify-between items-center'>
                {props.currentPage===totalPages
                ?   <p className = 'text-masala text-sm'>Showing { ((props.currentPage - 1)*props.entriesPerPage) + 1} to { props.totalEntries } of {props.totalEntries} questions</p>
                :   <p className = 'text-masala text-sm'>Showing { ((props.currentPage - 1)*props.entriesPerPage) + 1} to { props.currentPage*props.entriesPerPage } of {props.totalEntries} questions</p>
                }
                <div className = 'flex items-center'>
                    {renderFirstPage}
                    {renderPreviousPage}
                    {renderPageNumbers} 
                    {renderNextPage}
                    {renderLastPage}
                </div>
            </div>
        )
        : <div></div>
    )
}

export default Pagination;