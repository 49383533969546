import React from "react";
import ColorSelection from "../element-types/color-selection";
import SingleInputElement from "../element-types/single-input-element";
import LayoutElementsActionBtns from "./utils/layout-elements-actions-btns";

function AccordionDropdownIcon(props) {
    return (
        <>
            {/* Font Size */}
            <SingleInputElement
                selectorName="accordion-dropdown-icon-font-size"
                propertyName="Font Size"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["heading-font-size-desktop"] === props.themeConfig["heading-font-size-mobile"] &&
                    props.themeConfig["heading-font-size-desktop"] === props.themeConfig["heading-font-size-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Font Color */}
            <ColorSelection
                selectorName="accordion-dropdown-icon-font-color"
                propertyName="Font Color"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["heading-font-color-desktop"] ===
                        props.themeConfig["heading-font-color-mobile"] &&
                    props.themeConfig["heading-font-color-desktop"] === props.themeConfig["heading-font-color-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            <LayoutElementsActionBtns
                themeConfig={props.themeConfig}
                updateThemeConfig={props.updateThemeConfig}
                resetThemeConfig={props.resetThemeConfig}
            />
        </>
    );
}

export default AccordionDropdownIcon;
