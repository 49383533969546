import React, { useRef, useEffect } from "react";
import { VscTriangleDown, VscTriangleUp, VscNotebook, VscTerminalTmux, VscExtensions } from "react-icons/vsc";
import { FiCheck } from "react-icons/fi";
import { RiCloseCircleFill, RiUser4Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { AiOutlineFormatPainter } from "react-icons/ai";
import { IoLanguageOutline } from "react-icons/io5";
import { PulseLoader } from "react-spinners";
import LeftNavbar from "../../utils/left-navbar";
import { PageHeading } from "../../utils/typography";
import SettingsNav from "../settings-nav";
import SetLanguage from "./set-language";
import EditText from "./edit-text";

function TextSettingsView(props) {
    return (
        <>
            <div className="flex">
                <LeftNavbar />

                <div className="pr-12 pl-28 lg:pl-[17rem] w-full pb-20">
                    <div className="pt-12 pb-8 flex flex-row justify-between items-center">
                        <PageHeading text="Text" />
                        <SettingsNav />
                    </div>
                    {props.pageIsLoading ? (
                        <div className="flex justify-center mt-40">
                            <PulseLoader color="#e879f9" />
                        </div>
                    ) : (
                        <div
                            className="bg-white rounded-2xl px-12 py-10 overflow-hidden shadow-container antialiased"
                            style={{ height: "calc(100vh - 128px)" }}
                        >
                            <div className="h-full flex flex-col">
                                <div className="flex flex-row justify-around border rounded-lg border-zinc-200 w-1/3">
                                    <button
                                        className={`${
                                            props.textSettingsStep === "set-language"
                                                ? "bg-fuchsia-50 rounded-lg border border-purple-200 font-semibold"
                                                : " hover:bg-zinc-50"
                                        } text-sm w-1/2 py-2 font-normal text-zinc-800`}
                                        onClick={() => props.changeTextSettingsStep("set-language")}
                                    >
                                        Set Language
                                    </button>
                                    <button
                                        className={`${
                                            props.textSettingsStep === "edit-text"
                                                ? "bg-fuchsia-50 rounded-lg border border-purple-200 font-semibold"
                                                : " hover:bg-zinc-50"
                                        } text-sm w-1/2 py-2 font-normal text-zinc-800`}
                                        onClick={() => props.changeTextSettingsStep("edit-text")}
                                    >
                                        Edit Text
                                    </button>
                                </div>
                                {props.textSettingsStep === "set-language" ? (
                                    <SetLanguage
                                        selectLanguageFromList={props.selectLanguageFromList}
                                        listOfLanguages={props.listOfLanguages}
                                        changeLanguage={props.changeLanguage}
                                        languageSelected={props.languageSelected}
                                    />
                                ) : (
                                    <EditText
                                        contentConfig={props.contentConfig}
                                        changeContentConfig={props.changeContentConfig}
                                        updateContentConfig={props.updateContentConfig}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default TextSettingsView;
