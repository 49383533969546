import React from "react";
import { PrimaryButton } from "../../utils/buttons";

function SetLanguage(props) {
    return (
        <div className="overflow-scroll pt-8 antialiased flex flex-col">
            <label className="pb-4 text-mine-shaft text-base capitalize">Set Language</label>
            <select
                name="language"
                className="w-full max-w-[15rem] outline-none text-center focus:outline-none text-base text-mine-shaft border border-slate-400 focus:ring-0 focus:border-purple-300 rounded-md capitalize"
                value={props.languageSelected}
                onChange={props.selectLanguageFromList}
            >
                {props.listOfLanguages &&
                    props.listOfLanguages.map((language, index) => {
                        return (
                            <option key={index} value={language}>
                                {language}
                            </option>
                        );
                    })}
            </select>

            <div className="mt-8 max-w-fit">
                <PrimaryButton
                    buttonText={"Set Language"}
                    onClick={() => props.changeLanguage(props.languageSelected)}
                />
            </div>
        </div>
    );
}
export default SetLanguage;
