import React from "react";
import { SectionHeading } from "../utils/typography";
import { VscCloudUpload } from "react-icons/vsc";

function EditProfile(props) {
    return (
        <div className="bg-white rounded-2xl py-10 max-w-lg shadow-container">
            <div className="px-12">
                <SectionHeading text="Edit website avatar" />
                <p className=" text-slate-700 font-normal mt-4 text-sm antialiased">
                    Your Avatar will appear alongside your responses on your webpages. This could be your brand or your
                    own profile.
                </p>
            </div>
            <div className=" mx-12 xl:mx-4 bg-zinc-50 p-8 rounded-lg mt-5 flex flex-col items-center">
                <div className="w-full">
                    <label className="text-masala text-sm font-medium antialiased">
                        Avatar name
                        <input
                            type="text"
                            name="name"
                            className=" text-masala w-full mt-2 bg-white rounded-lg px-4 py-2.5 border outline-none focus:ring focus:ring-fuchsia-200"
                            onChange={props.onProfileInputChange}
                            value={props.userName === null ? "" : props.userName}
                        />
                    </label>
                </div>

                <div className="mt-5 w-full">
                    <label className="text-masala text-sm font-medium antialiased">
                        Avatar image
                        <div className="cursor-pointer mt-2 w-full flex flex-col items-center pt-12 pb-8 bg-white rounded-lg">
                            {props.profileImageUrl ? (
                                <div className="h-32 w-32 rounded-full flex justify-center items-center">
                                    <img
                                        src={props.profileImageUrl}
                                        alt="profile"
                                        className="h-32 w-32 object-cover rounded-full"
                                    />
                                </div>
                            ) : (
                                <div className="border border-dashed border-fuchsia-200 h-32 w-32 rounded-full flex justify-center items-center">
                                    <VscCloudUpload className="text-fuchsia-400 text-3xl" />
                                </div>
                            )}

                            <p className="text-slate-700 font-normal mt-4 px-8 text-center antialiased">
                                Click here to upload a picture
                            </p>
                        </div>
                        <input
                            type="file"
                            className="hidden"
                            onChange={props.getProfileImage === null ? "" : props.getProfileImage}
                        />
                    </label>
                </div>
                <button
                    className="mt-5 px-8 py-3 bg-mine-shaft hover:bg-black text-sm text-white font-semibold rounded-lg"
                    // className='w-full mt-8 py-3 bg-violet-600 hover:bg-violet-800 text-sm text-white font-semibold rounded-lg'
                    onClick={props.onProfileUpdate}
                >
                    Save Avatar
                </button>
            </div>
        </div>
    );
}

export default EditProfile;
