import React from "react";

function QuestionsZeroCase(props){
    return(
        <div className='flex flex-col items-center mt-16'>
            <img src='assets/images/questions-zero-case.svg' width={ props.width } alt='no questions' />
            <p className='mt-6 text-masala text-lg font-light max-w-lg'>{ props.text }</p>
        </div>
    )
}

export { QuestionsZeroCase };