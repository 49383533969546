import React from "react";
import QuestionView from "./question-card/question-view";

function QuestionsListView(props) {
    let questionList;
    if (props.questions !== undefined) {
        questionList = (props.questions || []).map((question) => {
            return (
                <div className="mb-6" key={question.post_id}>
                    <QuestionView
                        question={question}
                        answerQuestion={props.answerQuestion}
                        answerIsSubmitting={props.answerIsSubmitting}
                        answerSubmittingForQId={props.answerSubmittingForQId}
                        updateQuestion={props.updateQuestion}
                        updateAnswer={props.updateAnswer}
                        answerSubmitted={props.answerSubmitted}
                        updateAnswerSubmittedState={props.updateAnswerSubmittedState}
                        changeFaqModalState={props.changeFaqModalState}
                        pageLinks={props.pageLinks}
                        allPagesSelected={props.allPagesSelected}
                        onCheckboxChange={props.onCheckboxChange}
                        deselectPage={props.deselectPage}
                        updateQuestionAddedState={props.updateQuestionAddedState}
                        questionAdded={props.questionAdded}
                        questionIsUpdating={props.questionIsUpdating}
                        pageLinksForAddingFaq={props.pageLinksForAddingFaq}
                    />
                </div>
            );
        });
    }
    return <>{questionList}</>;
}

export default QuestionsListView;
