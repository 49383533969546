import React, { useState, useEffect } from "react";
import WidgetContainer from "./layout-elements/widget-container";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import Heading from "./layout-elements/heading";
import Subheading from "./layout-elements/subheading";
import Qtextbox from "./layout-elements/qtextbox";
import QtextboxText from "./layout-elements/qtextbox-text";
import PrimaryBtn from "./layout-elements/primary-btn";
import SecondaryBtn from "./layout-elements/secondary-btn";
import { deletePreviewHighlighter } from "../../utils/utils";
import HighlightedQuestionCard from "./layout-elements/highlighted-question-card";
import UserQuestionCard from "./layout-elements/user-question-card";
import QuestionText from "./layout-elements/question-text";
import AnswerText from "./layout-elements/answer-text";
import QuestionUsername from "./layout-elements/qusername";
import ProfileImg from "./layout-elements/profile-img";
import LoginModal from "./layout-elements/login-modal";
import LoginDescription from "./layout-elements/login-description";
import LoginOption from "./layout-elements/login-option";
import AccordionDropdownIcon from "./layout-elements/accordion-dropdown-icon";

function ThemeEditor(props) {
    const [layoutElementToOpen, openLayoutElement] = useState(null);
    useEffect(() => {
        props.getWebsiteConfig();
    }, []);

    return (
        <div className="h-full overflow-scroll pb-10">
            {/* Widget container */}
            {layoutElementToOpen === "widget" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Widget Box</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">The outer box for the widget</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <WidgetContainer
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("widget");
                        props.setElementClass("hg-widget-container");
                    }}
                >
                    <div>
                        <p>Widget Box</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">The outer box for the widget</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Widget title */}
            {layoutElementToOpen === "heading" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Widget Title</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">The top title in the widget</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <Heading
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("heading");
                        props.setElementClass("hg-heading");
                    }}
                >
                    <div>
                        <p>Widget Title</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">The top title in the widget</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Headings */}
            {layoutElementToOpen === "subheading" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Headings</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">
                                Titles for the question list & login box
                            </p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <Subheading
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("subheading");
                        props.setElementClass("hg-subheading");
                    }}
                >
                    <div>
                        <p>Headings</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">
                            Titles for the question list & login box
                        </p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Question Textbox */}
            {layoutElementToOpen === "qtextbox" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Question Box</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">Question Box Text</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <Qtextbox
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("qtextbox");
                        props.setElementClass("hg-qtextbox");
                    }}
                >
                    <div>
                        <p>Question Box</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">Question Box Text</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Question Textbox Text */}
            {layoutElementToOpen === "qtextbox-text" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Text in Question Box</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">
                                Style for text written in the question box
                            </p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <QtextboxText
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("qtextbox-text");
                        props.setElementClass("hg-qtextbox-text");
                    }}
                >
                    <div>
                        <p>Text in Question Box</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">
                            Style for text written in the question box
                        </p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Primary Buttons */}
            {layoutElementToOpen === "primary-btn" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Main Action Buttons</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">For the key actions</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <PrimaryBtn
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("primary-btn");
                        props.setElementClass("hg-primary-btn");
                    }}
                >
                    <div>
                        <p>Main Action Buttons</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">For the key actions</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Secondary Buttons */}
            {layoutElementToOpen === "secondary-btn" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Other Action Buttons</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">For actions that are not central</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <SecondaryBtn
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("secondary-btn");
                        props.setElementClass("hg-secondary-btn");
                    }}
                >
                    <div>
                        <p>Other Action Buttons</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">For actions that are not central</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Highlighted Question card */}
            {layoutElementToOpen === "highlighted-question-card" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Highlighted Question</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">
                                From a direct link to a particular question
                            </p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <HighlightedQuestionCard
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("highlighted-question-card");
                        props.setElementClass("hg-highlighted-question-card");
                    }}
                >
                    <div>
                        <p>Highlighted Question</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">
                            From a direct link to a particular question
                        </p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* User Question card */}
            {layoutElementToOpen === "user-question-card" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Visitor Questions</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">For the visitor’s own questions</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <UserQuestionCard
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("user-question-card");
                        props.setElementClass("hg-user-question-card");
                    }}
                >
                    <div>
                        <p>Visitor Questions</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">For the visitor’s own questions</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Question Text */}
            {layoutElementToOpen === "question-text" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Question Text</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">Text styling of the question</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <QuestionText
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("question-text");
                        props.setElementClass("hg-question-text");
                    }}
                >
                    <div>
                        <p>Question Text</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">Text styling of the question</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Accordion dropdown icon */}
            {props.selectedTheme === "accordion" ? (
                layoutElementToOpen === "accordion-dropdown-icon" ? (
                    <div className="border-b border-slate-200">
                        <div
                            className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                            onClick={() => {
                                openLayoutElement(null);
                                props.setElementClass(null);
                                deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                            }}
                        >
                            <div>
                                <p>Accordion Dropdown Icon</p>
                                <p className="text-gray-500 text-xs font-normal pt-1.5">
                                    Dropdown icon to show the answer
                                </p>
                            </div>
                            <IoChevronUp className="text-xl" />
                        </div>
                        <AccordionDropdownIcon
                            changeColor={props.changeColor}
                            changeColorOnAllDevices={props.changeColorOnAllDevices}
                            changeDimensionInput={props.changeDimensionInput}
                            changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                            changeSelectInput={props.changeSelectInput}
                            changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                            themeConfig={props.themeConfig}
                            device={props.device}
                            updateThemeConfig={props.updateThemeConfig}
                            resetThemeConfig={props.resetThemeConfig}
                            fonts={props.fonts}
                        />
                    </div>
                ) : (
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                        onClick={() => {
                            openLayoutElement("accordion-dropdown-icon");
                            props.setElementClass("hg-accordion-dropdown-icon");
                        }}
                    >
                        <div>
                            <p>Accordion Dropdown Icon</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">Dropdown icon to show the answer</p>
                        </div>
                        <IoChevronDown className="text-xl" />
                    </div>
                )
            ) : null}

            {/* Answer Text */}
            {layoutElementToOpen === "answer-text" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Answer Text</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">Text styling of the answer</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <AnswerText
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("answer-text");
                        props.setElementClass("hg-answer-text");
                    }}
                >
                    <div>
                        <p>Answer Text</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">Text styling of the answer</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Visitor Name */}
            {layoutElementToOpen === "qusername" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Visitor Name</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">
                                Text styling of name of the visitor who posted the question
                            </p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <QuestionUsername
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("qusername");
                        props.setElementClass("hg-qusername");
                    }}
                >
                    <div>
                        <p>Visitor Name</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">
                            Text styling of name of the visitor who posted the question
                        </p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Visitor Profile Picture */}
            {layoutElementToOpen === "profile-img" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Visitor Image</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">
                                Text styling of image of the visitor who posted the question
                            </p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <ProfileImg
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("profile-img");
                        props.setElementClass("hg-profile-img");
                    }}
                >
                    <div>
                        <p>Visitor Image</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">
                            Text styling of image of the visitor who posted the question
                        </p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Login modal */}
            {layoutElementToOpen === "login-modal" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Login box</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">The box with the login options</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <LoginModal
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("login-modal");
                        props.setElementClass("hg-login-modal");
                    }}
                >
                    <div>
                        <p>Login box</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">The box with the login options</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Login description */}
            {layoutElementToOpen === "login-description" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Login Message</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">
                                Description text in the login modal
                            </p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <LoginDescription
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("login-description");
                        props.setElementClass("hg-login-description");
                    }}
                >
                    <div>
                        <p>Login Message</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">Description text in the login modal</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}

            {/* Login option */}
            {layoutElementToOpen === "login-option" ? (
                <div className="border-b border-slate-200">
                    <div
                        className="flex items-center justify-between text-mine-shaft text-lg font-medium pt-8 cursor-pointer"
                        onClick={() => {
                            openLayoutElement(null);
                            props.setElementClass(null);
                            deletePreviewHighlighter(document.getElementsByTagName("iframe")[0].contentDocument);
                        }}
                    >
                        <div>
                            <p>Login Buttons</p>
                            <p className="text-gray-500 text-xs font-normal pt-1.5">Buttons for login options</p>
                        </div>
                        <IoChevronUp className="text-xl" />
                    </div>
                    <LoginOption
                        changeColor={props.changeColor}
                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                        changeDimensionInput={props.changeDimensionInput}
                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                        changeSelectInput={props.changeSelectInput}
                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                        themeConfig={props.themeConfig}
                        device={props.device}
                        updateThemeConfig={props.updateThemeConfig}
                        resetThemeConfig={props.resetThemeConfig}
                        fonts={props.fonts}
                    />
                </div>
            ) : (
                <div
                    className="flex items-center justify-between text-mine-shaft text-lg font-medium py-8 cursor-pointer border-b"
                    onClick={() => {
                        openLayoutElement("login-option");
                        props.setElementClass("hg-login-option");
                    }}
                >
                    <div>
                        <p>Login Buttons</p>
                        <p className="text-gray-500 text-xs font-normal pt-1.5">Buttons for login options</p>
                    </div>
                    <IoChevronDown className="text-xl" />
                </div>
            )}
        </div>
    );
}

export default ThemeEditor;
