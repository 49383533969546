import React, { useEffect, useRef, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { getPages } from "../utils/apis";
import { PrimaryButton, SecondaryButton } from "../utils/buttons";
import Editor from "../utils/editor";
import { SectionHeading } from "../utils/typography";
import { useHistory } from "react-router-dom";
import { getItemFromLocalStorage } from "../utils/utils";

function AddFaq(props) {
    const history = useHistory();

    const [isPageDropdownOpen, setPageDropdownState] = useState(false);
    const [allPagesSelected, setAllPagesSelected] = useState(false);
    const [pageLinks, setPageLinks] = useState([]);

    const getPageLinks = async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            history.push("/");
            return;
        }

        const resp = await getPages("app_user");
        // console.log(resp);
        if (resp.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }

        if (props.isNewQuestion === false) {
            if (checkIfUrlExistsInQuestion("all_pages")) {
                if (resp.data.pages.length > 0) {
                    const pageUrls = resp.data.pages.map((page) => {
                        return Object.assign({}, page, {
                            isChecked: true
                        });
                    });
                    setPageLinks(pageUrls);
                    setAllPagesSelected(true);
                }
            } else {
                if (resp.data.pages.length > 0) {
                    const pageUrls = resp.data.pages.map((page) => {
                        const isPageSelected = checkIfUrlExistsInQuestion(page.url);

                        return Object.assign({}, page, {
                            isChecked: isPageSelected
                        });
                    });
                    const allPagesSelected = pageUrls.findIndex((item) => item.isChecked === false) === -1;
                    setPageLinks(pageUrls);
                    setAllPagesSelected(allPagesSelected);
                }
            }
        } else {
            if (resp.data.pages.length > 0) {
                const pageUrls = resp.data.pages.map((page) => {
                    return Object.assign({}, page, {
                        isChecked: true
                    });
                });
                const allPagesSelected = pageUrls.findIndex((item) => item.isChecked === false) === -1;
                setPageLinks(pageUrls);
                setAllPagesSelected(allPagesSelected);
            }
        }
    };

    useEffect(() => {
        getPageLinks();
    }, []);

    const checkIfUrlExistsInQuestion = (url) => {
        return props.question.pages.some((element) => {
            if (element.url === url) {
                return true;
            } else {
                return false;
            }
        });
    };

    const deselectPage = (pageLinks, pageUrl) => {
        const pageUrls = pageLinks.map((page) => {
            if (page.url === pageUrl || pageUrl === "all") {
                return Object.assign({}, page, {
                    isChecked: false
                });
            } else {
                return page;
            }
        });
        setPageLinks(pageUrls);
        setAllPagesSelected(false);
    };

    const onCheckboxChange = (pageLinks, pageUrl, isChecked) => {
        let isAllChecked = pageUrl === "all" && isChecked;
        let isAllUnChecked = pageUrl === "all" && !isChecked;

        const pages = pageLinks.map((page) => {
            if (isAllChecked || page.url === pageUrl) {
                return Object.assign({}, page, {
                    isChecked: isChecked
                });
            } else if (isAllUnChecked) {
                return Object.assign({}, page, {
                    isChecked: false
                });
            }

            return page;
        });

        let allPagesSelected = pages.findIndex((item) => item.isChecked === false) === -1 || isAllChecked;

        setPageLinks(pages);
        setAllPagesSelected(allPagesSelected);
    };

    function useOutsideAlerter(ref, setElementState) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setElementState(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.changeFaqModalState);

    const pageDropdownRef = useRef(null);
    useOutsideAlerter(pageDropdownRef, setPageDropdownState);

    const [questionText, handleQuestionTextChange] = useState(() =>
        props.isNewQuestion === false ? props.question.blocks : ""
    );
    const [answerText, handleAnswerTextChange] = useState(() =>
        props.isNewQuestion === false ? props.answer.blocks : ""
    );

    let renderPageList;
    if (pageLinks.length > 0) {
        renderPageList = pageLinks.map((page) => {
            return (
                <label key={page.id} className="py-2.5 cursor-pointer flex items-center">
                    <input
                        type="checkbox"
                        className="h-7 w-7 cursor-pointer text-mine-shaft rounded-sm border-mine-shaft"
                        name={page.name}
                        value={page.url}
                        checked={page.isChecked || false}
                        onChange={(event) => onCheckboxChange(pageLinks, page.url, event.target.checked)}
                    />
                    <div>
                        <p className="pl-3 text-mine-shaft text-sm font-semibold">{page.title}</p>
                        <p className="pl-3 text-mine-shaft text-xs font-normal pt-1">{page.url}</p>
                    </div>
                </label>
            );
        });
    }

    let selectedPages = [];

    let renderSelectedPages;

    if (allPagesSelected) {
        selectedPages = ["all"];
        renderSelectedPages = (
            <div className="flex shrink-0 items-center justify-center text-mine-shaft text-xs px-3 py-1 rounded-lg border border-purple-500 mx-2">
                All Pages
                <VscClose
                    className="text-lg ml-2 text-purple-500 hover:text-purple-700 cursor-pointer"
                    onClick={() => deselectPage(pageLinks, "all")}
                />
            </div>
        );
    } else {
        if (pageLinks) {
            renderSelectedPages = pageLinks.map((page) => {
                if (page.isChecked) {
                    selectedPages.push(page.url);
                    return (
                        <div
                            key={page.id}
                            className="flex shrink-0 items-center justify-center text-mine-shaft text-xs px-3 py-1 rounded-lg border border-purple-500 mx-2"
                        >
                            {page.title ? page.title : page.url}
                            <VscClose
                                className="text-lg ml-2 text-purple-500 hover:text-purple-700 cursor-pointer"
                                onClick={() => deselectPage(pageLinks, page.url)}
                            />
                        </div>
                    );
                }
            });
        }
    }

    useEffect(() => {
        if (props.questionAdded) {
            props.changeFaqModalState(false);
            props.updateQuestionAddedState(false);
        }
    }, [props.questionAdded]);

    let renderAddQuestionBtn;
    if (props.questionIsUpdating) {
        renderAddQuestionBtn = <PrimaryButton isLoading />;
    } else {
        if (
            questionText === "" ||
            answerText === "" ||
            questionText === undefined ||
            answerText === undefined ||
            questionText.length === 0 ||
            answerText.length === 0 ||
            selectedPages.length === 0
        ) {
            renderAddQuestionBtn = <PrimaryButton buttonText="Add Question" disabled />;
        } else {
            if (props.isNewQuestion === false) {
                renderAddQuestionBtn = (
                    <PrimaryButton
                        buttonText="Update"
                        onClick={() =>
                            props.updateQuestion(
                                props.question.post_id,
                                "",
                                questionText,
                                answerText,
                                selectedPages,
                                props.answer.id
                            )
                        }
                    />
                );
            } else {
                renderAddQuestionBtn = (
                    <PrimaryButton
                        buttonText="Add Question"
                        onClick={() => props.addFaqQuestion(questionText, answerText, selectedPages)}
                    />
                );
            }
        }
    }

    return (
        <div className="flex justify-center items-center overflow-y-scroll fixed inset-0 z-40 bg-black bg-opacity-80 min-h-screen px-2">
            <div
                ref={wrapperRef}
                className="relative z-50 shadow w-full p-10 max-w-4xl bg-white rounded-xl overflow-y-scroll h-full"
            >
                <SectionHeading text="Add Question" />
                <p className="text-sm text-gray-500 font-normal mt-8">Question</p>
                <div className="answer-textbox border border-slate-200 rounded-lg px-2 mt-2 focus-within:border-fuchsia-300">
                    <Editor
                        editorInstanceId="faq-qtext"
                        editorInitialData={props.question}
                        readOnly={false}
                        handleTextChange={handleQuestionTextChange}
                    />
                </div>

                <p className="text-sm text-gray-500 font-normal mt-8">Answer</p>
                <div className="answer-textbox border border-slate-200 rounded-lg px-2 mt-2 focus-within:border-fuchsia-300">
                    <Editor
                        editorInstanceId="faq-atext"
                        editorInitialData={props.answer}
                        readOnly={false}
                        handleTextChange={handleAnswerTextChange}
                    />
                </div>

                <p className="text-sm text-gray-500 font-normal mt-8">Pages to show this Question on...</p>
                <div className="relative">
                    <div className="flex border border-slate-200 rounded-lg px-2 py-2 mt-2 focus-within:border-fuchsia-300">
                        {renderSelectedPages}
                        <input
                            type="text"
                            className="w-full border-none outline-none focus:outline-none focus:ring-0 text-mine-shaft"
                            onClick={() => setPageDropdownState(true)}
                            onFocus={() => setPageDropdownState(true)}
                            readOnly
                            // onChange={(event) => getFilteredPages(event.target.value)}
                        />
                    </div>
                    <div
                        className={`${
                            isPageDropdownOpen ? "block" : "hidden"
                        } border border-slate-200 p-6 rounded-lg shadow flex flex-col absolute w-full bg-white max-h-96 overflow-scroll`}
                        ref={pageDropdownRef}
                    >
                        <label className="py-2.5 cursor-pointer">
                            <input
                                type="checkbox"
                                className="h-7 w-7 cursor-pointer text-mine-shaft rounded-sm border-mine-shaft"
                                name="all"
                                value="all"
                                checked={allPagesSelected || false}
                                onChange={(event) => onCheckboxChange(pageLinks, "all", event.target.checked)}
                            />
                            <span className="pl-3 text-mine-shaft text-sm font-normal">Select All</span>
                        </label>

                        <div className="border-b my-2.5"></div>

                        {renderPageList}
                    </div>

                    <div className="flex items-center justify-end mt-10">
                        {renderAddQuestionBtn}

                        <SecondaryButton buttonText="Cancel" onClick={() => props.changeFaqModalState(false)} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddFaq;
