import React, { useState, useEffect, useContext, createContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MagicLogin from "./components/login/magic-login";
import DashboardContainer from "./components/dashboard/dashboard-container";
import QuestionsPageContainer from "./components/user-asked-questions/questions-page-container";
import MagicLoginCallback from "./components/login/magic-callback";
import CollectInfoContainer from "./components/onboarding/collect-info-container";
import MagicLogout from "./components/login/magic-logout";
import TextSettingsContainer from "./components/settings/text-settings/text-settings-container";
import DesignWidgetContainer from "./components/settings/design-widget/design-widget-container";
import FaqContainer from "./components/faq/faq-container";
import SetupInstructionsContainer from "./components/settings/setup-instructions/setup-instructions-container";
import ProfileContainer from "./components/settings/profile/profile-container";
import Home from "./home";
import Upgrade from "./components/upgrade/upgrade";
import WebsiteContext from "./components/context/website-context";

function App() {
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
    const [trialDaysLeft, setTrialDaysLeft] = useState(null);
    const [trialStatus, setTrialStatus] = useState(null);
    const [websiteConfigIsLoading, setWebsiteConfigIsLoading] = useState(true);

    const websiteConfig = {
        isSubscriptionActive: isSubscriptionActive,
        trialDaysLeft: trialDaysLeft,
        trialStatus: trialStatus,
        websiteConfigIsLoading: websiteConfigIsLoading,
        setIsSubscriptionActive: setIsSubscriptionActive,
        setTrialDaysLeft: setTrialDaysLeft,
        setTrialStatus: setTrialStatus,
        setWebsiteConfigIsLoading: setWebsiteConfigIsLoading
    };

    return (
        <WebsiteContext.Provider value={websiteConfig}>
            <Router>
                <Switch>
                    <Route exact path="/login" component={MagicLogin} />
                    <Route exact path="/login-callback" component={MagicLoginCallback} />
                    <Route exact path="/logout" component={MagicLogout} />
                    <Route exact path="/" component={Home} />
                    <Route exact path="/setup" component={DashboardContainer} />
                    <Route exact path="/questions" component={QuestionsPageContainer} />
                    <Route exact path="/onboarding" component={CollectInfoContainer} />
                    <Route exact path="/faq" component={FaqContainer} />
                    <Route exact path="/settings/content" component={TextSettingsContainer} />
                    <Route exact path="/settings/design" component={DesignWidgetContainer} />
                    <Route exact path="/settings/setup-instructions" component={SetupInstructionsContainer} />
                    <Route exact path="/settings/profile" component={ProfileContainer} />
                    <Route exact path="/upgrade" component={Upgrade} />
                </Switch>
            </Router>
        </WebsiteContext.Provider>
    );
}

export default App;
