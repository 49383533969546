import React from "react";
import ColorSelection from "../element-types/color-selection";
import MultiInputElement from "../element-types/multi-input-element";
import SingleInputElement from "../element-types/single-input-element";
import LayoutElementsActionBtns from "./utils/layout-elements-actions-btns";

function LoginModal(props) {
    return (
        <>
            {/* Background color */}
            <ColorSelection
                selectorName="login-modal-bg-color"
                propertyName="Background Color"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={false}
            />

            {/* Max width */}
            <SingleInputElement
                selectorName="login-modal-max-width"
                propertyName="Maximum Width"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={false}
            />

            {/* Padding */}
            <MultiInputElement
                selectorName="login-modal-p"
                propertyName="Padding"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["login-modal-pl-desktop"] === props.themeConfig["login-modal-pl-mobile"] &&
                    props.themeConfig["login-modal-pl-desktop"] === props.themeConfig["login-modal-pl-tablet"] &&
                    props.themeConfig["login-modal-pt-desktop"] === props.themeConfig["login-modal-pt-mobile"] &&
                    props.themeConfig["login-modal-pt-desktop"] === props.themeConfig["login-modal-pt-tablet"] &&
                    props.themeConfig["login-modal-pr-desktop"] === props.themeConfig["login-modal-pr-mobile"] &&
                    props.themeConfig["login-modal-pr-desktop"] === props.themeConfig["login-modal-pr-tablet"] &&
                    props.themeConfig["login-modal-pb-desktop"] === props.themeConfig["login-modal-pb-mobile"] &&
                    props.themeConfig["login-modal-pb-desktop"] === props.themeConfig["login-modal-pb-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Border Radius */}
            <SingleInputElement
                selectorName="login-modal-border-radius"
                propertyName="Border Radius"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["login-modal-border-radius-desktop"] ===
                        props.themeConfig["login-modal-border-radius-mobile"] &&
                    props.themeConfig["login-modal-border-radius-desktop"] ===
                        props.themeConfig["login-modal-border-radius-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            <LayoutElementsActionBtns
                themeConfig={props.themeConfig}
                updateThemeConfig={props.updateThemeConfig}
                resetThemeConfig={props.resetThemeConfig}
            />
        </>
    );
}

export default LoginModal;
