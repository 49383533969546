import React from "react";
import LeftNavbar from "../utils/left-navbar";
import Pagination from "../utils/pagination";
import { PageHeading } from "../utils/typography";
import QuestionsListView from "../utils/questions-list-view";
import { PulseLoader } from "react-spinners";
import QuestionPageFilters from "./question-page-filters";
import UpgradeNudge from "../utils/upgrade-nudge";
import QuestionsZeroCase from "./questions-zero-case";

function QuestionsPageView(props) {
    return (
        <>
            <div className="flex">
                <LeftNavbar />

                <div className="pr-12 pl-28 lg:pl-[17rem] w-full pb-20">
                    <UpgradeNudge />
                    <div className="pt-12 pb-8">
                        <PageHeading text="Inbox" />
                    </div>

                    {props.pageLinksAreLoading ? (
                        <div className="flex justify-center mt-40">
                            <PulseLoader color="#e879f9" />
                        </div>
                    ) : (
                        <>
                            <QuestionPageFilters
                                pageLinks={props.pageLinks}
                                selectedPageLink={props.selectedPageLink}
                                selectedPageTitle={props.selectedPageTitle}
                                onPageLinkChange={props.onPageLinkChange}
                                totalUnansweredQuestions={props.totalUnansweredQuestions}
                            />

                            <div className="bg-white rounded-lg w-full px-8 py-4 mb-4 text-sm text-zinc-600 font-light shadow-container">
                                Showing unanswered questions for{" "}
                                <span className="font-normal text-zinc-800">
                                    {props.selectedPageLink === "all" ? "All Pages" : props.selectedPageLink}
                                </span>
                            </div>
                            {props.questionsAreLoading ? (
                                <div className="flex justify-center mt-40">
                                    <PulseLoader color="#e879f9" />
                                </div>
                            ) : props.questions.length > 0 ? (
                                <>
                                    <QuestionsListView
                                        questions={props.questions}
                                        answerQuestion={props.answerQuestion}
                                        answerIsSubmitting={props.answerIsSubmitting}
                                        answerSubmittingForQId={props.answerSubmittingForQId}
                                        updateQuestion={props.updateQuestion}
                                        updateAnswer={props.updateAnswer}
                                        answerSubmitted={props.answerSubmitted}
                                        updateAnswerSubmittedState={props.updateAnswerSubmittedState}
                                    />
                                    <div className="bg-white rounded-lg w-full px-8 py-4 mb-12">
                                        <Pagination
                                            totalEntries={props.totalQuestions}
                                            entriesPerPage={props.pageSize}
                                            currentPage={props.currentPage}
                                            changePageNumber={props.changePageNumber}
                                        />
                                    </div>
                                </>
                            ) : (
                                <QuestionsZeroCase />
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default QuestionsPageView;
