import React from "react";
import axios from "axios";
import { getAPIBaseUrl, getItemFromLocalStorage, setItemInLocalStorage } from "../../utils/utils";
import SetupInstructionsView from "./setup-instructions-view";

class SetupInstructionsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            websiteId: null,
            pageIsLoading: true
        };
        this.getWebsiteDetails = this.getWebsiteDetails.bind(this);
    }

    getWebsiteDetails = async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
        }

        let apiBaseUrl = getAPIBaseUrl();

        this.setState({
            pageIsLoading: true
        });

        await axios
            .get(`${apiBaseUrl}/oauth/websites/`, { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_message === "WEBSITE_NOT_FOUND") {
                        this.props.history.push("/onboarding");
                    } else {
                        this.setState({
                            websiteId: response.data.display_id,
                            pageIsLoading: false
                        });
                        setItemInLocalStorage("website-id", response.data.website_id);

                        // Update crisp data
                        window.$crisp.push(["set", "user:company", [response.data.name]]);
                    }
                }
            })
            .catch((error) => {
                console.log("Error in getting website details");
                console.log(error);
                if (error.response.data.detail === "Signature has expired.") {
                    // remove access token from local storage
                    localStorage.removeItem("access-token");
                    this.props.history.push("/login");
                }
            });
    };

    componentDidMount() {
        document.title = "Setup - hagrid";
        this.getWebsiteDetails();
    }

    render() {
        return <SetupInstructionsView websiteId={this.state.websiteId} pageIsLoading={this.state.pageIsLoading} />;
    }
}

export default SetupInstructionsContainer;
