import React from "react";
import { PulseLoader } from "react-spinners";
import QuestionsListView from "../utils/questions-list-view";
import { PrimaryButton } from "../utils/buttons";
import LeftNavbar from "../utils/left-navbar";
import Pagination from "../utils/pagination";
import { PageHeading } from "../utils/typography";
import AddFaq from "./add-faq";
import FaqFilters from "./faq-filters";
import UpgradeNudge from "../utils/upgrade-nudge";
import FaqZeroCase from "./faq-zero-case";

function FaqView(props) {
    return (
        <>
            <div className="flex">
                <LeftNavbar />

                <div className="pr-12 pl-28 lg:pl-[17rem] w-full pb-20">
                    <UpgradeNudge />
                    <div className="pt-12 pb-8 flex flex-row justify-between items-center">
                        <PageHeading text="Questions" />
                        <PrimaryButton buttonText="Add new question" onClick={() => props.changeFaqModalState(true)} />
                    </div>
                    {props.pageLinksAreLoading ? (
                        <div className="flex justify-center mt-40">
                            <PulseLoader color="#e879f9" />
                        </div>
                    ) : props.questions.length > 0 ? (
                        <>
                            <FaqFilters
                                pageLinks={props.pageLinks}
                                selectedPageLink={props.selectedPageLink}
                                selectedPageTitle={props.selectedPageTitle}
                                onPageLinkChange={props.onPageLinkChange}
                                changeStatusFilter={props.changeStatusFilter}
                                statusFilter={props.statusFilter}
                                selectedPageId={props.selectedPageId}
                                totalQuestions={props.totalQuestions}
                                refreshQuestions={props.refreshQuestions}
                            />
                            <div className="bg-white rounded-lg w-full px-8 py-4 mb-4 text-sm text-slate-600 font-light shadow-container">
                                Showing questions for{" "}
                                <span className="font-normal text-slate-800">
                                    {props.selectedPageLink === "all" ? "All Pages" : props.selectedPageLink}
                                </span>
                            </div>
                            {props.questionsAreLoading ? (
                                <div className="flex justify-center mt-40">
                                    <PulseLoader color="#e879f9" />
                                </div>
                            ) : (
                                <>
                                    <QuestionsListView
                                        questions={props.questions}
                                        answerQuestion={props.answerQuestion}
                                        answerIsSubmitting={props.answerIsSubmitting}
                                        updateQuestion={props.updateFaqQuestion}
                                        updateAnswer={props.updateAnswer}
                                        answerSubmitted={props.answerSubmitted}
                                        updateAnswerSubmittedState={props.updateAnswerSubmittedState}
                                        pageLinks={props.pageLinks}
                                        allPagesSelected={props.allPagesSelected}
                                        onCheckboxChange={props.onCheckboxChange}
                                        deselectPage={props.deselectPage}
                                        updateQuestionAddedState={props.updateQuestionAddedState}
                                        questionAdded={props.questionAdded}
                                        questionIsUpdating={props.questionIsUpdating}
                                        pageLinksForAddingFaq={props.pageLinksForAddingFaq}
                                    />
                                    <div className="bg-white rounded-lg w-full px-8 py-5 mb-12">
                                        <Pagination
                                            totalEntries={props.totalQuestions}
                                            entriesPerPage={props.pageSize}
                                            currentPage={props.currentPage}
                                            changePageNumber={props.changePageNumber}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {props.pageLinks.length > 0 ? (
                                <>
                                    <FaqFilters
                                        pageLinks={props.pageLinks}
                                        selectedPageLink={props.selectedPageLink}
                                        selectedPageTitle={props.selectedPageTitle}
                                        onPageLinkChange={props.onPageLinkChange}
                                        changeStatusFilter={props.changeStatusFilter}
                                        statusFilter={props.statusFilter}
                                        selectedPageId={props.selectedPageId}
                                        totalQuestions={props.totalQuestions}
                                        refreshQuestions={props.refreshQuestions}
                                    />
                                    <div className="bg-white rounded-lg w-full px-8 py-4 mb-4 text-sm text-slate-600 font-light shadow-container">
                                        Showing questions for{" "}
                                        <span className="font-normal text-mine-shaft">
                                            {props.selectedPageLink === "all" ? "All Pages" : props.selectedPageLink}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}

                            {/* <QuestionsZeroCase text="No questions here!" width="180px" /> */}
                            <FaqZeroCase />
                        </>
                    )}
                </div>
            </div>

            {props.addFaqModalIsOpen ? (
                <AddFaq
                    changeFaqModalState={props.changeFaqModalState}
                    allPagesSelected={props.allPagesSelected}
                    onCheckboxChange={props.onCheckboxChange}
                    pageLinks={props.pageLinks}
                    deselectPage={props.deselectPage}
                    addFaqQuestion={props.addFaqQuestion}
                    updateQuestionAddedState={props.updateQuestionAddedState}
                    questionAdded={props.questionAdded}
                    questionIsUpdating={props.questionIsUpdating}
                    isNewQuestion={true}
                    pageLinksForAddingFaq={props.pageLinksForAddingFaq}
                />
            ) : null}
        </>
    );
}

export default FaqView;
