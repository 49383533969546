import React, { useContext, useEffect } from "react";
import axios from "axios";
import { VscComment, VscCreditCard, VscGear, VscHome, VscSignOut, VscWand, VscInbox } from "react-icons/vsc";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import { getAPIBaseUrl, getItemFromLocalStorage, setItemInLocalStorage } from "../utils/utils";
import { getWebsiteConfig, openStripeBilling } from "./apis";
import WebsiteContext from "../context/website-context";
import { useHistory } from "react-router-dom";

function LeftNavbar(props) {
    const history = useHistory();

    const websiteConfig = useContext(WebsiteContext);

    const getConfig = async () => {
        const accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            history.push("/login");
            return;
        }

        const websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            history.push("/");
            return;
        }

        websiteConfig.setWebsiteConfigIsLoading(true);

        const response = await getWebsiteConfig();

        websiteConfig.setWebsiteConfigIsLoading(false);

        // console.log(response);
        if (response.error === "Signature has expired.") {
            history.push("/login");
            return;
        }

        if (response.data.user_profile?.subscription_id) {
            websiteConfig.setIsSubscriptionActive(true);
        } else {
            websiteConfig.setIsSubscriptionActive(false);
        }

        if (response.data.user_profile?.no_of_days_left) {
            websiteConfig.setTrialDaysLeft(response.data.user_profile.no_of_days_left);
        }
        if (response.data.user_profile?.trial_status) {
            websiteConfig.setTrialStatus(response.data.user_profile.trial_status);
        }
    };

    useEffect(() => {
        getConfig();
    }, []);

    // extract pathname from location
    const { pathname } = useLocation();

    return (
        <nav className="bg-white min-h-screen fixed w-16 lg:w-56 border-r border-slate-100 z-10 px-2 lg:px-4 overflow-y-scroll h-full">
            <div
                className="flex items-center justify-center lg:justify-start cursor-pointer pt-12 pb-8 lg:px-4"
                onClick={() => history.push("/")}
            >
                <img src="/assets/images/hagrid-logo-alt.svg" alt="logo" className="w-[6.5rem] hidden lg:block" />
                <img src="/assets/images/hagrid-logo-small.svg" alt="logo" className="w-9 block lg:hidden" />
            </div>
            <NavLink
                className={`${
                    getItemFromLocalStorage("onboarded") === "true" ? "hidden" : "flex"
                } hover:bg-slate-50 rounded-lg text-slate-800 py-2 mb-2 lg:px-4 px-0 items-center justify-center lg:justify-start`}
                activeClassName=" py-2 mb-3 lg:px-4 px-0 w-full flex justify-center lg:justify-start items-center bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                exact
                to="/setup"
            >
                <VscWand className="text-2xl lg:text-xl" />
                <p className="text-base ml-2 font-normal hidden lg:block">Setup</p>
            </NavLink>
            <NavLink
                className="hover:bg-slate-50 rounded-lg text-slate-800 py-2 my-3 lg:px-4 px-0 flex items-center justify-center lg:justify-start"
                activeClassName=" py-2 my-3 lg:px-4 px-0 w-full flex justify-center lg:justify-start items-center bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                exact
                to="/questions"
            >
                <VscInbox className="text-2xl lg:text-xl" />
                <p className="text-base ml-2 font-normal hidden lg:block">Inbox</p>
            </NavLink>
            <NavLink
                className="hover:bg-slate-50 rounded-lg text-slate-800 py-2 my-3 lg:px-4 px-0 flex items-center justify-center lg:justify-start"
                activeClassName=" py-2 my-3 lg:px-4 px-0 w-full flex justify-center lg:justify-start items-center bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                exact
                to="/faq"
            >
                <VscComment className="text-2xl lg:text-xl" />
                <p className="text-base ml-2 font-normal hidden lg:block">Questions</p>
            </NavLink>
            <NavLink
                className="hover:bg-slate-50 rounded-lg text-slate-800 py-2 my-3 lg:px-4 px-0 flex items-center justify-center lg:justify-start"
                activeClassName=" py-2 my-3 lg:px-4 px-0 w-full flex justify-center lg:justify-start items-center bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                to="/settings/design"
                isActive={() =>
                    [
                        "/settings/design",
                        "/settings/setup-instructions",
                        "/settings/profile",
                        "/settings/content"
                    ].includes(pathname)
                }
            >
                <VscGear className="text-2xl lg:text-xl" />
                <p className="text-base ml-2 font-normal hidden lg:block">Settings</p>
            </NavLink>
            {websiteConfig.isSubscriptionActive ? (
                <button
                    className="hover:bg-slate-50 rounded-lg text-slate-800 py-2 my-3 lg:px-4 px-0 flex items-center justify-center lg:justify-start w-full"
                    onClick={() => openStripeBilling()}
                >
                    <VscCreditCard className="text-2xl lg:text-xl" />
                    <p className="text-base ml-2 font-normal hidden lg:block">Billing</p>
                </button>
            ) : (
                <NavLink
                    className="hover:bg-slate-50 rounded-lg text-slate-800 py-2 my-3 lg:px-4 px-0 flex items-center justify-center lg:justify-start"
                    activeClassName=" py-2 my-3 lg:px-4 px-0 w-full flex justify-center lg:justify-start items-center bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                    exact
                    to="/upgrade"
                >
                    <VscCreditCard className="text-2xl lg:text-xl" />
                    <p className="text-base ml-2 font-normal hidden lg:block">Upgrade</p>
                </NavLink>
            )}

            <NavLink
                className="hover:bg-slate-50 rounded-lg text-slate-800 py-2 my-3 lg:px-4 px-0 flex items-center justify-center lg:justify-start"
                activeClassName=" py-2 my-3 lg:px-4 px-0 w-full flex justify-center lg:justify-start items-center bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                exact
                to="/logout"
            >
                <VscSignOut className="text-2xl lg:text-xl" />
                <p className="text-base ml-2 font-normal hidden lg:block">Logout</p>
            </NavLink>

            {/* <button
                className="hover:bg-slate-50 rounded-lg text-slate-800 py-2 my-3 lg:px-4 px-0 flex items-center justify-center lg:justify-start w-full"
                onClick={() => {
                    props.history.push("/logout");
                }}
            >
                <VscSignOut className="text-2xl lg:text-xl" />
                <p className="text-base ml-2 font-normal hidden lg:block">Logout</p>
            </button> */}
        </nav>
    );
}

export default withRouter(LeftNavbar);
