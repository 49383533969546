import React, { useState, useRef, useEffect } from "react";
import { SectionHeading } from "./typography";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdDragIndicator } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { getQuestions, updateSortOrder } from "./apis";
import { useHistory } from "react-router-dom";
import { getItemFromLocalStorage } from "./utils";
import { PulseLoader } from "react-spinners";
import { QuestionsZeroCase } from "./zero-cases";

function SortQuestionsPopup(props) {
    const history = useHistory();

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.setIsSortPopupOpen(false);
                    props.refreshQuestions();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const [questions, setQuestions] = useState([]);
    const [questionsAreLoading, setQuestionsAreLoading] = useState(true);

    const getQuestionsToSort = async (pageUrl, currentPage, pageSize, statusFilter) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            history.push("/");
            return;
        }

        setQuestionsAreLoading(true);

        const resp = await getQuestions(pageUrl, currentPage, pageSize, statusFilter);
        if (resp.error === "Signature has expired.") {
            history.push("/login");
            return;
        }
        setQuestions(resp.data.posts);
        setQuestionsAreLoading(false);
    };

    // Build an array from the key values of an array of objects
    const getQuestionsArray = (questions) => {
        return questions.map((question) => question.post_id);
    };

    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        setItemList(questions);
    }, [questions]);

    // Function to update list on drop
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(itemList, result.source.index, result.destination.index);

        updateSortOrder(props.selectedPageId, "manual", getQuestionsArray(items));

        setItemList(items);
    };

    const sortOptions = [
        { displayName: "Newest first", value: "newest" },
        { displayName: "Oldest first", value: "oldest" },
        { displayName: "Manually", value: "manual" }
    ];

    useEffect(() => {
        getQuestionsToSort(props.selectedPageLink, 1, props.totalQuestions, "PUBLISHED");
    }, [props.selectedPageLink, props.sortOrder]);

    const changeSortOrder = async (event) => {
        console.log(event.target.value);
        const sortOrder = event.target.value;
        let resp;
        if (sortOrder === "manual") {
            resp = await updateSortOrder(props.selectedPageId, sortOrder, getQuestionsArray(itemList));
            // console.log(resp);
        } else {
            resp = await updateSortOrder(props.selectedPageId, sortOrder);
            // console.log(resp);
        }
        props.setSortOrder(sortOrder);
    };

    return (
        <div className="flex justify-center items-center inset-0 fixed z-40 bg-black bg-opacity-90 min-h-screen p-12">
            <div
                ref={wrapperRef}
                className="relative z-50 bg-white w-full max-w-3xl px-12 py-10 rounded-lg shadow h-full sm:max-h-min antialiased overflow-y-scroll"
            >
                <div className="flex flex-col min-h-full sm:min-h-fit">
                    <div
                        className="absolute right-3 top-3 sm:right-5 sm:top-5 rounded-full w-10 h-10 hover:bg-zinc-200 flex items-center justify-center cursor-pointer"
                        onClick={() => {
                            props.setIsSortPopupOpen(false);
                            props.refreshQuestions();
                        }}
                    >
                        <IoClose className="text-2xl text-zinc-600" />
                    </div>
                    <SectionHeading text="Order Questions" />

                    <div className="flex flex-row items-end mt-8">
                        <div className="flex flex-col max-w-[16rem] w-full">
                            <p className="text-xs text-zinc-400 font-semibold mb-2 antialiased">SORT BY</p>
                            <select
                                className="border border-zinc-200 bg-white py-3 px-6 flex items-center justify-between rounded-lg cursor-pointer outline-none text-center focus:outline-none text-sm text-zinc-800 focus:ring-0 focus:border-zinc-200 form-select antialiased"
                                onChange={changeSortOrder}
                                value={props.sortOrder}
                            >
                                {sortOptions.map((option, index) => {
                                    return (
                                        <option key={index} value={option.value}>
                                            {option.displayName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <p
                            className={`${
                                props.sortOrder === "manual" ? "block" : "hidden"
                            } mx-4 text-zinc-600 text-xs`}
                        >
                            Drag and drop to reorder questions.
                        </p>
                    </div>

                    <div className="mt-8">
                        <p className="text-xs text-zinc-400 font-semibold mb-2 antialiased">QUESTIONS</p>
                        {questionsAreLoading ? (
                            <div className="flex justify-center mt-12">
                                <PulseLoader color="#e879f9" />
                            </div>
                        ) : itemList.length > 0 ? (
                            props.sortOrder === "manual" ? (
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                className="divide-y divide-zinc-200"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {itemList.map((item, index) => (
                                                    <Draggable
                                                        key={item.post_id}
                                                        draggableId={item.post_id.toString()}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className={`${
                                                                    snapshot.isDragging ? "bg-zinc-50" : "bg-white"
                                                                } flex flex-row items-center py-2 px-4  text-sm text-zinc-800 my-2`}
                                                                ref={provided.innerRef}
                                                                {...provided.dragHandleProps}
                                                                {...provided.draggableProps}
                                                            >
                                                                <MdDragIndicator className="mr-2 text-base items-center flex shrink-0 text-zinc-600" />
                                                                {item.content}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            ) : (
                                <div className="divide-y divide-zinc-200">
                                    {itemList.map((item) => (
                                        <div key={item.post_id} className="py-2 px-4  text-sm text-zinc-800 my-2">
                                            {item.content}
                                        </div>
                                    ))}
                                </div>
                            )
                        ) : (
                            <QuestionsZeroCase text="No questions here" width="11rem" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SortQuestionsPopup;
