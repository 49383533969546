import React from "react";

function QuestionsZeroCase() {
    return (
        <div className="flex flex-col items-center mt-8">
            <div className="flex flex-col items-center">
                <img src="assets/images/questions-zero-case.svg" className="w-40" alt="no questions" />
                <p className="mt-8 text-zinc-800 text-lg font-medium max-w-xl antialiased">
                    There are no unanswered questions from your visitors!
                </p>
            </div>
            <div className="max-w-3xl antialiased mt-12">
                <p className="text-zinc-800 font-semibold text-lg">Guide to Success</p>
                <p className="text-zinc-800 mt-6">
                    This is the page where you can respond to your website visitors’ questions. You can respond with
                    rich answers and automatically{" "}
                    <span className="underline underline-offset-4 decoration-dotted decoration-purple-500">
                        embed links - including Youtube videos, Tweets, Pinterest, Facebook posts and much more
                    </span>
                    .
                </p>
                <p className="text-zinc-800 mt-8">
                    When you respond to a question, the user gets notified. This way you can reach them, even though
                    they have probably left your website.
                </p>
                <p className="text-zinc-800 mt-8">
                    When you respond to a question, you can mark the question as{" "}
                    <span className="font-semibold">Private</span> so that only the person who asked the question can
                    see the answer. They will still get notified of the answers.
                </p>
                <img src="assets/images/inbox-zero-case-1.svg" loading="lazy" alt="no questions" className="mt-8" />
                <p className="text-zinc-800 mt-8">
                    Once you have responded to a question, it can be accessed from the{" "}
                    <span className="font-semibold">Questions</span> tab. You can also update the question and edit the
                    response.
                </p>
                <p className="text-zinc-800 mt-8">
                    You can also mark SPAM questions (to help in the battle) or delete the questions. Delete questions
                    that you do not want to answer, so that the inbox doesn’t have a pile up of questions.
                </p>
                <img src="assets/images/inbox-zero-case-2.svg" loading="lazy" alt="no questions" className="mt-8" />
                <p className="text-zinc-800 mt-8">
                    Oldest questions are shown here first, so you can respond to customers who asked questions first.
                </p>
            </div>
        </div>
    );
}

export default QuestionsZeroCase;
