import React from "react";
import axios from "axios";
import qs from "qs";
import { getAPIBaseUrl, setItemInLocalStorage } from "../utils/utils";
import { PulseLoader } from "react-spinners";
import { withRouter } from "react-router-dom";

class MagicLoginCallback extends React.Component {
    constructor(props) {
        super();
        this.createAccessToken = this.createAccessToken.bind(this);
    }

    createAccessToken = async (userType, idToken) => {
        let payload = {
            user_type: userType,
            id_token: idToken
        };

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .post(`${apiBaseUrl}/oauth/token/`, qs.stringify(payload))
            .then((response) => {
                if (response.status === 200) {
                    // Identify user for posthog
                    window.posthog.identify(
                        response.data.user_profile.id, // distinct_id, required
                        { userEmail: response.data.user_profile.email, app: "hagrid" }
                    );

                    // Save access token in local storage
                    setItemInLocalStorage("access-token", response.data.token);
                    this.props.history.push({
                        pathname: `/`,
                        state: { accessToken: response.data.token }
                    });
                }
            })
            .catch((error) => {
                console.log("Error in creating access token");
                console.log(error);
            });
    };

    componentDidMount() {
        const checkExistingScript = document.getElementById("magic-callback-script");
        if (checkExistingScript) {
            return;
        }
        const script = document.createElement("script");
        script.type = "application/javascript";
        script.src = "https://auth.magic.link/pnp/callback";
        script.id = "magic-callback-script";
        script.setAttribute("data-magic-publishable-api-key", "pk_live_06972B21F7C0450F");
        document.body.appendChild(script);

        window.addEventListener("@magic/ready", (e) => {
            const { idToken, userMetadata, oauth } = e.detail;
            this.createAccessToken("app_user", e.detail.idToken);
        });
    }

    render() {
        return (
            <div className="h-screen flex items-center justify-center">
                <PulseLoader color="#e879f9" />
            </div>
        );
    }
}

export default withRouter(MagicLoginCallback);
