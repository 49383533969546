import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { getOnboardingSteps } from "./components/utils/apis";
import { getAPIBaseUrl, getItemFromLocalStorage, setItemInLocalStorage } from "./components/utils/utils";

function Home(props) {
    const history = useHistory();

    const getWebsiteDetails = useCallback(async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            history.push("/login");
            return;
        }

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .get(`${apiBaseUrl}/oauth/websites/`, { headers: { accessToken: accessToken } })
            .then((response) => {
                // console.log(response);
                if (response.status === 200) {
                    if (response.data.error_message === "WEBSITE_NOT_FOUND") {
                        localStorage.removeItem("website-id");
                        localStorage.removeItem("active-subscription");
                        localStorage.removeItem("billing-cycle");
                        localStorage.removeItem("onboarded");
                        history.push("/onboarding");
                    } else {
                        setItemInLocalStorage("website-id", response.data.website_id);

                        // Update crisp data
                        window.$crisp.push(["set", "user:company", [response.data.name]]);

                        // Update company name in posthog
                        window.posthog.people.set({ websiteName: response.data.name });

                        checkOnboardingAndRedirect();
                    }
                }
            })
            .catch((error) => {
                console.log("Error in getting website details");
                console.log(error);
                console.log(error.response.data.detail);
                if (error.response.data.detail === "Signature has expired.") {
                    // remove access token from local storage
                    localStorage.removeItem("access-token");
                    history.push("/login");
                }
            });
    }, []);

    const checkOnboardingAndRedirect = useCallback(async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            history.push("/login");
            return;
        }
        const resp = await getOnboardingSteps();
        // console.log(resp);
        if (resp.data.current_status.status === "FINISHED") {
            setItemInLocalStorage("onboarded", true);
            history.push("/questions");
            return;
        }
        setItemInLocalStorage("onboarded", false);
        history.push("/setup");
    }, []);

    const getProfileDetails = async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            history.push("/login");
            return;
        }

        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            return;
        }

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .get(`${apiBaseUrl}/oauth/users/`, { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    // Update crisp data
                    window.$crisp.push(["set", "user:nickname", [response.data.user_profile.name]]);
                    window.$crisp.push(["set", "user:email", [response.data.user_profile.email]]);
                    window.$crisp.push(["set", "user:avatar", [response.data.user_profile.image_url]]);

                    // Identify user for posthog
                    window.posthog.identify(
                        response.data.user_profile.id, // distinct_id, required
                        {
                            userName: response.data.user_profile.name,
                            userEmail: response.data.user_profile.email,
                            app: "hagrid"
                        }
                    );
                }
            })

            .catch((error) => {
                console.log("Error in getting profile details");
                console.log(error);
                if (error.response.data.detail === "Signature has expired.") {
                    // remove access token from local storage
                    localStorage.removeItem("access-token");
                    history.push("/login");
                }
            });
    };

    useEffect(() => {
        getWebsiteDetails();
        getProfileDetails();
    }, []);

    return (
        <div className="h-screen flex items-center justify-center">
            <PulseLoader color="#e879f9" />
        </div>
    );
}

export default Home;
