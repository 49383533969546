import React, { useState } from "react";
import { CopyBlock, shadesOfPurple } from "react-code-blocks";
import { SectionHeading } from "../utils/typography";
import { VscCopy, VscCheck } from "react-icons/vsc";
import { withRouter } from "react-router-dom";

// Copy to clipboard
function copyToClipboard(code) {
    let textArea = document.createElement("textarea");
    textArea.value = code;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
}

// Open link in a new tab
function openLink(link) {
    window.open(link, "_blank");
}

function SetupInstructions(props) {
    const [isCopied, copy] = useState(false);

    let codeSnippet = `<script type="text/javascript"> window.HAGRID_WEBSITE_ID = "${props.websiteId}";</script>\n<script async type="text/javascript" src="https://static.hagrid.io/build/main.js"></script>`;

    return (
        <div className="bg-white rounded-2xl px-12 py-10 shadow-container">
            <SectionHeading text="Get hagrid on your webpage" />
            <p className="antialiased my-4 text-base text-slate-800">
                {
                    "Here is the hagrid snippet to add to the <head> section of your webpage. Click on “Copy” to get started."
                }
            </p>

            <CopyBlock
                text={codeSnippet}
                language={"javascript"}
                showLineNumbers={false}
                wrapLines={true}
                theme={shadesOfPurple}
                customStyle={{
                    borderRadius: "8px 8px 0 0",
                    fontSize: "1rem",
                    padding: "2rem"
                }}
            />
            <div className="flex justify-end w-full rounded-b-lg bg-[#2d2b57] pb-6 px-8">
                {isCopied ? (
                    <button
                        className="flex items-center rounded-full bg-emerald-600 px-5 py-2 text-white text-sm"
                        onClick={() => {
                            copyToClipboard(codeSnippet);
                            copy(true);
                        }}
                    >
                        <VscCheck className="mr-1.5 text-base" />
                        Copied
                    </button>
                ) : (
                    <button
                        className="flex items-center rounded-full bg-[#444181] hover:bg-opacity-50 px-5 py-2 text-white text-sm"
                        onClick={() => {
                            copyToClipboard(codeSnippet);
                            copy(true);
                        }}
                    >
                        <VscCopy className="mr-1.5 text-base" />
                        Copy
                    </button>
                )}
            </div>

            <p className="antialiased mt-6 mb-3 text-base text-slate-800">{`You will also need to place a <div> block, with the class name “hagrid” where you need the hagrid widget.`}</p>
            <p className="antialiased mb-3 text-base text-slate-800">{`For detailed instructions go to one of the guides.`}</p>
            <div className="flex flex-wrap">
                <button
                    className="ml-3 mt-2 w-40 h-14 bg-white hover:bg-slate-50 rounded-lg border border-slate-200 flex items-center justify-center font-semibold text-lg text-slate-800"
                    onClick={() =>
                        openLink(
                            "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-any-page-with-Javascript-1c2fbf3e1add442f86b1f17794a68402?utm_source=app"
                        )
                    }
                >
                    JavaScript
                </button>

                <button
                    className="ml-3 mt-2 w-40 h-14 bg-white hover:bg-slate-50 rounded-lg border border-slate-200 flex items-center justify-center"
                    onClick={() =>
                        openLink(
                            "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-Webflow-site-36a4873b13764041a599c29a0c4e5854?utm_source=app"
                        )
                    }
                >
                    <img src="/assets/images/webflow-icon.svg" width="90px" alt="webflow" />
                </button>

                <button
                    className="ml-3 mt-2 w-40 h-14 bg-white hover:bg-slate-50 rounded-lg border border-slate-200 flex items-center justify-center"
                    onClick={() =>
                        openLink(
                            "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-React-page-436bc3bca36c47e6aed28e2c4d0d416c?utm_source=app"
                        )
                    }
                >
                    <img src="/assets/images/react-icon.svg" width="90px" alt="reactjs" />
                </button>

                <button
                    className="ml-3 mt-2 w-40 h-14 bg-white hover:bg-slate-50 rounded-lg border border-slate-200 flex items-center justify-center"
                    onClick={() =>
                        openLink(
                            "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-Shopify-Store-9c2492ec23044e7192de6ba5d5889781?utm_source=app"
                        )
                    }
                >
                    <img src="/assets/images/shopify-icon.svg" width="90px" alt="shopify" />
                </button>

                <button
                    className="ml-3 mt-2 w-40 h-14 bg-white hover:bg-slate-50 rounded-lg border border-slate-200 flex items-center justify-center"
                    onClick={() =>
                        openLink(
                            "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-Wordpress-Page-fd6651d1b90f45728fd60048e5d5a158?utm_source=app"
                        )
                    }
                >
                    <img src="/assets/images/wordpress-icon.svg" width="110px" alt="wordpress" />
                </button>

                <button
                    className="ml-3 mt-2 w-40 h-14 bg-white hover:bg-slate-50 rounded-lg border border-slate-200 flex items-center justify-center"
                    onClick={() =>
                        openLink(
                            "https://aarzoo.notion.site/Add-the-hagrid-QnA-widget-to-your-Carrd-Page-aaf5b4aa0f794f6285a86fd30d45fe50?utm_source=app"
                        )
                    }
                >
                    <img src="/assets/images/carrd-icon.svg" width="80px" alt="carrd" />
                </button>
            </div>
        </div>
    );
}

export default withRouter(SetupInstructions);
