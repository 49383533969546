import React, { useContext } from "react";
import { FiChevronRight } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import WebsiteContext from "../context/website-context";

function UpgradeNudge(props) {
    const history = useHistory();
    const websiteConfig = useContext(WebsiteContext);

    return websiteConfig.isSubscriptionActive || websiteConfig.websiteConfigIsLoading ? (
        <></>
    ) : websiteConfig.trialStatus === "active" ? (
        <div
            className={`${
                websiteConfig.trialDaysLeft > 7
                    ? "bg-orange-100 hover:bg-orange-200 text-zinc-800"
                    : "bg-rose-500 hover:bg-rose-600 text-white"
            } w-full px-6 py-3 rounded-b-lg flex flex-row justify-between items-center antialiased cursor-pointer`}
            onClick={() => {
                history.push("/upgrade");
            }}
        >
            <p className="text-sm font-semibold">
                {websiteConfig.trialDaysLeft} days remaining in your free trial.{" "}
                <span className="underline decoration-dashed decoration-1 underline-offset-4"> Upgrade </span> before
                the trial ends. Get 50% off for your first 3 months.
            </p>
            <FiChevronRight className="text-lg" />
        </div>
    ) : (
        <div
            className="w-full px-6 py-3 bg-rose-500 hover:bg-rose-600 rounded-b-lg flex flex-row justify-between items-center antialiased cursor-pointer"
            onClick={() => {
                history.push("/upgrade");
            }}
        >
            <p className="text-sm font-semibold text-white">
                Your free trial is over. The hagrid widget on your site is now hidden.{" "}
                <span className="underline decoration-dashed decoration-1 underline-offset-4"> Activate </span> the paid
                plan today, to continue using hagrid.
            </p>
            <FiChevronRight className="text-white text-lg" />
        </div>
    );
}
export default UpgradeNudge;
