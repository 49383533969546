import axios from "axios";
import qs from "qs";
import { getAPIBaseUrl, getApiVersion, getItemFromLocalStorage } from "./utils";

export async function getQuestions(pageUrl, currentPage, pageSize, statusFilter) {
    const accessToken = getItemFromLocalStorage("access-token");
    const websiteId = getItemFromLocalStorage("website-id");
    const apiBaseUrl = getAPIBaseUrl();
    const apiVersion = getApiVersion();

    let payload = {
        page_url: pageUrl,
        page: currentPage,
        page_size: pageSize,
        status: statusFilter,
        website_id: websiteId
    };

    let headers = {
        accessToken: accessToken,
        "X-API-Version": apiVersion
    };

    let resp = {};

    await axios
        .post(`${apiBaseUrl}/posts/`, qs.stringify(payload), { headers: headers })
        .then((response) => {
            if (response.status === 200) {
                resp.data = response.data;
            }
        })
        .catch((error) => {
            console.log("Error in fetching questions");
            console.log(error.response);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                resp.error = error.response.data.detail;
                localStorage.removeItem("access-token");
            }
        });
    return resp;
}

export async function addQuestion(question, answer, pageUrls) {
    const accessToken = getItemFromLocalStorage("access-token");
    const websiteId = getItemFromLocalStorage("website-id");
    let apiBaseUrl = getAPIBaseUrl();
    let apiVersion = getApiVersion();

    let payload = {
        post_blocks: question,
        comment_blocks: answer,
        page_urls: pageUrls,
        website_id: websiteId
    };

    let resp = {};

    await axios
        .post(`${apiBaseUrl}/posts/create/`, payload, {
            headers: { accessToken: accessToken, "X-Api-Version": apiVersion }
        })
        .then((response) => {
            if (response.status === 200) {
                resp.data = response.data;
            }
        })
        .catch((error) => {
            console.log("Error in adding question");
            console.log(error.response);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                resp.error = error.response.data.detail;
                localStorage.removeItem("access-token");
            }
        });
    return resp;
}

export async function updateQuestion(questionId, questionStatus, question, answer, pageUrls, answerId) {
    const accessToken = getItemFromLocalStorage("access-token");
    const websiteId = getItemFromLocalStorage("website-id");
    let apiBaseUrl = getAPIBaseUrl();
    let apiVersion = getApiVersion();

    let payload = {
        status: questionStatus,
        post_blocks: question,
        comment_blocks: answer,
        comment_id: answerId,
        page_urls: pageUrls,
        website_id: websiteId
    };

    let resp = {};

    await axios
        .put(`${apiBaseUrl}/posts/${questionId}/`, payload, {
            headers: { accessToken: accessToken, "X-Api-Version": apiVersion }
        })
        .then((response) => {
            if (response.status === 200) {
                resp.data = response.data;
            }
        })
        .catch((error) => {
            console.log("Error in updating question");
            console.log(error.response);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                resp.error = error.response.data.detail;
                localStorage.removeItem("access-token");
            }
        });
    return resp;
}

export async function getPages(createdBy, searchTerm) {
    const accessToken = getItemFromLocalStorage("access-token");
    const websiteId = getItemFromLocalStorage("website-id");
    let apiBaseUrl = getAPIBaseUrl();
    let apiVersion = getApiVersion();

    let apiUrl = `${apiBaseUrl}/posts/pages/?website_id=${websiteId}&created_by=${createdBy}`;

    if (searchTerm !== "" && searchTerm !== undefined) {
        apiUrl = apiUrl + `&search_term=${searchTerm}`;
    }

    let resp = {};

    await axios
        .get(apiUrl, {
            headers: { accessToken: accessToken, "X-API-Version": apiVersion }
            // headers: { accessToken: accessToken }
        })
        .then((response) => {
            if (response.status === 200) {
                resp.data = response.data;
            }
        })
        .catch((error) => {
            console.log("Error in fetching pages");
            console.log(error.response);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                resp.error = error.response.data.detail;
                localStorage.removeItem("access-token");
            }
        });
    return resp;
}

export async function getOnboardingSteps() {
    const accessToken = getItemFromLocalStorage("access-token");
    let apiBaseUrl = getAPIBaseUrl();
    let apiVersion = getApiVersion();

    let apiUrl = `${apiBaseUrl}/posts/onboarding/`;

    let resp = {};

    await axios
        .get(apiUrl, {
            headers: { accessToken: accessToken, "X-API-Version": apiVersion }
        })
        .then((response) => {
            if (response.status === 200) {
                resp.data = response.data;
            }
        })
        .catch((error) => {
            console.log("Error in getting onboarding steps");
            console.log(error.response);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                resp.error = error.response.data.detail;
                localStorage.removeItem("access-token");
            }
        });
    return resp;
}

export async function updateOnboardingStep(stepId, action) {
    const accessToken = getItemFromLocalStorage("access-token");
    let apiBaseUrl = getAPIBaseUrl();
    let apiVersion = getApiVersion();

    let payload = {
        step_id: stepId,
        action: action
    };

    let resp = {};

    await axios
        .post(`${apiBaseUrl}/posts/onboarding/`, payload, {
            headers: { accessToken: accessToken, "X-Api-Version": apiVersion }
        })
        .then((response) => {
            if (response.status === 200) {
                resp.data = response.data;
            }
        })
        .catch((error) => {
            console.log("Error in updating onboarding step");
            console.log(error.response);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                resp.error = error.response.data.detail;
                localStorage.removeItem("access-token");
            }
        });
    return resp;
}

export async function updateSortOrder(pageId, sortOrder, questionSequence) {
    const accessToken = getItemFromLocalStorage("access-token");
    let apiBaseUrl = getAPIBaseUrl();
    let apiVersion = getApiVersion();

    let payload = {
        page_id: pageId,
        sort_order: sortOrder,
        post_sequence: questionSequence
    };

    let resp = {};

    await axios
        .put(`${apiBaseUrl}/posts/pages/`, payload, {
            headers: { accessToken: accessToken, "X-Api-Version": apiVersion }
        })
        .then((response) => {
            if (response.status === 200) {
                resp.data = response.data;
            }
        })
        .catch((error) => {
            console.log("Error in updating sort order");
            console.log(error.response);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                resp.error = error.response.data.detail;
                localStorage.removeItem("access-token");
            }
        });
    return resp;
}

export async function openStripeCheckout(billingPeriod) {
    const accessToken = getItemFromLocalStorage("access-token");
    let apiBaseUrl = getAPIBaseUrl();

    let payload = {
        billing_period: billingPeriod
    };

    let resp = {};

    await axios
        .post(`${apiBaseUrl}/oauth/checkout/`, payload, { headers: { accessToken: accessToken } })
        .then((response) => {
            if (response.status === 200) {
                resp.data = response.data;
            }
        })
        .catch((error) => {
            console.log("Error in opening stripe checkout");
            console.log(error.response);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                resp.error = error.response.data.detail;
                localStorage.removeItem("access-token");
            }
        });
    return resp;
}

export async function openStripeBilling() {
    let accessToken = getItemFromLocalStorage("access-token");
    if (!accessToken) {
        window.open("/login", "_self");
    }

    let apiBaseUrl = getAPIBaseUrl();

    await axios
        .get(`${apiBaseUrl}/oauth/billing-portal/`, {
            headers: { accessToken: accessToken }
        })
        .then((response) => {
            if (response.status === 200) {
                window.open(response.data.billing_portal_url, "_self");
            }
        })
        .catch((error) => {
            console.log("No subscription created for this user");
            console.log(error);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                localStorage.removeItem("access-token");
                window.open("/login", "_self");
            }
        });
}

export async function getTextConfig() {
    let resp;
    await axios
        .get("https://api.airtable.com/v0/app2BoEL0OVZy5TXu/hagrid", {
            headers: {
                Authorization: "Bearer key6C3dJB9iYN3tbf"
            }
        })
        .then((response) => {
            console.log(response);
            resp = response;
        })
        .catch((error) => {
            console.log("Error in getting text config");
            console.log(error);
        });
    return resp;
}

export async function getWebsiteConfig() {
    const accessToken = getItemFromLocalStorage("access-token");
    const apiBaseUrl = getAPIBaseUrl();

    let resp = {};

    await axios
        .get(`${apiBaseUrl}/posts/config/`, { headers: { accessToken: accessToken } })
        .then((response) => {
            if (response.status === 200) {
                resp.data = response.data;
            }
        })
        .catch((error) => {
            console.log("Error in getting website config");
            console.log(error.response);
            if (error.response.data.detail === "Signature has expired.") {
                // remove access token from local storage
                resp.error = error.response.data.detail;
                localStorage.removeItem("access-token");
            }
        });
    return resp;
}
