import React from "react";
import axios from "axios";
import qs from "qs";
import { getAPIBaseUrl, getItemFromLocalStorage } from "../../utils/utils";
import ProfileView from "./profile-view";

class ProfileContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileImageUrl: "",
            userName: "",
            presignedUrl: "",
            presignedFields: "",
            profileImageName: "",
            profileImage: "",
            isEditProfileOpen: false,
            profileDetailsAreLoading: true
        };
        this.toggleEditProfileState = this.toggleEditProfileState.bind(this);
    }

    toggleEditProfileState = async (newState) => {
        await this.setState({
            isEditProfileOpen: newState
        });
    };

    getProfileImage = async (e) => {
        await this.setState(
            {
                profileImage: e.target.files[0],
                profileImageUrl: URL.createObjectURL(e.target.files[0]),
                profileImageName: e.target.files[0].name
            },
            () => {
                this.uploadImage(this.state.websiteId, this.state.profileImageName);
            }
        );
    };

    onProfileInputChange = async (e) => {
        await this.setState({
            userName: e.target.value
        });
    };

    uploadImage = async (websiteId, fileName) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
        }

        let apiBaseUrl = getAPIBaseUrl();

        let payload = {
            website_id: websiteId,
            file_name: fileName
        };

        await axios
            .post(`${apiBaseUrl}/oauth/signed-url/`, qs.stringify(payload), { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        presignedUrl: response.data.url,
                        presignedFields: response.data.fields
                    });
                    this.uploadImageToServer(response.data.url, this.state.profileImage, response.data.fields);
                }
            })
            .catch((error) => {
                console.log("Error in getting presigned URL");
                console.log(error);
            });
    };

    // Upload image using presigned URL
    uploadImageToServer = async (presignedUrl, profileImage, presignedFields) => {
        let data = new FormData();
        // Iterate over all key/value pairs of presignedFields object
        for (var key in presignedFields) {
            data.append(key, presignedFields[key]);
        }

        data.append("file", profileImage);

        axios
            .post(presignedUrl, data, { headers: { "Content-Type": "image/*" } })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log("Error in uploading image to S3");
                console.log(error);
            });
    };

    getProfileDetails = async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
        }

        let apiBaseUrl = getAPIBaseUrl();

        this.setState({
            profileDetailsAreLoading: true
        });

        await axios
            .get(`${apiBaseUrl}/oauth/users/`, { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data);
                    if (response.data.user_profile.image_url === null && response.data.user_profile.name === null) {
                        this.setState({
                            isEditProfileOpen: true
                        });
                    }

                    this.setState({
                        profileImageUrl: response.data.user_profile.image_url,
                        userName: response.data.user_profile.name,
                        profileDetailsAreLoading: false
                    });

                    // Update crisp data
                    window.$crisp.push(["set", "user:nickname", [response.data.user_profile.name]]);
                    window.$crisp.push(["set", "user:email", [response.data.user_profile.email]]);
                    window.$crisp.push(["set", "user:avatar", [response.data.user_profile.image_url]]);

                    // Identify user for posthog
                    window.posthog.identify(
                        response.data.user_profile.id, // distinct_id, required
                        { userName: response.data.user_profile.name, userEmail: response.data.user_profile.email }
                    );
                }
            })

            .catch((error) => {
                console.log("Error in getting profile details");
                console.log(error);
                if (error.response.data.detail === "Signature has expired.") {
                    // remove access token from local storage
                    localStorage.removeItem("access-token");
                    this.props.history.push("/login");
                }
            });
    };

    updateProfileDetails = async (userName, presignedUrl, presignedFieldsKey) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
        }

        let apiBaseUrl = getAPIBaseUrl();

        let imageUrl;
        if (presignedUrl && presignedFieldsKey) {
            imageUrl = presignedUrl + presignedFieldsKey;
        } else {
            imageUrl = "";
        }

        this.setState({
            profileDetailsAreLoading: true
        });

        let payload = {
            name: userName,
            image_url: imageUrl
        };

        await axios
            .post(`${apiBaseUrl}/oauth/users/`, qs.stringify(payload), { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data);
                    console.log("Profile details updated successfully");
                    this.setState({
                        profileDetailsAreLoading: false,
                        isEditProfileOpen: false
                    });
                }
            })
            .catch((error) => {
                console.log("Error in updating profile details");
                console.log(error);
            });
    };

    onProfileUpdate = async () => {
        await this.updateProfileDetails(this.state.userName, this.state.presignedUrl, this.state.presignedFields.key);
    };

    componentDidMount() {
        document.title = "Profile - hagrid";
        this.getProfileDetails();
    }

    render() {
        return (
            <ProfileView
                pageIsLoading={this.state.profileDetailsAreLoading}
                userName={this.state.userName}
                profileImageUrl={this.state.profileImageUrl}
                toggleEditProfileState={this.toggleEditProfileState}
                getProfileImage={this.getProfileImage}
                onProfileInputChange={this.onProfileInputChange}
                onProfileUpdate={this.onProfileUpdate}
                isEditProfileOpen={this.state.isEditProfileOpen}
            />
        );
    }
}

export default ProfileContainer;
