import defaultThemeConfig from "../utils/default-theme-config.json";

export function getAPIBaseUrl() {
    return process.env.REACT_APP_API_BASE_URL;
    // return "https://api-dev-hagrid.gansy.io";
}

// Remove extra spaces at the beginning and end of a string
export function removeExtraSpaces(str) {
    return str.replace(/^\s+|\s+$/g, "");
}

// Set value to a key in local storage
export function setItemInLocalStorage(key, value) {
    localStorage.setItem(key, value);
}

// Get value from a key in local storage
export function getItemFromLocalStorage(key) {
    return localStorage.getItem(key);
}

export function getApiVersion() {
    return "3.0";
}

// Remove substring from a string
export function removeSubstring(str, substr) {
    return str.replace(substr, "");
}

// Highlight element based on its class name
export function createPreviewHighlighter(document, className) {
    deletePreviewHighlighter(document);
    let elementsToHighlight = Array.from(document.getElementsByClassName(className));
    elementsToHighlight.forEach((elementToHighlight) => {
        // Get coordinates of the element
        const { top, left, width, height } = elementToHighlight.getBoundingClientRect();
        // Create the highlighter container
        var container = document.createElement("div");
        container.className = "hgrd-preview-highlighter-container";
        const borderClassNames = [
            "hgrd-preview-highlighter-top",
            "hgrd-preview-highlighter-left",
            "hgrd-preview-highlighter-right",
            "hgrd-preview-highlighter-bottom"
        ];
        // Create the invisible border elements
        borderClassNames.forEach((className) => {
            var element = document.createElement("div");
            element.className = className;
            element.style.position = "fixed";
            element.style.background = "rgb(168 85 247)";
            element.style.height = "0px";
            element.style.width = "0px";
            element.style.transition = "all 150ms ease";
            element.style.zIndex = "100";
            // make the border elements visible
            switch (className) {
                case "hgrd-preview-highlighter-top":
                    element.style.width = `${width + 5}px`;
                    element.style.height = "3px";
                    element.style.top = `${top - 4}px`;
                    element.style.left = `${left - 4}px`;
                    break;
                case "hgrd-preview-highlighter-left":
                    element.style.width = "3px";
                    element.style.height = `${height + 8}px`;
                    element.style.top = `${top - 4}px`;
                    element.style.left = `${left - 5}px`;
                    break;
                case "hgrd-preview-highlighter-right":
                    element.style.width = "3px";
                    element.style.height = `${height + 8}px`;
                    element.style.top = `${top - 4}px`;
                    element.style.left = `${left + width + 1}px`;
                    break;
                case "hgrd-preview-highlighter-bottom":
                    element.style.width = `${width + 4}px`;
                    element.style.height = "3px";
                    element.style.top = `${top + height + 1}px`;
                    element.style.left = `${left - 3}px`;
                    break;
                default:
                    break;
            }
            container.appendChild(element);
        });
        document.body.appendChild(container);
    });
}

// Remove highlight
export function deletePreviewHighlighter(document) {
    Array.from(document.getElementsByClassName("hgrd-preview-highlighter-container")).forEach((element) =>
        element.remove()
    );
}

// Get theme config value
export function getThemeConfigValue(themeConfig, propertyName) {
    if (themeConfig[propertyName] !== undefined) {
        return themeConfig[propertyName];
    } else if (defaultThemeConfig[propertyName] !== undefined) {
        return defaultThemeConfig[propertyName];
    } else {
        return "";
    }
}

// Get url parameter from the current page url
export function getUrlParameter(name) {
    const url = new URL(window.location.href);
    return url.searchParams.get(name);
}

// Set url parameter in the current page url
export function setUrlParameter(name, value) {
    const url = new URL(window.location.href);
    url.searchParams.set(name, value);
    window.history.replaceState({}, "", url.href);
}
