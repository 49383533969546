import React from "react";
import { NavLink } from "react-router-dom";
import { VscExtensions } from "react-icons/vsc";
import { RiUser4Line } from "react-icons/ri";
import { AiOutlineFormatPainter, AiOutlineEdit } from "react-icons/ai";

function SettingsNav(props) {
    return (
        <nav className="flex flex-row justify-center items-center">
            <NavLink
                className="hover:bg-slate-50 rounded-lg text-slate-800 py-1.5 px-4 flex items-center justify-center lg:justify-start shrink-0"
                activeClassName=" py-1.5 px-4 flex justify-center lg:justify-start items-center shrink-0 bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                exact
                to="/settings/profile"
            >
                <RiUser4Line className="text-lg" />
                <p className="text-sm ml-2 font-normal">Profile</p>
            </NavLink>
            <NavLink
                className="hover:bg-slate-50 rounded-lg text-slate-800 py-1.5 px-4 flex items-center justify-center lg:justify-start shrink-0"
                activeClassName=" py-1.5 px-4 flex justify-center lg:justify-start items-center shrink-0 bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                exact
                to="/settings/setup-instructions"
            >
                <VscExtensions className="text-lg" />
                <p className="text-sm ml-2 font-normal">Setup Instructions</p>
            </NavLink>
            <NavLink
                className="hover:bg-slate-50 rounded-lg text-slate-800 py-1.5 px-4 flex items-center justify-center lg:justify-start shrink-0"
                activeClassName=" py-1.5 px-4 flex justify-center lg:justify-start items-center shrink-0 bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                exact
                to="/settings/design"
            >
                <AiOutlineFormatPainter className="text-lg" />
                <p className="text-sm ml-2 font-normal">Design</p>
            </NavLink>
            <NavLink
                className="hover:bg-slate-50 rounded-lg text-slate-800 py-1.5 px-4 flex items-center justify-center lg:justify-start shrink-0"
                activeClassName=" py-1.5 px-4 flex justify-center lg:justify-start items-center shrink-0 bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                exact
                to="/settings/content"
            >
                <AiOutlineEdit className="text-lg" />
                <p className="text-sm ml-2 font-normal">Text</p>
            </NavLink>
        </nav>
    );
}

export default SettingsNav;
