import React, { useState } from "react";
import { removeSubstring, getThemeConfigValue } from "../../../utils/utils";

function MultiInputElement(props) {
    const [isChecked, setChecked] = useState(props.isSameForAllDevices);

    let elementName;
    if (props.deviceLevelConfig) {
        if (isChecked) {
            elementName = props.selectorName;
        } else {
            elementName = props.selectorName + "-" + props.device;
        }
    } else {
        elementName = props.selectorName;
    }

    return (
        <div className="my-8">
            <div className="flex flex-row justify-between">
                <p className="text-mine-shaft text-base">{props.propertyName}</p>
                <label
                    className={`${props.deviceLevelConfig ? "block" : "hidden"} flex flex-row items-end cursor-pointer`}
                >
                    <input
                        type="checkbox"
                        className="h-4 w-4 cursor-pointer text-mine-shaft rounded-sm border-mine-shaft"
                        defaultChecked={isChecked}
                        onChange={(e) => {
                            setChecked(e.target.checked);
                        }}
                    />
                    <span className="pl-2 text-mine-shaft text-xs font-normal">Same for all devices</span>
                </label>
            </div>
            <div className="flex flex-row items-center mt-4">
                <div>
                    <input
                        type="text"
                        name={isChecked ? props.selectorName + "t" : props.selectorName + "t-" + props.device}
                        className="w-16 mr-2.5 text-center outline-none focus:outline-none text-base text-mine-shaft border border-slate-400 focus:ring-0 focus:border-purple-300 rounded-md"
                        // value={
                        //     props.themeConfig[props.selectorName + "t-" + props.device]
                        //         ? removeSubstring(props.themeConfig[props.selectorName + "t-" + props.device], "px")
                        //         : ""
                        // }
                        value={removeSubstring(
                            getThemeConfigValue(props.themeConfig, props.selectorName + "t-" + props.device),
                            "px"
                        )}
                        onChange={
                            isChecked && props.deviceLevelConfig
                                ? props.changeDimensionInputOnAllDevices
                                : props.changeDimensionInput
                        }
                    />
                    <p className="mt-2 text-xs text-slate-700 text-center antialiased">Top</p>
                </div>

                <div>
                    <input
                        type="text"
                        name={isChecked ? props.selectorName + "r" : props.selectorName + "r-" + props.device}
                        className="w-16 mr-2.5 text-center outline-none focus:outline-none text-base text-mine-shaft border border-slate-400 focus:ring-0 focus:border-purple-300 rounded-md"
                        // value={
                        //     props.themeConfig[props.selectorName + "r-" + props.device]
                        //         ? removeSubstring(props.themeConfig[props.selectorName + "r-" + props.device], "px")
                        //         : ""
                        // }
                        value={removeSubstring(
                            getThemeConfigValue(props.themeConfig, props.selectorName + "r-" + props.device),
                            "px"
                        )}
                        onChange={
                            isChecked && props.deviceLevelConfig
                                ? props.changeDimensionInputOnAllDevices
                                : props.changeDimensionInput
                        }
                    />
                    <p className="mt-2 text-xs text-slate-700 text-center antialiased">Right</p>
                </div>

                <div>
                    <input
                        type="text"
                        name={isChecked ? props.selectorName + "b" : props.selectorName + "b-" + props.device}
                        className="w-16 mr-2.5 text-center outline-none focus:outline-none text-base text-mine-shaft border border-slate-400 focus:ring-0 focus:border-purple-300 rounded-md"
                        // value={
                        //     props.themeConfig[props.selectorName + "b-" + props.device]
                        //         ? removeSubstring(props.themeConfig[props.selectorName + "b-" + props.device], "px")
                        //         : ""
                        // }
                        value={removeSubstring(
                            getThemeConfigValue(props.themeConfig, props.selectorName + "b-" + props.device),
                            "px"
                        )}
                        onChange={
                            isChecked && props.deviceLevelConfig
                                ? props.changeDimensionInputOnAllDevices
                                : props.changeDimensionInput
                        }
                    />
                    <p className="mt-2 text-xs text-slate-700 text-center antialiased">Bottom</p>
                </div>

                <div>
                    <input
                        type="text"
                        name={isChecked ? props.selectorName + "l" : props.selectorName + "l-" + props.device}
                        className="w-16 mr-2.5 text-center outline-none focus:outline-none text-base text-mine-shaft border border-slate-400 focus:ring-0 focus:border-purple-300 rounded-md"
                        // value={
                        //     props.themeConfig[props.selectorName + "l-" + props.device]
                        //         ? removeSubstring(props.themeConfig[props.selectorName + "l-" + props.device], "px")
                        //         : ""
                        // }
                        value={removeSubstring(
                            getThemeConfigValue(props.themeConfig, props.selectorName + "l-" + props.device),
                            "px"
                        )}
                        onChange={
                            isChecked && props.deviceLevelConfig
                                ? props.changeDimensionInputOnAllDevices
                                : props.changeDimensionInput
                        }
                    />
                    <p className="mt-2 text-xs text-slate-700 text-center antialiased">Left</p>
                </div>

                <p className="text-base text-mine-shaft">px</p>
            </div>
        </div>
    );
}

export default MultiInputElement;
