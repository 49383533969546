import React from "react";
import { SectionHeading } from "../utils/typography";

function ProfileDetails(props) {
    return (
        <div className="bg-white rounded-2xl py-10 max-w-lg shadow-container">
            <div className="px-12">
                <SectionHeading text="Your avatar" />
                <p className=" text-slate-800 font-normal mt-4 text-base antialiased">
                    Your Avatar will appear alongside your responses on your webpages. This could be your brand or your
                    own profile.
                </p>
            </div>

            <div className="flex flex-col items-center mt-5">
                <div className="h-32 w-32 rounded-full flex justify-center items-center">
                    <img src={props.profileImageUrl} alt="profile" className="h-32 w-32 object-cover rounded-full" />
                </div>

                <p className="text-masala font-medium text-lg mt-2 bg-white rounded-lg px-4 py-2.5">{props.userName}</p>

                <button
                    className="mt-5 px-8 py-3 bg-mine-shaft hover:bg-black text-sm text-white font-semibold rounded-lg"
                    // className='w-full mt-8 py-3 bg-violet-600 hover:bg-violet-800 text-sm text-white font-semibold rounded-lg'
                    onClick={() => props.toggleEditProfileState(true)}
                >
                    Edit Avatar
                </button>
            </div>
        </div>
    );
}

export default ProfileDetails;
