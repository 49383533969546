import React, { useState } from "react";
import { removeSubstring, getThemeConfigValue } from "../../../utils/utils";

function SingleInputElement(props) {
    const [isChecked, setChecked] = useState(props.isSameForAllDevices);

    let elementName, elementKeyForValue;
    if (props.deviceLevelConfig) {
        elementKeyForValue = props.selectorName + "-" + props.device;

        if (isChecked) {
            elementName = props.selectorName;
        } else {
            elementName = props.selectorName + "-" + props.device;
        }
    } else {
        elementName = props.selectorName;
        elementKeyForValue = props.selectorName;
    }

    return (
        <div className="my-8">
            <div className="flex flex-row justify-between">
                <p className="text-mine-shaft text-base">{props.propertyName}</p>
                <label
                    className={`${props.deviceLevelConfig ? "block" : "hidden"} flex flex-row items-end cursor-pointer`}
                >
                    <input
                        type="checkbox"
                        className="h-4 w-4 cursor-pointer text-mine-shaft rounded-sm border-mine-shaft"
                        defaultChecked={isChecked}
                        onChange={(e) => {
                            setChecked(e.target.checked);
                        }}
                    />
                    <span className="pl-2 text-mine-shaft text-xs font-normal">Same for all devices</span>
                </label>
            </div>
            <div className="flex items-center mt-4">
                <input
                    type="text"
                    name={elementName}
                    className="w-24 outline-none text-center focus:outline-none text-base text-mine-shaft border border-slate-400 focus:ring-0 focus:border-purple-300 rounded-md"
                    // value={  props.themeConfig[elementKeyForValue] ? removeSubstring(props.themeConfig[elementKeyForValue], 'px') : '' }
                    value={removeSubstring(getThemeConfigValue(props.themeConfig, elementKeyForValue), "px")}
                    onChange={
                        isChecked && props.deviceLevelConfig
                            ? props.changeDimensionInputOnAllDevices
                            : props.changeDimensionInput
                    }
                />
                <p className="text-base text-mine-shaft ml-2.5">px</p>
            </div>
        </div>
    );
}

export default SingleInputElement;
