import React, { useEffect } from "react";
import Frame from "react-frame-component";
import PreviewScreen from "./preview-screen";

function PreviewFrame(props) {
    let children = document.head.childNodes;
    let transformedHeadElement = document.createElement("div");
    children.forEach((child) => {
        if (child.nodeName === "STYLE" || child.nodeName === "LINK") {
            var cln = child.cloneNode(true);
            transformedHeadElement.appendChild(cln);
        }
    });
    let rootElement = document.createElement("div");
    for (const [property, propertyValue] of Object.entries(props.themeConfig)) {
        rootElement.style.setProperty(`--${property}`, propertyValue);
    }

    let fontFamily = props.themeConfig["widget-font-family"];

    let fontWeights = new Set();
    for (let element in props.themeConfig) {
        if (element.includes("font-weight")) {
            fontWeights.add(props.themeConfig[element]);
        }
    }

    if (fontWeights.size > 0) {
        let fontWeightsStr = Array.from(fontWeights).sort().join(",");
        fontFamily = fontFamily + ":" + `${fontWeightsStr}`;
    }

    // props.themeConfig["widget-font-family"]

    let fontElement = document.createElement("link");
    fontElement.rel = "stylesheet";
    fontElement.href = `https://fonts.googleapis.com/css?family=${fontFamily}`;
    transformedHeadElement.appendChild(fontElement);

    const initialContent = `<!DOCTYPE html><html><head>${transformedHeadElement.innerHTML}</head><body>${rootElement.outerHTML}</body></html>`;

    let iFrameWidth, iFrameHeight, iFrameScale;
    if (props.device === "desktop") {
        iFrameWidth = "1062px";
        iFrameHeight = "870px";
        iFrameScale = `scale(${props.scale})`;
    } else if (props.device === "mobile") {
        iFrameWidth = "375px";
        iFrameHeight = "750px";
        iFrameScale = `scale(${props.scale})`;
    } else if (props.device === "tablet") {
        iFrameWidth = "768px";
        iFrameHeight = "800px";
        iFrameScale = `scale(${props.scale})`;
    }

    return (
        <div className="flex justify-center pt-8">
            {props.openInModal ? (
                <div className={"relative"}>
                    <Frame
                        initialContent={initialContent}
                        style={{
                            border: "1px solid",
                            borderColor: "rgb(203 213 225)",
                            width: iFrameWidth,
                            height: iFrameHeight,
                            transformOrigin: "left top",
                            transform: iFrameScale,
                            display: "block",
                            postion: "absolute",
                            top: "0px",
                            left: "0px",
                            boxShadow: "0 0px 20px 0px rgb(0 0 0 / 0.10)",
                            overflow: "scroll"
                        }}
                    >
                        <PreviewScreen
                            // themeConfig={ props.themeConfig }
                            selectedTheme={props.selectedTheme}
                            selectedElementClass={props.selectedElementClass}
                            device={props.device}
                        />
                    </Frame>
                </div>
            ) : (
                <div
                    className={`${
                        props.device === "desktop" ? "w-[40rem]" : props.device === "mobile" ? "w-[16rem]" : "w-[29rem]"
                    } h-[33rem] relative`}
                >
                    <Frame
                        initialContent={initialContent}
                        style={{
                            border: "1px solid",
                            borderColor: "rgb(203 213 225)",
                            width: iFrameWidth,
                            height: iFrameHeight,
                            transformOrigin: "left top",
                            transform: iFrameScale,
                            display: "block",
                            postion: "absolute",
                            top: "0px",
                            left: "0px",
                            boxShadow: "0 0px 20px 0px rgb(0 0 0 / 0.10)",
                            overflow: "scroll"
                        }}
                    >
                        <PreviewScreen
                            selectedTheme={props.selectedTheme}
                            selectedElementClass={props.selectedElementClass}
                            device={props.device}
                        />
                    </Frame>
                </div>
            )}
        </div>
    );
}

export default PreviewFrame;
