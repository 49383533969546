import React from "react";
import axios from "axios";
import qs from "qs";
import { getAPIBaseUrl, getItemFromLocalStorage, setItemInLocalStorage } from "../utils/utils";
import DashboardView from "./dashboard-view";
import { getOnboardingSteps, updateOnboardingStep } from "../utils/apis";

class DashboardContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            steps: [],
            pageIsLoading: true
        };
        this.updateStep = this.updateStep.bind(this);
    }

    getSteps = async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        this.setState({ pageIsLoading: true });

        const resp = await getOnboardingSteps();
        if (resp.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }
        if (resp.data.current_status.status === "FINISHED") {
            setItemInLocalStorage("onboarded", true);
            this.props.history.push("/questions");
            return;
        }
        setItemInLocalStorage("onboarded", false);
        if (resp.data.current_status.status_details?.action_details) {
            this.setState({
                steps: resp.data.current_status.status_details.action_details,
                pageIsLoading: false
            });
        }
    };

    updateStep = async (stepId, stepStatus) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        const resp = await updateOnboardingStep(stepId, stepStatus);
        if (resp.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }
        if (resp.data.is_updated) {
            this.getSteps();
        }
    };

    componentDidMount = () => {
        document.title = "Setup - hagrid";
        this.getSteps();
    };

    render() {
        // console.log(this.state);
        return (
            <DashboardView
                updateOnboardingStep={this.updateStep}
                steps={this.state.steps}
                // pageIsLoading={this.state.pageIsLoading}
            />
        );
    }
}

export default DashboardContainer;
