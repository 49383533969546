import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { BiSortAlt2 } from "react-icons/bi";
import SortQuestionsPopup from "../utils/sort-questions-popup";

function QuestionPageFilters(props) {
    const [isPageDropdownOpen, setIsPageDropdownOpen] = useState(false);
    const [isSortPopupOpen, setIsSortPopupOpen] = useState(false);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsPageDropdownOpen(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const pageSelectRef = useRef(null);
    useOutsideAlerter(pageSelectRef);

    let pageLinksList;
    if (props.pageLinks) {
        pageLinksList = (props.pageLinks || []).map((pageLink) => {
            return (
                <div
                    key={pageLink.id}
                    className={`${
                        props.selectedPageLink === pageLink.url
                            ? "bg-zinc-100 font-semibold"
                            : "bg-white hover:bg-zinc-50"
                    } flex flex-row items-center justify-between px-6 py-3 cursor-pointer last:hover:rounded-b-lg`}
                    onClick={() => {
                        props.onPageLinkChange(pageLink.url, pageLink.title);
                        setIsPageDropdownOpen(false);
                    }}
                >
                    {/* <div className={`${pageLink.post_count > 0 ? "w-5/6" : "w-full"}`}> */}
                    <div>
                        <p className="text-zinc-800 text-sm mb-1 line-clamp-2 empty:hidden">{pageLink.title}</p>
                        <p className="text-zinc-600 text-xs line-clamp-2 font-normal">{pageLink.url}</p>
                    </div>
                    {/* <div className={`${pageLink.post_count > 0 ? "block" : "hidden"} w-1/6 px-2`}>
                        <div className="h-3 rounded-full bg-rose-400"></div>
                    </div> */}
                </div>
            );
        });
    }
    return (
        <div className="flex flex-row items-center justify-between mb-4">
            {/* Select page filter */}
            <div className="flex flex-col max-w-xs w-full relative" ref={pageSelectRef}>
                <p className="text-xs text-zinc-400 font-semibold mb-2 antialiased">SELECT PAGE</p>
                <div
                    className="border border-zinc-200 bg-white py-3 px-6  flex items-center justify-between rounded-lg cursor-pointer"
                    onClick={() => setIsPageDropdownOpen(!isPageDropdownOpen)}
                >
                    <p className="text-sm text-zinc-800 line-clamp-1">
                        {props.selectedPageTitle || props.selectedPageLink}
                    </p>
                    <FiChevronDown className="text-lg text-zinc-800" />
                </div>
                <div
                    className={`${
                        isPageDropdownOpen ? "flex flex-col" : "hidden"
                    } bg-white mt-1 rounded-lg border border-zinc-200 z-10 absolute top-[4.5rem] shadow-lg divide-y divide-zinc-100 overflow-scroll w-full`}
                    style={{ maxHeight: "min(calc(100vh - 14rem), 24rem)" }}
                >
                    <div
                        className={`${
                            props.selectedPageLink === "all" ? "bg-zinc-100 font-semibold" : "bg-white hover:bg-zinc-50"
                        } flex flex-row items-center justify-between px-6 py-3 cursor-pointer hover:rounded-t-lg`}
                        onClick={() => {
                            props.onPageLinkChange("all", "All Pages");
                            setIsPageDropdownOpen(false);
                        }}
                    >
                        <p className="text-zinc-800 text-sm">All Pages</p>
                        {/* <div
                            className={`${
                                props.totalUnansweredQuestions && props.totalUnansweredQuestions > 0
                                    ? "block"
                                    : "hidden"
                            } w-1/6 px-2`}
                        >
                            <div className="h-3 bg-rose-400 rounded-full"></div>
                        </div> */}
                    </div>
                    {pageLinksList}
                </div>
            </div>

            {/* Sort */}
            {/* <div className="flex flex-col max-w-[12rem] w-full">
                <p className="text-xs text-zinc-400 font-semibold mb-2 antialiased">SORT</p>
                <button
                    className="border border-zinc-200 bg-white py-3 px-6  flex items-center justify-between rounded-lg cursor-pointer text-sm text-zinc-800 antialiased"
                    onClick={() => setIsSortPopupOpen(true)}
                >
                    Oldest first
                    <BiSortAlt2 className="text-lg" />
                </button>
            </div> */}

            {/* {isSortPopupOpen ? <SortQuestionsPopup setIsSortPopupOpen={setIsSortPopupOpen} /> : null} */}
        </div>
    );
}
export default QuestionPageFilters;
