import React, { useState, useEffect, useRef } from "react";
import { VscTrash, VscWarning, VscEyeClosed, VscEye, VscEdit } from "react-icons/vsc";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ConfirmationModal from "../confirmation-modal";
import AnswerTextbox from "./answer-textbox";
import AnswerView from "./answer-view";
import Editor from "../editor";
import { DropdownOptions } from "../buttons";
import AddFaq from "../../faq/add-faq";

function QuestionView(props) {
    const [isAnswerTextboxOpen, handleAnswerTextboxState] = useState(false);
    const [answerText, handleAnswerTextChange] = useState("");
    const [initialAnswerText, handleInitialAnswerTextChange] = useState("");
    const [isNewAnswer, handleNewAnswerState] = useState(true);
    const [answerToEdit, updateAnswerToEdit] = useState("");
    const [isQuestionPrivate, changeQuestionPublishState] = useState(false);
    const [questionOptionModalIsOpen, changeQuestionModalState] = useState(false);

    // Confirmation modals
    const [confirmationModalIsOpen, changeConfirmationModalState] = useState(false);
    const [confirmationModalType, changeConfirmationModalType] = useState("");
    const closeConfirmationModal = () => {
        changeConfirmationModalState(false);
        changeConfirmationModalType("");
    };
    //

    // FAQ question handling
    const [faqModalIsOpen, changeFaqModalState] = useState(false);
    const [isNewQuestion, handleNewQuestionState] = useState(true);

    const onChangeHandler = (e) => {
        handleAnswerTextChange(e.target.value);
    };

    const clearAnswerText = () => {
        handleAnswerTextChange("");
    };

    const onQuestionPublishStateChange = (e) => {
        changeQuestionPublishState(e.target.checked);
    };

    useEffect(() => {
        if (props.question.status === "PRIVATE") {
            changeQuestionPublishState(true);
        } else {
            changeQuestionPublishState(false);
        }
    }, [props.question.status]);

    useEffect(() => {
        if (props.answerSubmitted) {
            handleAnswerTextboxState(false);
            props.updateAnswerSubmittedState(false);
        }
    }, [props.answerSubmitted]);

    // Check if props.question.status changes
    // If props.question.status changes, update changeQuestionModalState to false
    useEffect(() => {
        changeQuestionModalState(false);
    }, [props.question.status]);

    // Handle click outside of the modal
    function useOutsideClick(ref, changeModalState) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    changeModalState(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const questionModalRef = useRef(null);
    useOutsideClick(questionModalRef, changeQuestionModalState);

    let renderConfirmationModal;
    if (confirmationModalIsOpen) {
        if (confirmationModalType === "delete-question") {
            renderConfirmationModal = (
                <ConfirmationModal
                    icon={<VscTrash />}
                    text="Are you sure? This can't be undone."
                    buttonText="Delete"
                    onButtonClick={() => props.updateQuestion(props.question.post_id, "DELETED")}
                    closeModal={closeConfirmationModal}
                />
            );
        } else if (confirmationModalType === "mark-question-as-spam") {
            renderConfirmationModal = (
                <ConfirmationModal
                    icon={<VscWarning />}
                    text="Are you sure you want to mark this question as spam?"
                    buttonText="Mark as spam"
                    onButtonClick={() => props.updateQuestion(props.question.post_id, "SPAM")}
                    closeModal={closeConfirmationModal}
                />
            );
        }
    } else {
        renderConfirmationModal = <></>;
    }

    let renderQuestionOptions;
    if (props.question.created_by === "app_user") {
        renderQuestionOptions = (
            <div className="relative">
                <HiOutlineDotsVertical
                    className="text-xl text-masala cursor-pointer hover:text-black"
                    onClick={() => changeQuestionModalState(!questionOptionModalIsOpen)}
                />
                <div
                    ref={questionModalRef}
                    className={`${
                        questionOptionModalIsOpen ? "block" : "hidden"
                    } z-10 shadow border border-slate-100 absolute top-7 right-0 rounded-lg bg-white whitespace-nowrap py-3`}
                >
                    <DropdownOptions
                        icon={<VscEdit className="text-lg" />}
                        buttonText={"Edit"}
                        onClick={() => {
                            handleNewQuestionState(false);
                            changeFaqModalState(true);
                        }}
                    />
                    <DropdownOptions
                        icon={<VscTrash className="text-lg" />}
                        buttonText={"Delete"}
                        onClick={() => {
                            changeConfirmationModalState(true);
                            changeConfirmationModalType("delete-question");
                        }}
                    />
                </div>
            </div>
        );
    } else {
        renderQuestionOptions = (
            <div className="relative">
                <HiOutlineDotsVertical
                    className="text-xl text-masala cursor-pointer hover:text-black"
                    onClick={() => changeQuestionModalState(!questionOptionModalIsOpen)}
                />
                <div
                    ref={questionModalRef}
                    className={`${
                        questionOptionModalIsOpen ? "block" : "hidden"
                    } z-10 shadow border border-slate-100 absolute top-7 right-0 rounded-lg bg-white whitespace-nowrap py-3`}
                >
                    {props.question.status === "PRIVATE" ? (
                        <DropdownOptions
                            icon={<VscEye className="text-lg" />}
                            buttonText={"Make public"}
                            onClick={() => props.updateQuestion(props.question.post_id, "PUBLISHED")}
                        />
                    ) : props.question.status === "PUBLISHED" ? (
                        <DropdownOptions
                            icon={<VscEyeClosed className="text-lg" />}
                            buttonText={"Make private"}
                            onClick={() => props.updateQuestion(props.question.post_id, "PRIVATE")}
                        />
                    ) : (
                        <></>
                    )}

                    <DropdownOptions
                        icon={<VscTrash className="text-lg" />}
                        buttonText={"Delete"}
                        onClick={() => {
                            changeConfirmationModalState(true);
                            changeConfirmationModalType("delete-question");
                        }}
                    />

                    <DropdownOptions
                        icon={<VscWarning className="text-lg" />}
                        buttonText={"Mark as spam"}
                        onClick={() => {
                            changeConfirmationModalState(true);
                            changeConfirmationModalType("mark-question-as-spam");
                        }}
                    />
                </div>
            </div>
        );
    }

    // Get list of pages from props.pages
    let listOfPages = [];
    if (props.question.pages) {
        props.question.pages.map((page) => {
            return listOfPages.push(page.title);
        });
    }

    return (
        <>
            <div className="px-8 py-4 rounded-lg bg-white shadow-container">
                <div className="flex justify-between items-center">
                    <p className="text-sm text-gray-500 font-normal">Question</p>
                    <div className="flex items-center">
                        <VscEyeClosed
                            className={`${
                                props.question.status === "PRIVATE" ? "block" : "hidden"
                            } mr-4 text-xl text-purple-400`}
                        />
                        {renderQuestionOptions}
                    </div>
                </div>
                <div className="mt-0.5 question">
                    <Editor
                        editorInstanceId={`question-${props.question.post_id}`}
                        editorInitialData={props.question}
                        readOnly={true}
                    />
                    <p
                        className={`${
                            props.question.created_by === "app_user" ? "hidden" : "block"
                        }  text-gray-500 text-xs font-light mt-1`}
                    >
                        {props.question.user.name} | {props.question.display_time}
                    </p>
                </div>

                {isAnswerTextboxOpen ? (
                    <div className="mt-8">
                        <AnswerTextbox
                            handleAnswerTextboxState={handleAnswerTextboxState}
                            answerQuestion={props.answerQuestion}
                            answerText={answerText}
                            onChangeHandler={onChangeHandler}
                            clearAnswerText={clearAnswerText}
                            questionId={props.question.post_id}
                            answerIsSubmitting={props.answerIsSubmitting}
                            answerSubmittingForQId={props.answerSubmittingForQId}
                            updateAnswer={props.updateAnswer}
                            isNewAnswer={isNewAnswer}
                            answerToEdit={answerToEdit}
                            onQuestionPublishStateChange={onQuestionPublishStateChange}
                            isQuestionPrivate={isQuestionPrivate}
                            handleAnswerTextChange={handleAnswerTextChange}
                            initialAnswerText={initialAnswerText}
                        />
                    </div>
                ) : props.question.comments === undefined || props.question.comments === null ? (
                    <button
                        className="mt-8 px-6 py-3 bg-zinc-800 hover:bg-zinc-900 text-sm text-white font-semibold rounded-lg"
                        onClick={() => {
                            handleNewAnswerState(true);
                            handleAnswerTextboxState(true);
                        }}
                    >
                        Answer
                    </button>
                ) : (
                    <div className="mt-8">
                        <AnswerView
                            question={props.question}
                            updateAnswer={props.updateAnswer}
                            questionId={props.question.post_id}
                            handleAnswerTextboxState={handleAnswerTextboxState}
                            handleAnswerTextChange={handleAnswerTextChange}
                            handleNewAnswerState={handleNewAnswerState}
                            updateAnswerToEdit={updateAnswerToEdit}
                            confirmationModalIsOpen={confirmationModalIsOpen}
                            confirmationModalType={confirmationModalType}
                            changeConfirmationModalState={changeConfirmationModalState}
                            changeConfirmationModalType={changeConfirmationModalType}
                            closeConfirmationModal={closeConfirmationModal}
                            handleInitialAnswerTextChange={handleInitialAnswerTextChange}
                        />
                    </div>
                )}

                <div className="border-b border-zinc-200 my-6"></div>
                <div className="flex items-center">
                    <p className="text-sm font-medium text-zinc-600">Pages:</p>
                    {/* {listOfPages[0] === "all" ? (
                        <p className="ml-8 text-gray-500 text-sm font-medium">All Pages</p>
                    ) : (
                        <p className="ml-8 text-gray-500 text-sm font-medium">{listOfPages.join(", ")}</p>
                    )} */}
                    <p className="ml-8 text-zinc-800 text-sm font-medium">{listOfPages.join(", ")}</p>
                </div>
            </div>

            {renderConfirmationModal}

            {faqModalIsOpen ? (
                <AddFaq
                    changeFaqModalState={changeFaqModalState}
                    question={props.question}
                    answer={props.question.comments[0]}
                    selectedPages={props.question.pages}
                    pageLinks={props.pageLinks}
                    allPagesSelected={props.allPagesSelected}
                    onCheckboxChange={props.onCheckboxChange}
                    deselectPage={props.deselectPage}
                    updateQuestionAddedState={props.updateQuestionAddedState}
                    questionAdded={props.questionAdded}
                    questionIsUpdating={props.questionIsUpdating}
                    isNewQuestion={isNewQuestion}
                    updateQuestion={props.updateQuestion}
                    pageLinksForAddingFaq={props.pageLinksForAddingFaq}
                />
            ) : null}
        </>
    );
}

export default QuestionView;
