import React from "react";
import ColorSelection from "../element-types/color-selection";
import SelectDropdown from "../element-types/select-dropdown";
import SingleInputElement from "../element-types/single-input-element";
import LayoutElementsActionBtns from "./utils/layout-elements-actions-btns";

function LoginOption(props) {
    let borderStyles = [
        { display: "Solid", value: "solid" },
        { display: "Dashed", value: "dashed" },
        { display: "Dotted", value: "dotted" },
        { display: "None", value: "none" }
    ];
    return (
        <>
            {/* Font Size */}
            <SingleInputElement
                selectorName="login-option-font-size"
                propertyName="Font Size"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["login-option-font-size-desktop"] ===
                        props.themeConfig["login-option-font-size-mobile"] &&
                    props.themeConfig["login-option-font-size-desktop"] ===
                        props.themeConfig["login-option-font-size-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Font Weight */}
            <SelectDropdown
                selectorName="login-option-font-weight"
                propertyName="Font Weight"
                themeConfig={props.themeConfig}
                changeSelectInput={props.changeSelectInput}
                changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["login-option-font-weight-desktop"] ===
                        props.themeConfig["login-option-font-weight-mobile"] &&
                    props.themeConfig["login-option-font-weight-desktop"] ===
                        props.themeConfig["login-option-font-weight-tablet"]
                        ? true
                        : false
                }
                device={props.device}
                options={props.fonts.find((obj) => obj.value === props.themeConfig["widget-font-family"]).variants}
            />

            {/* Font Color */}
            <ColorSelection
                selectorName="login-option-font-color"
                propertyName="Font Color"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["login-option-font-color-desktop"] ===
                        props.themeConfig["login-option-font-color-mobile"] &&
                    props.themeConfig["login-option-font-color-desktop"] ===
                        props.themeConfig["login-option-font-color-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Line Height */}
            <SingleInputElement
                selectorName="login-option-line-height"
                propertyName="Line Height"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["login-option-line-height-desktop"] ===
                        props.themeConfig["login-option-line-height-mobile"] &&
                    props.themeConfig["login-option-line-height-desktop"] ===
                        props.themeConfig["login-option-line-height-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Letter Spacing */}
            <SingleInputElement
                selectorName="login-option-letter-spacing"
                propertyName="Letter Spacing"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["login-option-letter-spacing-desktop"] ===
                        props.themeConfig["login-option-letter-spacing-mobile"] &&
                    props.themeConfig["login-option-letter-spacing-desktop"] ===
                        props.themeConfig["login-option-letter-spacing-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Background Color */}
            <ColorSelection
                selectorName="login-option-bg-color"
                propertyName="Background Color"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={false}
                device={props.device}
            />

            {/* Background Color on Hover */}
            <ColorSelection
                selectorName="login-option-hover-bg-color"
                propertyName="Background Color on Hover"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={false}
                device={props.device}
            />

            {/* Border Radius */}
            <SingleInputElement
                selectorName="login-option-border-radius"
                propertyName="Border Radius"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["login-option-border-radius-desktop"] ===
                        props.themeConfig["login-option-border-radius-mobile"] &&
                    props.themeConfig["login-option-border-radius-desktop"] ===
                        props.themeConfig["login-option-border-radius-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Border Style */}
            <SelectDropdown
                selectorName="login-option-border-style"
                propertyName="Border Style"
                themeConfig={props.themeConfig}
                changeSelectInput={props.changeSelectInput}
                changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                deviceLevelConfig={false}
                device={props.device}
                options={borderStyles}
            />

            {/* Border Width */}
            <SingleInputElement
                selectorName="login-option-border-width"
                propertyName="Border Width"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={false}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                device={props.device}
            />

            {/* Border Color */}
            <ColorSelection
                selectorName="login-option-border-color"
                propertyName="Border Color"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={false}
                device={props.device}
            />

            <LayoutElementsActionBtns
                themeConfig={props.themeConfig}
                updateThemeConfig={props.updateThemeConfig}
                resetThemeConfig={props.resetThemeConfig}
            />
        </>
    );
}

export default LoginOption;
