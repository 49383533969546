import React from "react";
import ColorSelection from "../element-types/color-selection";
import SingleInputElement from "../element-types/single-input-element";
import MultiInputElement from "../element-types/multi-input-element";
import LayoutElementsActionBtns from "./utils/layout-elements-actions-btns";

function Qtextbox(props) {
    return (
        <>
            {/* Padding */}
            <MultiInputElement
                selectorName="qtextbox-p"
                propertyName="Padding"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-pl-desktop"] === props.themeConfig["qtextbox-pl-mobile"] &&
                    props.themeConfig["qtextbox-pl-desktop"] === props.themeConfig["qtextbox-pl-tablet"] &&
                    props.themeConfig["qtextbox-pt-desktop"] === props.themeConfig["qtextbox-pt-mobile"] &&
                    props.themeConfig["qtextbox-pt-desktop"] === props.themeConfig["qtextbox-pt-tablet"] &&
                    props.themeConfig["qtextbox-pr-desktop"] === props.themeConfig["qtextbox-pr-mobile"] &&
                    props.themeConfig["qtextbox-pr-desktop"] === props.themeConfig["qtextbox-pr-tablet"] &&
                    props.themeConfig["qtextbox-pb-desktop"] === props.themeConfig["qtextbox-pb-mobile"] &&
                    props.themeConfig["qtextbox-pb-desktop"] === props.themeConfig["qtextbox-pb-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Border Radius */}
            <SingleInputElement
                selectorName="qtextbox-border-radius"
                propertyName="Border Radius"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-border-radius-desktop"] ===
                        props.themeConfig["qtextbox-border-radius-mobile"] &&
                    props.themeConfig["qtextbox-border-radius-desktop"] ===
                        props.themeConfig["qtextbox-border-radius-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Border Width */}
            <SingleInputElement
                selectorName="qtextbox-border-width"
                propertyName="Border Width"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-border-width-desktop"] ===
                        props.themeConfig["qtextbox-border-width-mobile"] &&
                    props.themeConfig["qtextbox-border-width-desktop"] ===
                        props.themeConfig["qtextbox-border-width-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Border Color */}
            <ColorSelection
                selectorName="qtextbox-border-color"
                propertyName="Border Color"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-border-color-desktop"] ===
                        props.themeConfig["qtextbox-border-color-mobile"] &&
                    props.themeConfig["qtextbox-border-color-desktop"] ===
                        props.themeConfig["qtextbox-border-color-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Border Color when Focused */}
            <ColorSelection
                selectorName="qtextbox-border-focus-color"
                propertyName="Border Color when Focused"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-border-focus-color-desktop"] ===
                        props.themeConfig["qtextbox-border-focus-color-mobile"] &&
                    props.themeConfig["qtextbox-border-focus-color-desktop"] ===
                        props.themeConfig["qtextbox-border-focus-color-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            <LayoutElementsActionBtns
                themeConfig={props.themeConfig}
                updateThemeConfig={props.updateThemeConfig}
                resetThemeConfig={props.resetThemeConfig}
            />
        </>
    );
}

export default Qtextbox;
