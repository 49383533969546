import React from "react";
import axios from "axios";
import qs from "qs";
import { getAPIBaseUrl, getApiVersion, getItemFromLocalStorage } from "../utils/utils";
import QuestionsPageView from "./questions-page-view";
import { getPages, getQuestions } from "../utils/apis";

class QuestionsPageContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            selectedPageLink: "all",
            selectedPageTitle: "All Pages",
            pageLinks: [],
            questions: [],
            totalQuestions: "",
            pageSize: 10,
            currentPage: 1,
            questionsAreLoading: true,
            answerIsSubmitting: false,
            answerSubmittingForQId: "",
            statusFilter: "CREATED",
            answerSubmitted: false,
            pageLinksAreLoading: true,
            totalUnansweredQuestions: null,
            totalQuestionsAcrossAllPages: null
        };
        this.onPageLinkChange = this.onPageLinkChange.bind(this);
        this.getPageLinks = this.getPageLinks.bind(this);
        this.changePageNumber = this.changePageNumber.bind(this);
        this.getUserQuestions = this.getUserQuestions.bind(this);
        this.answerQuestion = this.answerQuestion.bind(this);
        this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
        this.updateQuestion = this.updateQuestion.bind(this);
        this.updateAnswer = this.updateAnswer.bind(this);
        this.updateAnswerSubmittedState = this.updateAnswerSubmittedState.bind(this);
    }

    onPageLinkChange = async (url, title) => {
        await this.setState(
            {
                selectedPageLink: url,
                selectedPageTitle: title,
                currentPage: 1
            },
            () => {
                this.getUserQuestions(
                    this.state.selectedPageLink,
                    this.state.currentPage,
                    this.state.pageSize,
                    this.state.statusFilter,
                    "widget_user"
                );
            }
        );
    };

    onStatusFilterChange = async (event) => {
        await this.setState(
            {
                statusFilter: event.target.value,
                currentPage: 1
            },
            () => {
                this.getUserQuestions(
                    this.state.selectedPageLink,
                    this.state.currentPage,
                    this.state.pageSize,
                    this.state.statusFilter,
                    "widget_user"
                );
            }
        );
    };

    getPageLinks = async (searchTerm) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        this.setState({
            pageLinksAreLoading: true
        });

        const resp = await getPages("widget_user", searchTerm);
        if (resp.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }
        this.setState({
            pageLinks: resp.data.pages,
            totalUnansweredQuestions: resp.data.post_count,
            totalQuestionsAcrossAllPages: resp.data.total_count,
            pageLinksAreLoading: false
        });
    };

    getUserQuestions = async (pageUrl, currentPage, pageSize, statusFilter, createdBy) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        this.setState({
            questionsAreLoading: true
        });

        const resp = await getQuestions(pageUrl, currentPage, pageSize, statusFilter, createdBy);
        if (resp.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }
        this.setState({
            questions: resp.data.posts,
            totalQuestions: resp.data.total_results,
            questionsAreLoading: false
        });
    };

    answerQuestion = async (questionId, answer, questionStatus) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        let apiBaseUrl = getAPIBaseUrl();
        let apiVersion = getApiVersion();

        let payload = {
            blocks: answer,
            post_status: questionStatus
        };

        // console.log(payload);

        this.setState({
            answerIsSubmitting: true,
            answerSubmittingForQId: questionId,
            answerSubmitted: false
        });

        await axios
            .post(`${apiBaseUrl}/posts/${questionId}/comments/create/`, payload, {
                headers: { accessToken: accessToken, "X-Api-Version": apiVersion }
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Answer posted successfully");
                    // console.log(response);
                    this.setState({
                        answerIsSubmitting: false,
                        answerSubmittingForQId: "",
                        answerSubmitted: true
                    });
                    this.getUserQuestions(
                        this.state.selectedPageLink,
                        this.state.currentPage,
                        this.state.pageSize,
                        this.state.statusFilter,
                        "widget_user"
                    );
                }
            })
            .catch((error) => {
                console.log("Error in answering question");
                console.log(error);
                if (error.response.data.detail === "Signature has expired.") {
                    // remove access token from local storage
                    localStorage.removeItem("access-token");
                    this.props.history.push("/login");
                }
            });
    };

    updateQuestion = async (questionId, questionStatus) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        let apiBaseUrl = getAPIBaseUrl();

        let payload = {
            status: questionStatus
        };

        await axios
            .put(`${apiBaseUrl}/posts/${questionId}/`, qs.stringify(payload), { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Question updated successfully");
                    this.getUserQuestions(
                        this.state.selectedPageLink,
                        this.state.currentPage,
                        this.state.pageSize,
                        this.state.statusFilter,
                        "widget_user"
                    );
                }
            })
            .catch((error) => {
                console.log("Error in updating question");
                console.log(error);
                if (error.response.data.detail === "Signature has expired.") {
                    // remove access token from local storage
                    localStorage.removeItem("access-token");
                    this.props.history.push("/login");
                }
            });
    };

    updateAnswer = async (questionId, answerId, answerStatus, answerText, questionStatus) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        let apiBaseUrl = getAPIBaseUrl();
        let apiVersion = getApiVersion();

        let payload = {
            blocks: answerText,
            status: answerStatus,
            post_status: questionStatus
        };

        // console.log(payload);

        this.setState({
            answerIsSubmitting: true,
            answerSubmittingForQId: questionId,
            answerSubmitted: false
        });

        await axios
            .put(`${apiBaseUrl}/posts/${questionId}/comments/${answerId}/`, payload, {
                headers: { accessToken: accessToken, "X-Api-Version": apiVersion }
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Answer updated successfully");
                    // console.log(response);
                    this.setState({
                        answerIsSubmitting: false,
                        answerSubmittingForQId: "",
                        answerSubmitted: true
                    });
                    // this.getPageLinks();
                    this.getUserQuestions(
                        this.state.selectedPageLink,
                        this.state.currentPage,
                        this.state.pageSize,
                        this.state.statusFilter,
                        "widget_user"
                    );
                }
            })
            .catch((error) => {
                console.log("Error in updating answer");
                console.log(error);
                if (error.response.data.detail === "Signature has expired.") {
                    // remove access token from local storage
                    localStorage.removeItem("access-token");
                    this.props.history.push("/login");
                }
            });
    };

    updateAnswerSubmittedState = () => {
        this.setState({
            answerSubmitted: false
        });
    };

    changePageNumber = async (pageNumber) => {
        await this.setState(
            {
                currentPage: pageNumber
            },
            () => {
                this.getUserQuestions(
                    this.state.selectedPageLink,
                    this.state.currentPage,
                    this.state.pageSize,
                    this.state.statusFilter,
                    "widget_user"
                );
            }
        );
    };

    componentDidMount() {
        document.title = "Questions - hagrid";
        this.getPageLinks();
        this.getUserQuestions(
            this.state.selectedPageLink,
            this.state.currentPage,
            this.state.pageSize,
            this.state.statusFilter,
            "widget_user"
        );
    }

    render() {
        // console.log(this.state);
        return (
            <>
                <QuestionsPageView
                    onPageLinkChange={this.onPageLinkChange}
                    pageLinks={this.state.pageLinks}
                    selectedPageLink={this.state.selectedPageLink}
                    selectedPageTitle={this.state.selectedPageTitle}
                    questions={this.state.questions}
                    currentPage={this.state.currentPage}
                    pageSize={this.state.pageSize}
                    totalQuestions={this.state.totalQuestions}
                    changePageNumber={this.changePageNumber}
                    answerQuestion={this.answerQuestion}
                    answerIsSubmitting={this.state.answerIsSubmitting}
                    answerSubmittingForQId={this.state.answerSubmittingForQId}
                    statusFilter={this.state.statusFilter}
                    onStatusFilterChange={this.onStatusFilterChange}
                    updateQuestion={this.updateQuestion}
                    updateAnswer={this.updateAnswer}
                    answerSubmitted={this.state.answerSubmitted}
                    updateAnswerSubmittedState={this.updateAnswerSubmittedState}
                    questionsAreLoading={this.state.questionsAreLoading}
                    pageLinksAreLoading={this.state.pageLinksAreLoading}
                    totalUnansweredQuestions={this.state.totalUnansweredQuestions}
                    totalQuestionsAcrossAllPages={this.state.totalQuestionsAcrossAllPages}
                />
            </>
        );
    }
}
export default QuestionsPageContainer;
