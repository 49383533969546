import React from "react";
import { BeatLoader } from "react-spinners";
import Editor from "../editor";

function AnswerTextbox(props) {
    return (
        <div className="answer-textbox border border-slate-200 rounded-lg pt-2 pb-4 px-4">
            <Editor
                editorInstanceId={`answer-textbox-${props.questionId}`}
                editorInitialData={props.initialAnswerText}
                readOnly={false}
                handleTextChange={props.handleAnswerTextChange}
                // isQuestionPosted={ props.isQuestionPosted }
            />

            <div className="flex items-center justify-end mt-3">
                <label className="inline-flex items-center mr-3">
                    <input
                        type="checkbox"
                        checked={props.isQuestionPrivate}
                        onChange={props.onQuestionPublishStateChange}
                        className="h-6 w-6 text-mine-shaft border-mine-shaft rounded-sm"
                    />
                    <span className="ml-1 text-mine-shaft text-sm">Post as private</span>
                </label>

                {props.answerText === "" ? (
                    <button className="px-8 py-3 bg-mine-shaft text-sm text-white font-semibold rounded-lg bg-opacity-50 cursor-not-allowed">
                        Post
                    </button>
                ) : props.answerIsSubmitting && props.answerSubmittingForQId === props.questionId ? (
                    <button className="px-10 py-3 bg-mine-shaft hover:bg-black text-sm text-white font-semibold rounded-lg cursor-not-allowed">
                        <BeatLoader color={"#ffffff"} size={"8px"} />
                    </button>
                ) : props.isNewAnswer ? (
                    <button
                        className="px-8 py-3 bg-mine-shaft hover:bg-black text-sm text-white font-semibold rounded-lg"
                        onClick={() =>
                            props.answerQuestion(
                                props.questionId,
                                props.answerText,
                                props.isQuestionPrivate ? "PRIVATE" : "PUBLISHED"
                            )
                        }
                    >
                        Post
                    </button>
                ) : (
                    <button
                        className="px-8 py-3 bg-mine-shaft hover:bg-black text-sm text-white font-semibold rounded-lg"
                        onClick={() =>
                            props.updateAnswer(
                                props.questionId,
                                props.answerToEdit,
                                "",
                                props.answerText,
                                props.isQuestionPrivate ? "PRIVATE" : "PUBLISHED"
                            )
                        }
                    >
                        Update
                    </button>
                )}

                <button
                    className="ml-3 px-8 py-3 bg-slate-50 hover:bg-slate-200 text-sm text-slate-800 font-semibold rounded-lg"
                    onClick={() => {
                        props.handleAnswerTextboxState(false);
                        props.clearAnswerText();
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default AnswerTextbox;
