import React, { useEffect, useRef, useState } from "react";
import { getThemeConfigValue } from "../../../utils/utils";

function SelectDropdown(props) {
    const [isChecked, setChecked] = useState(props.isSameForAllDevices);

    let elementName, elementKeyForValue;
    if (props.deviceLevelConfig) {
        elementKeyForValue = props.selectorName + "-" + props.device;

        if (isChecked) {
            elementName = props.selectorName;
        } else {
            elementName = props.selectorName + "-" + props.device;
        }
    } else {
        elementName = props.selectorName;
        elementKeyForValue = props.selectorName;
    }

    return (
        <div className="my-8">
            <div className="flex flex-row justify-between">
                <p className="text-mine-shaft text-base">{props.propertyName}</p>
                <label
                    className={`${
                        props.deviceLevelConfig ? "block" : "hidden"
                    } flex flex-row items-center cursor-pointer`}
                >
                    <input
                        type="checkbox"
                        className="h-4 w-4 cursor-pointer text-mine-shaft rounded-sm border-mine-shaft"
                        defaultChecked={isChecked}
                        onChange={(e) => {
                            setChecked(e.target.checked);
                        }}
                    />
                    <span className="pl-2 text-mine-shaft text-xs font-normal">Same for all devices</span>
                </label>
            </div>
            <div className="flex items-center mt-4">
                <select
                    name={elementName}
                    className="w-40 outline-none text-center focus:outline-none text-base text-mine-shaft border border-slate-400 focus:ring-0 focus:border-purple-300 rounded-md"
                    value={getThemeConfigValue(props.themeConfig, elementKeyForValue)}
                    onChange={
                        isChecked && props.deviceLevelConfig
                            ? props.changeSelectInputOnAllDevices
                            : props.changeSelectInput
                    }
                >
                    {props.options &&
                        props.options.map((obj, index) => {
                            return (
                                <option key={index} value={obj.value}>
                                    {obj.display}
                                </option>
                            );
                        })}
                </select>
            </div>
        </div>
    );
}

export default SelectDropdown;
