import React from "react";
import ColorSelection from "../element-types/color-selection";
import SelectDropdown from "../element-types/select-dropdown";
import SingleInputElement from "../element-types/single-input-element";
import LayoutElementsActionBtns from "./utils/layout-elements-actions-btns";

function QtextboxText(props) {
    return (
        <>
            {/* Font Size */}
            <SingleInputElement
                selectorName="qtextbox-text-font-size"
                propertyName="Font Size"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-text-font-size-desktop"] ===
                        props.themeConfig["qtextbox-text-font-size-mobile"] &&
                    props.themeConfig["qtextbox-text-font-size-desktop"] ===
                        props.themeConfig["qtextbox-text-font-size-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Font Weight */}
            <SelectDropdown
                selectorName="qtextbox-text-font-weight"
                propertyName="Font Weight"
                themeConfig={props.themeConfig}
                changeSelectInput={props.changeSelectInput}
                changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-text-font-weight-desktop"] ===
                        props.themeConfig["qtextbox-text-font-weight-mobile"] &&
                    props.themeConfig["qtextbox-text-font-weight-desktop"] ===
                        props.themeConfig["qtextbox-text-font-weight-tablet"]
                        ? true
                        : false
                }
                device={props.device}
                options={props.fonts.find((obj) => obj.value === props.themeConfig["widget-font-family"]).variants}
            />

            {/* Font Color */}
            <ColorSelection
                selectorName="qtextbox-text-font-color"
                propertyName="Font Color"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-text-font-color-desktop"] ===
                        props.themeConfig["qtextbox-text-font-color-mobile"] &&
                    props.themeConfig["qtextbox-text-font-color-desktop"] ===
                        props.themeConfig["qtextbox-text-font-color-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Line Height */}
            <SingleInputElement
                selectorName="qtextbox-text-line-height"
                propertyName="Line Height"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-text-line-height-desktop"] ===
                        props.themeConfig["qtextbox-text-line-height-mobile"] &&
                    props.themeConfig["qtextbox-text-line-height-desktop"] ===
                        props.themeConfig["qtextbox-text-line-height-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Letter Spacing */}
            <SingleInputElement
                selectorName="qtextbox-text-letter-spacing"
                propertyName="Letter Spacing"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["qtextbox-text-letter-spacing-desktop"] ===
                        props.themeConfig["qtextbox-text-letter-spacing-mobile"] &&
                    props.themeConfig["qtextbox-text-letter-spacing-desktop"] ===
                        props.themeConfig["qtextbox-text-letter-spacing-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            <LayoutElementsActionBtns
                themeConfig={props.themeConfig}
                updateThemeConfig={props.updateThemeConfig}
                resetThemeConfig={props.resetThemeConfig}
            />
        </>
    );
}

export default QtextboxText;
