import React, { useRef, useEffect, useState } from "react";
import LeftNavbar from "../../utils/left-navbar";
import TopNavbar from "../../utils/top-navbar";
import { PageHeading, SectionHeading } from "../../utils/typography";
import { NavLink } from "react-router-dom";
import { VscNotebook, VscTerminalTmux, VscExtensions } from "react-icons/vsc";
import { RiUser4Line, RiPencilRuler2Line } from "react-icons/ri";
import ThemeEditor from "./theme-editor";
import ThemeSelector from "./theme-selector";
import { BsLaptop, BsPhone, BsTablet } from "react-icons/bs";
import PreviewFrame from "./preview/preview-frame";
import { PrimaryButton } from "../../utils/buttons";
import PreviewModal from "./preview/preview-modal";
import { IoLanguageOutline } from "react-icons/io5";
import { AiOutlineFormatPainter } from "react-icons/ai";
import SettingsNav from "../settings-nav";

function DesignWidgetView(props) {
    const [selectedElementClass, setElementClass] = useState(null);
    const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);

    return (
        <div className="relative">
            {/* <TopNavbar /> */}
            <div className="flex">
                <LeftNavbar />

                <div className="pr-12 pl-28 lg:pl-[17rem] w-full pb-20">
                    <div className="pt-12 pb-8 flex flex-row justify-between items-center">
                        <PageHeading text="Design" />
                        <SettingsNav />
                    </div>
                    <div
                        className="bg-white rounded-2xl px-12 py-10 overflow-hidden shadow-container antialiased"
                        style={{ height: "calc(100vh - 128px)" }}
                    >
                        <div className="flex flex-row h-full overflow-hidden">
                            <div className="w-1/3 border-r border-zinc-100 pr-2 h-full overflow-hidden">
                                <div className="flex flex-row justify-around border rounded-lg border-zinc-200">
                                    <button
                                        className={`${
                                            props.designStep === "select-layout"
                                                ? "bg-fuchsia-50 rounded-lg border border-purple-200 font-semibold"
                                                : " hover:bg-zinc-50"
                                        } text-sm w-1/2 py-2 font-normal text-zinc-800`}
                                        onClick={() => props.changeDesignStep("select-layout")}
                                    >
                                        Layout
                                    </button>
                                    <button
                                        className={`${
                                            props.designStep === "edit-layout"
                                                ? "bg-fuchsia-50 rounded-lg border border-purple-200 font-semibold"
                                                : " hover:bg-zinc-50"
                                        } text-sm w-1/2 py-2 font-normal text-zinc-800`}
                                        onClick={() => props.changeDesignStep("edit-layout")}
                                    >
                                        Appearance
                                    </button>
                                </div>
                                {props.designStep === "edit-layout" ? (
                                    <ThemeEditor
                                        changeColor={props.changeColor}
                                        changeColorOnAllDevices={props.changeColorOnAllDevices}
                                        changeDimensionInput={props.changeDimensionInput}
                                        changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                                        changeSelectInput={props.changeSelectInput}
                                        changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                                        themeConfig={props.themeConfig}
                                        device={props.device}
                                        updateThemeConfig={props.updateThemeConfig}
                                        resetThemeConfig={props.resetThemeConfig}
                                        fonts={props.fonts}
                                        getWebsiteConfig={props.getWebsiteConfig}
                                        setElementClass={setElementClass}
                                        selectedTheme={props.selectedTheme}
                                    />
                                ) : (
                                    <ThemeSelector
                                        selectedTheme={props.selectedTheme}
                                        changeSelectedTheme={props.changeSelectedTheme}
                                        updateThemeLayout={props.updateThemeLayout}
                                        getWebsiteConfig={props.getWebsiteConfig}
                                    />
                                )}
                            </div>
                            <div className="w-2/3 overflow-scroll">
                                <div className="flex flex-row justify-center">
                                    <div className="flex flex-row justify-center rounded-lg border border-zinc-200">
                                        <button
                                            className={`${
                                                props.device === "desktop"
                                                    ? "bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                                                    : "hover:bg-zinc-50 rounded-lg"
                                            } py-2 px-4 text-sm font-medium flex flex-row items-center text-zinc-800`}
                                            onClick={() => props.changeDevice("desktop")}
                                        >
                                            <BsLaptop className="text-xl" />
                                        </button>
                                        <button
                                            className={`${
                                                props.device === "mobile"
                                                    ? "bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                                                    : "hover:bg-zinc-50 rounded-lg"
                                            } py-2 px-4 text-sm font-medium flex flex-row items-center text-zinc-800`}
                                            onClick={() => props.changeDevice("mobile")}
                                        >
                                            <BsPhone className="text-xl" />
                                        </button>
                                        <button
                                            className={`${
                                                props.device === "tablet"
                                                    ? "bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                                                    : "hover:bg-zinc-50 rounded-lg"
                                            } py-2 px-4 text-sm font-medium flex flex-row items-center text-zinc-800`}
                                            onClick={() => props.changeDevice("tablet")}
                                        >
                                            <BsTablet className="text-xl" />
                                        </button>
                                    </div>
                                </div>
                                <PreviewFrame
                                    themeConfig={props.themeConfig}
                                    device={props.device}
                                    selectedTheme={props.selectedTheme}
                                    selectedElementClass={selectedElementClass}
                                    scale={0.6}
                                    openInModal={false}
                                />

                                <div className="mt-8 px-6 py-5 flex flex-col items-center">
                                    <p className="text-sm text-mine-shaft font-normal pb-4">
                                        This is a scaled down version of the widget. Just click below to see the
                                        original version.
                                    </p>
                                    <PrimaryButton
                                        buttonText={"Open Full Scale Preview"}
                                        onClick={() => setPreviewModalOpen(true)}
                                    />
                                </div>

                                {isPreviewModalOpen ? (
                                    <PreviewModal
                                        themeConfig={props.themeConfig}
                                        device={props.device}
                                        selectedTheme={props.selectedTheme}
                                        selectedElementClass={selectedElementClass}
                                        scale={1}
                                        changeDevice={props.changeDevice}
                                        setPreviewModalOpen={setPreviewModalOpen}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesignWidgetView;
