import React from "react";
import { BeatLoader } from "react-spinners";

function PrimaryButton(props) {
    if (props.isLoading) {
        return (
            <button className="px-10 py-3 bg-zinc-800 hover:bg-zinc-900 text-sm text-white font-semibold rounded-lg cursor-not-allowed">
                <BeatLoader color={"#ffffff"} size={"8px"} />
            </button>
        );
    } else {
        if (props.disabled) {
            return (
                <button className="cursor-not-allowed bg-opacity-50 px-5 py-2.5 bg-zinc-800 text-sm text-white font-semibold rounded-lg antialiased">
                    {props.buttonText}
                </button>
            );
        } else {
            return (
                <button
                    className="px-5 py-2.5 bg-zinc-800 hover:bg-zinc-900 text-sm text-white font-semibold rounded-lg antialiased"
                    onClick={props.onClick}
                >
                    {props.buttonText}
                </button>
            );
        }
    }
}

export { PrimaryButton };

function SecondaryButton(props) {
    return (
        <button
            className="ml-3 px-5 py-2.5 bg-slate-50 hover:bg-slate-200 text-sm text-slate-800 font-semibold rounded-lg antialiased"
            onClick={props.onClick}
        >
            {props.buttonText}
        </button>
    );
}

export { SecondaryButton };

function DropdownOptions(props) {
    return (
        <button
            className="bg-white w-full hover:bg-fuchsia-50 text-slate-700 py-2.5 px-6 flex items-center justify-start"
            onClick={props.onClick}
        >
            {props.icon}
            <p className="text-sm ml-2 font-medium">{props.buttonText}</p>
        </button>
    );
}
export { DropdownOptions };
