import { default as React, useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Image from "@editorjs/image";
import ImageTool from '@editorjs/image';
import Embed from "@editorjs/embed";
import CodeTool from "@editorjs/code";
import InlineCode from "@editorjs/inline-code";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import { getAPIBaseUrl } from "./utils";

function Editor(props) {
    const { editorInstanceId, editorInitialData, readOnly, isQuestionPosted } = props;
    const ejInstance = useRef();
    const [editorData, setEditorData] = React.useState(editorInitialData);

    // editorData -> this holds the latest data of the editor at all times (JSON)
    // console.log(editorData)

    // only runs once (to initialise the editor)
    useEffect(() => {
        if (!ejInstance.current) {
            initEditor();
        }
        return () => {
            if (ejInstance && ejInstance.current) {
                ejInstance.current.destroy();
                ejInstance.current = null;
            } else {
                console.log("EditorJS instance not found");
            }
        };
    }, []);

    // Set the editor data to empty object if the question is posted
    useEffect(() => {
        if (isQuestionPosted) {
            ejInstance.current.clear();
        }
    }, [isQuestionPosted]);

    const initEditor = () => {
        const editor = new EditorJS({
            holder: editorInstanceId,
            logLevel: "ERROR",
            data: editorData,
            readOnly: readOnly,
            minHeight: 0,
            // placeholder: "Type your answer here...",
            onReady: () => {
                ejInstance.current = editor;
            },
            onChange: async (editor) => {
                setTimeout(async () => {
                    let content = await editor.saver.save();
                    // Put your logic here to save this data to your DB
                    setEditorData(content);
                    props.handleTextChange(content.blocks);
                }, 200);
            },
            autofocus: false,
            tools: {
                embed: {
                    class: Embed,
                    inlineToolbar: true,
                    config: {
                        services: {
                            youtube: true,
                            facebook: true,
                            instagram: true,
                            twitter: true,
                            imgur: true,
                            codepen: true,
                            pinterest: true,
                            gfycat: true,
                            vimeo: true
                        }
                    }
                },
                // image: SimpleImage,
                image: {
                    class: ImageTool,
                    config: {
                      endpoints: {
                        byFile: `${getAPIBaseUrl()}/oauth/upload-file/`
                      }
                    }
                },
                code: CodeTool,
                inlineCode: {
                    class: InlineCode
                },
                list: {
                    class: List,
                    inlineToolbar: true
                },
                marker: {
                    class: Marker
                }
            }
        });
    };

    return <div id={editorInstanceId}> </div>;
}

export default Editor;
