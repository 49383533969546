import React from "react";
import { SectionHeading } from "../utils/typography";

function CollectInfoView(props){
    return(
        <div className='flex justify-center pt-24 px-8'>
            <div className=' max-w-md flex flex-col items-center bg-white rounded-2xl py-12 px-12 drop-shadow-[0_0_21px_rgba(0,0,0,0.08)]'>
                <div className='flex items-center mb-10'>
                    {/* <img src='assets/images/hagrid-logo.svg' alt='logo' width='38px' className='mr-2' />
                    <p className='font-jtmw text-2xl text-mine-shaft pt-1'>hagrid</p> */}
                    <img src='assets/images/hagrid-logo-small.svg' alt='logo' width='60px' className='mr-2' />
                </div>
                <SectionHeading text="Let's get started!" />
                {/* <p className='text-slate-700 font-normal mt-3 px-4 text-sm antialiased'>We need your website details to generate widget code for your website</p> */}
                <div className='bg-zinc-50 px-8 pb-12 pt-8 rounded-lg mt-5 flex flex-col items-center'>
                    <div>
                        <label className='text-masala text-sm font-medium antialiased'>
                            Give your website a nickname
                            <input 
                                type='text' 
                                placeholder='eg. hagrid' 
                                name='websiteName' 
                                className=' text-masala w-full mt-2 bg-white rounded-lg px-4 py-2.5 border outline-none focus:ring focus:ring-fuchsia-200' 
                                onChange={ props.handleInputChange }
                            />
                        </label>
                    </div>

                    <div className='mt-5'>
                        <label className='text-masala text-sm font-medium antialiased'>
                            Your website URL is
                            <input 
                                placeholder='eg. www.hagrid.io' 
                                type='url' 
                                name='websiteUrl' 
                                className=' text-masala w-full mt-2 bg-white rounded-lg px-4 py-2.5 border outline-none focus:ring focus:ring-fuchsia-200' 
                                onChange={ props.handleInputChange }
                            />
                        </label>
                    </div>
                    {/* <button 
                        className='w-full mt-8 py-3 bg-mine-shaft hover:bg-black text-sm text-white font-semibold rounded-lg'
                    >
                        Save Profile
                    </button> */}
                    {props.websiteName && props.websiteUrl
                    ?   <button 
                            className='w-full mt-8 py-3 bg-violet-600 hover:bg-violet-800 text-sm text-white font-semibold rounded-lg'
                            onClick={ props.handleSubmit }
                        >
                            Save Profile
                        </button>
                    :   <button 
                            className='w-full mt-8 py-3 bg-opacity-50 bg-violet-600 text-sm text-white font-semibold rounded-lg cursor-not-allowed'
                        >
                            Save Website
                        </button>

                    }
                    
                </div>
            </div>
        </div>
    )
}

export default CollectInfoView;