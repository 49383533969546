import React from "react";
import ColorSelection from "../element-types/color-selection";
import MultiInputElement from "../element-types/multi-input-element";
import SelectDropdown from "../element-types/select-dropdown";
import SingleInputElement from "../element-types/single-input-element";
import LayoutElementsActionBtns from "./utils/layout-elements-actions-btns";

function WidgetContainer(props) {
    return (
        <>
            {/* Background color */}
            <ColorSelection
                selectorName="widget-bg-color"
                propertyName="Background Color"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={false}
                isSameForAllDevices={
                    props.themeConfig["widget-bg-color-desktop"] === props.themeConfig["widget-bg-color-mobile"] &&
                    props.themeConfig["widget-bg-color-desktop"] === props.themeConfig["widget-bg-color-tablet"]
                        ? true
                        : false
                }
            />

            {/* Max width */}
            <SingleInputElement
                selectorName="widget-max-width"
                propertyName="Maximum Width"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={false}
            />

            {/* Font family */}
            <SelectDropdown
                selectorName="widget-font-family"
                propertyName="Font Family"
                themeConfig={props.themeConfig}
                changeSelectInput={props.changeSelectInput}
                changeSelectInputOnAllDevices={props.changeSelectInputOnAllDevices}
                deviceLevelConfig={false}
                options={props.fonts}
            />

            {/* Padding */}
            <MultiInputElement
                selectorName="widget-p"
                propertyName="Padding"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["widget-pl-desktop"] === props.themeConfig["widget-pl-mobile"] &&
                    props.themeConfig["widget-pl-desktop"] === props.themeConfig["widget-pl-tablet"] &&
                    props.themeConfig["widget-pt-desktop"] === props.themeConfig["widget-pt-mobile"] &&
                    props.themeConfig["widget-pt-desktop"] === props.themeConfig["widget-pt-tablet"] &&
                    props.themeConfig["widget-pr-desktop"] === props.themeConfig["widget-pr-mobile"] &&
                    props.themeConfig["widget-pr-desktop"] === props.themeConfig["widget-pr-tablet"] &&
                    props.themeConfig["widget-pb-desktop"] === props.themeConfig["widget-pb-mobile"] &&
                    props.themeConfig["widget-pb-desktop"] === props.themeConfig["widget-pb-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Margin */}
            <MultiInputElement
                selectorName="widget-m"
                propertyName="Margin"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                deviceLevelConfig={true}
                isSameForAllDevices={
                    props.themeConfig["widget-ml-desktop"] === props.themeConfig["widget-ml-mobile"] &&
                    props.themeConfig["widget-ml-desktop"] === props.themeConfig["widget-ml-tablet"] &&
                    props.themeConfig["widget-mt-desktop"] === props.themeConfig["widget-mt-mobile"] &&
                    props.themeConfig["widget-mt-desktop"] === props.themeConfig["widget-mt-tablet"] &&
                    props.themeConfig["widget-mb-desktop"] === props.themeConfig["widget-mb-mobile"] &&
                    props.themeConfig["widget-mb-desktop"] === props.themeConfig["widget-mb-tablet"] &&
                    props.themeConfig["widget-mb-desktop"] === props.themeConfig["widget-mb-mobile"] &&
                    props.themeConfig["widget-mb-desktop"] === props.themeConfig["widget-mb-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            <LayoutElementsActionBtns
                themeConfig={props.themeConfig}
                updateThemeConfig={props.updateThemeConfig}
                resetThemeConfig={props.resetThemeConfig}
            />
        </>
    );
}

export default WidgetContainer;
