import React, { useEffect } from "react";
import { FiCheck } from "react-icons/fi";
import { PrimaryButton } from "../../utils/buttons";

function ThemeSelector(props) {
    useEffect(() => {
        props.getWebsiteConfig();
    }, []);
    return (
        <div className="h-full pb-10 overflow-scroll">
            <div className="text-mine-shaft text-lg font-medium pt-8">
                <p>Presets</p>
                <p className="text-gray-500 text-xs font-normal pt-1.5">Select the preset for your widget</p>
            </div>
            <div>
                <button
                    className={`${
                        props.selectedTheme === "simple"
                            ? "bg-fuchsia-50 border border-purple-200"
                            : "bg-white hover:bg-zinc-50 border border-zinc-200"
                    } text-lg font-medium w-full max-w-xs rounded-lg flex items-center justify-between px-10 py-6 mt-8 mb-4 text-zinc-800 relative`}
                    onClick={() => props.changeSelectedTheme("simple")}
                >
                    Simple
                    <img src="/assets/images/simple-layout-gray.svg" alt="simple-layout" />
                    <div
                        className={`${
                            props.selectedTheme === "simple"
                                ? "border-purple-500 bg-purple-500"
                                : "border-zinc-200 bg-white hidden"
                        } flex items-center justify-center rounded-full border absolute w-7 h-7 -right-2 -top-2`}
                    >
                        <FiCheck
                            className={`${props.selectedTheme === "simple" ? "block" : "hidden"} text-lg text-white`}
                        />
                    </div>
                </button>
                <button
                    className={`${
                        props.selectedTheme === "accordion"
                            ? "bg-fuchsia-50 border border-purple-200"
                            : "bg-white hover:bg-zinc-50 border border-zinc-200"
                    } text-lg font-medium w-full max-w-xs rounded-lg flex items-center justify-between px-10 py-6 mt-4 mb-8 text-zinc-800 relative`}
                    onClick={() => props.changeSelectedTheme("accordion")}
                >
                    Accordion
                    <img src="/assets/images/accordion-layout-gray.svg" alt="accordion-layout" />
                    <div
                        className={`${
                            props.selectedTheme === "accordion"
                                ? "border-purple-500 bg-purple-500"
                                : "border-zinc-200 bg-white hidden"
                        } flex items-center justify-center rounded-full border absolute w-7 h-7 -right-2 -top-2`}
                    >
                        <FiCheck
                            className={`${props.selectedTheme === "accordion" ? "block" : "hidden"} text-lg text-white`}
                        />
                    </div>
                </button>
            </div>
            <div className="flex flex-row justify-start mb-5">
                <PrimaryButton
                    buttonText={"Save Changes"}
                    onClick={() => props.updateThemeLayout(props.selectedTheme)}
                />
            </div>
        </div>
    );
}

export default ThemeSelector;
