import React from 'react';

function PageHeading(props){
    return(
        <h1 className='text-3xl font-bold text-mine-shaft antialiased'>
            {props.text}
        </h1>
    )
}

export { PageHeading };

function SectionHeading(props){
    return(
        <h1 className='text-2xl font-semibold text-mine-shaft antialiased'>
            {props.text}
        </h1>
    )
}

export { SectionHeading };