import React from "react";
import { FaUser, FaChevronRight, FaPaintRoller, FaCheck, FaCommentAlt, FaPencilRuler } from "react-icons/fa";
import { IoChevronForwardSharp, IoExtensionPuzzleSharp } from "react-icons/io5";
import { FiUser, FiArrowRightCircle } from "react-icons/fi";
import { RiUserLine, RiPaintFill } from "react-icons/ri";
import { GoCheck, GoCommentDiscussion } from "react-icons/go";
import { AiFillDatabase } from "react-icons/ai";
import { CgExtension } from "react-icons/cg";
import { BsArrowRight } from "react-icons/bs";
import { withRouter } from "react-router-dom";

function OnboardingSteps(props) {
    const status = (id) => {
        const filterObj = props.steps.find((obj) => {
            return obj.step_id === id;
        });
        if (filterObj) {
            return filterObj.action;
        } else {
            return "not_complete";
        }
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
                <div
                    className="flex items-center cursor-pointer hover:opacity-80"
                    onClick={() => {
                        props.history.push("/settings/profile");
                    }}
                >
                    <div className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 border border-slate-200">
                        <FaUser className="text-slate-600 text-sm" />
                    </div>
                    <div className="ml-4 antialiased">
                        <p
                            className={`${
                                status(1) === "completed" ? "text-teal-600 line-through" : "text-slate-800"
                            } text-base font-medium`}
                        >
                            Add an Avatar
                        </p>
                        <p className="text-slate-600 text-sm mt-0.5">
                            So visitors can put a face to the name. 1 minute.
                        </p>
                    </div>
                </div>
                {status(1) === "completed" ? (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer bg-teal-500"
                        onClick={() => {
                            props.updateOnboardingStep(1, "not_complete");
                        }}
                    >
                        <GoCheck className="text-lg text-white" />
                    </button>
                ) : (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer border border-slate-200"
                        onClick={() => {
                            props.updateOnboardingStep(1, "completed");
                        }}
                    >
                        <GoCheck className="text-lg text-slate-600" />
                    </button>
                )}
            </div>
            <div className="my-6 border-t border-slate-200"></div>
            <div className="flex flex-row items-center justify-between">
                <div
                    className="flex items-center cursor-pointer hover:opacity-80"
                    onClick={() => {
                        props.history.push("/settings/design");
                    }}
                >
                    <div className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 border border-slate-200">
                        <FaPencilRuler className="text-slate-600 text-sm" />
                    </div>
                    <div className="ml-4 antialiased">
                        <p
                            className={`${
                                status(2) === "completed" ? "text-teal-600 line-through" : "text-slate-800"
                            } text-base font-medium`}
                        >
                            Customise the hagrid widget
                        </p>
                        <p className="text-slate-600 text-sm mt-0.5">
                            Add your own colours, text, backgrounds. 2 minutes (or as long as you want!)
                        </p>
                    </div>
                </div>
                {status(2) === "completed" ? (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer bg-teal-500"
                        onClick={() => {
                            props.updateOnboardingStep(2, "not_complete");
                        }}
                    >
                        <GoCheck className="text-lg text-white" />
                    </button>
                ) : (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer border border-slate-200"
                        onClick={() => {
                            props.updateOnboardingStep(2, "completed");
                        }}
                    >
                        <GoCheck className="text-lg text-slate-600" />
                    </button>
                )}
            </div>
            <div className="my-6 border-t border-slate-200"></div>
            <div className="flex flex-row items-center justify-between">
                <div
                    className="flex items-center cursor-pointer hover:opacity-80"
                    onClick={() => {
                        props.history.push("/settings/setup-instructions");
                    }}
                >
                    <div className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 border border-slate-200">
                        <IoExtensionPuzzleSharp className="text-slate-600 text-lg" />
                    </div>
                    <div className="ml-4 antialiased">
                        <p
                            className={`${
                                status(3) === "completed" ? "text-teal-600 line-through" : "text-slate-800"
                            } text-base font-medium`}
                        >
                            Put hagrid on your website
                        </p>
                        <p className="text-slate-600 text-sm mt-0.5">
                            Add the widget to your website, follow the guide for your platform. 5 minutes.
                        </p>
                    </div>
                </div>
                {status(3) === "completed" ? (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer bg-teal-500"
                        onClick={() => {
                            props.updateOnboardingStep(3, "not_complete");
                        }}
                    >
                        <GoCheck className="text-lg text-white" />
                    </button>
                ) : (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer border border-slate-200"
                        onClick={() => {
                            props.updateOnboardingStep(3, "completed");
                        }}
                    >
                        <GoCheck className="text-lg text-slate-600" />
                    </button>
                )}{" "}
            </div>
            <div className="my-6 border-t border-slate-200"></div>
            <div className="flex flex-row items-center justify-between">
                <div
                    className="flex items-center cursor-pointer hover:opacity-80"
                    onClick={() => {
                        props.history.push("/questions");
                    }}
                >
                    <div className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 border border-slate-200">
                        <AiFillDatabase className="text-slate-600 text-base" />
                    </div>
                    <div className="ml-4 antialiased">
                        <p
                            className={`${
                                status(4) === "completed" ? "text-teal-600 line-through" : "text-slate-800"
                            } text-base font-medium`}
                        >
                            Add your FAQs
                        </p>
                        <p className="text-slate-600 text-sm mt-0.5">
                            Add a couple of seed questions (and answers) to get going. 1 minute.
                        </p>
                    </div>
                </div>
                {status(4) === "completed" ? (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer bg-teal-500"
                        onClick={() => {
                            props.updateOnboardingStep(4, "not_complete");
                        }}
                    >
                        <GoCheck className="text-lg text-white" />
                    </button>
                ) : (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer border border-slate-200"
                        onClick={() => {
                            props.updateOnboardingStep(4, "completed");
                        }}
                    >
                        <GoCheck className="text-lg text-slate-600" />
                    </button>
                )}
            </div>
            <div className="my-6 border-t border-slate-200"></div>
            <div className="flex flex-row items-center justify-between">
                <div
                    className="flex items-center cursor-pointer hover:opacity-80"
                    onClick={() => {
                        props.history.push("/questions");
                    }}
                >
                    <div className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 border border-slate-200">
                        <FaCommentAlt className="text-slate-600 text-sm" />
                    </div>
                    <div className="ml-4 antialiased">
                        <p
                            className={`${
                                status(5) === "completed" ? "text-teal-600 line-through" : "text-slate-800"
                            } text-base font-medium`}
                        >
                            Answer your first question
                        </p>
                        <p className="text-slate-600 text-sm mt-0.5">
                            Post a reply to your first question, and start your journey with hagrid.
                        </p>
                    </div>
                </div>
                {status(5) === "completed" ? (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer bg-teal-500"
                        onClick={() => {
                            props.updateOnboardingStep(5, "not_complete");
                        }}
                    >
                        <GoCheck className="text-lg text-white" />
                    </button>
                ) : (
                    <button
                        className="rounded-full w-10 h-10 flex items-center justify-center shrink-0 cursor-pointer border border-slate-200"
                        onClick={() => {
                            props.updateOnboardingStep(5, "completed");
                        }}
                    >
                        <GoCheck className="text-lg text-slate-600" />
                    </button>
                )}
            </div>
        </div>
    );
}

export default withRouter(OnboardingSteps);
