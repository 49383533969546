import React from "react";

class MagicLogin extends React.Component{
    constructor(props){
        super();
    }

    componentDidMount(){
        document.title ='Login - hagrid';
        const checkExistingScript = document.getElementById("magic-login-script");
        if(checkExistingScript){
            return;
        }
        const script = document.createElement("script");
        script.type = "application/javascript";
        script.src = "https://auth.magic.link/pnp/login";
        script.id = "magic-login-script";
        script.setAttribute('data-magic-publishable-api-key', 'pk_live_06972B21F7C0450F');
        script.setAttribute('data-terms-of-service-uri', '/path/to/your/terms-of-service');
        script.setAttribute('data-privacy-policy-uri', '/path/to/your/privacy-policy');
        script.setAttribute('data-redirect-uri', '/login-callback');
        document.body.appendChild(script);
    }

    render(){
        return(
            <>
            </>
        )
    }
}
export default MagicLogin;
