import React from "react";
import SingleInputElement from "../element-types/single-input-element";
import LayoutElementsActionBtns from "./utils/layout-elements-actions-btns";

function ProfileImg(props) {
    return (
        <>
            {/* Image Width */}
            <SingleInputElement
                selectorName="profile-img-width"
                propertyName="Image Width"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["profile-img-width-desktop"] === props.themeConfig["profile-img-width-mobile"] &&
                    props.themeConfig["profile-img-width-desktop"] === props.themeConfig["profile-img-width-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Image Height */}
            <SingleInputElement
                selectorName="profile-img-height"
                propertyName="Image Height"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["profile-img-height-desktop"] ===
                        props.themeConfig["profile-img-height-mobile"] &&
                    props.themeConfig["profile-img-height-desktop"] === props.themeConfig["profile-img-height-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            {/* Border Radius */}
            <SingleInputElement
                selectorName="profile-img-border-radius"
                propertyName="Border Radius"
                themeConfig={props.themeConfig}
                changeDimensionInput={props.changeDimensionInput}
                deviceLevelConfig={true}
                changeDimensionInputOnAllDevices={props.changeDimensionInputOnAllDevices}
                isSameForAllDevices={
                    props.themeConfig["profile-img-border-radius-desktop"] ===
                        props.themeConfig["profile-img-border-radius-mobile"] &&
                    props.themeConfig["profile-img-border-radius-desktop"] ===
                        props.themeConfig["profile-img-border-radius-tablet"]
                        ? true
                        : false
                }
                device={props.device}
            />

            <LayoutElementsActionBtns
                themeConfig={props.themeConfig}
                updateThemeConfig={props.updateThemeConfig}
                resetThemeConfig={props.resetThemeConfig}
            />
        </>
    );
}

export default ProfileImg;
