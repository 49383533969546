import React, { useEffect, useRef } from "react";
import PreviewFrame from "./preview-frame";
import { BsLaptop, BsPhone, BsTablet } from "react-icons/bs";

function PreviewModal(props) {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.setPreviewModalOpen(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className="flex justify-center items-start overflow-y-scroll absolute inset-0 z-40 bg-black bg-opacity-80 min-h-screen">
            <div ref={wrapperRef} className="relative z-50 shadow w-[1200px] bg-white rounded-xl h-[1200px] mt-10">
                <div
                    className="flex flex-row items-center justify-center absolute top-6 right-6 cursor-pointer w-6 h-6 outline-none border-none"
                    onClick={() => props.setPreviewModalOpen(false)}
                >
                    <img
                        className="w-6 h-6 outline-none border-none"
                        src="https://hagrid-public.s3.us-east-2.amazonaws.com/public/assets/close.svg"
                        alt="close modal"
                    />
                </div>
                <div className="pt-8 flex flex-row justify-center">
                    <button
                        className={`${
                            props.device === "desktop" ? "text-purple-500" : "text-slate-600 hover:text-purple-500"
                        } px-3 text-sm font-medium flex flex-row items-center`}
                        onClick={() => props.changeDevice("desktop")}
                    >
                        <BsLaptop className="text-[34px] mr-2.5" />
                        Desktop
                    </button>
                    <button
                        className={`${
                            props.device === "mobile" ? "text-purple-500" : "text-slate-600 hover:text-purple-500"
                        } px-3 text-sm font-medium flex flex-row items-center`}
                        onClick={() => props.changeDevice("mobile")}
                    >
                        <BsPhone className="text-3xl mr-1" />
                        Mobile
                    </button>
                    <button
                        className={`${
                            props.device === "tablet" ? "text-purple-500" : "text-slate-600 hover:text-purple-500"
                        } px-3 text-sm font-medium flex flex-row items-center`}
                        onClick={() => props.changeDevice("tablet")}
                    >
                        <BsTablet className="text-3xl mr-1.5" />
                        Tablet
                    </button>
                </div>
                <PreviewFrame
                    themeConfig={props.themeConfig}
                    device={props.device}
                    selectedTheme={props.selectedTheme}
                    scale={props.scale}
                    openInModal={true}
                />
            </div>
        </div>
    );
}

export default PreviewModal;
