import React from "react";
import { PrimaryButton, SecondaryButton } from "../../../../utils/buttons";

function LayoutElementsActionBtns(props) {
    return (
        <div className="flex flex-row justify-start mb-5">
            <PrimaryButton buttonText={"Save Changes"} onClick={() => props.updateThemeConfig(props.themeConfig)} />
            <SecondaryButton buttonText={"Undo Changes"} onClick={() => props.resetThemeConfig()} />
        </div>
    );
}

export default LayoutElementsActionBtns;
