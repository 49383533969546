import React from "react";

function FaqZeroCase() {
    return (
        <div className="flex flex-col items-center mt-8">
            <div className="flex flex-col items-center">
                <img src="assets/images/questions-zero-case.svg" className="w-40" alt="no questions" />
                <p className="mt-8 text-zinc-800 text-lg font-medium max-w-xl antialiased">
                    There are no questions here yet
                </p>
            </div>
            <div className="max-w-3xl antialiased mt-12">
                <p className="text-zinc-800 font-semibold text-lg">Guide to Success</p>
                <p className="text-zinc-800 mt-6">
                    This tab shows you the questions you have posted as well questions that visitors have asked.
                </p>
                <p className="text-zinc-800 mt-8">
                    Click on <span className="font-semibold">Add Question</span> to seed your own FAQ to the widget. You
                    can post rich questions and answers and automatically{" "}
                    <span className="underline underline-offset-4 decoration-dotted decoration-purple-500">
                        embed links - including Youtube videos, Tweets, Pinterest, Facebook posts and much more
                    </span>
                    .
                </p>
                <p className="text-zinc-800 mt-8">
                    To see unanswered questions from your website visitors go to the{" "}
                    <span className="font-semibold">Inbox</span> tab.
                </p>
                <p className="text-zinc-800 mt-8">
                    For <span className="font-semibold">Private</span> questions, only the person who asked the question
                    can see the answer.{" "}
                    <span className="underline underline-offset-4 decoration-dotted decoration-purple-500">
                        The <span className="font-semibold">Private</span> tab only shows questions from visitors that
                        have been marked as Private.
                    </span>{" "}
                    All questions are <span className="font-semibold">Public</span> unless marked as{" "}
                    <span className="font-semibold">Private</span>.
                </p>
                <p className="text-zinc-800 mt-8">
                    <span className="underline underline-offset-4 decoration-dotted decoration-purple-500">
                        The order of the questions here is the same on the widget on the website
                    </span>
                    . <br />
                    The questions are arranged with the newest on top, so the latest questions are seen first. However
                    you can reorder the questions as you see fit.
                </p>
                <img src="assets/images/faq-zero-case-1.svg" loading="lazy" alt="no questions" />
                <p className="text-zinc-800 mt-8">
                    You can assign questions to specific pages, and you can filter by page, to see the questions for
                    each specific page.
                </p>

                <p className="text-zinc-800 mt-8">
                    Remember that you can delete, edit, reassign, mark questions at any time as you see fit.
                </p>
            </div>
        </div>
    );
}

export default FaqZeroCase;
