import React from "react";
import ColorSelection from "../element-types/color-selection";
import LayoutElementsActionBtns from "./utils/layout-elements-actions-btns";

function UserQuestionCard(props) {
    return (
        <>
            {/* Font Color */}
            <ColorSelection
                selectorName="user-question-card-bg-color"
                propertyName="Background Color"
                changeColor={props.changeColor}
                changeColorOnAllDevices={props.changeColorOnAllDevices}
                themeConfig={props.themeConfig}
                deviceLevelConfig={false}
                device={props.device}
            />

            <LayoutElementsActionBtns
                themeConfig={props.themeConfig}
                updateThemeConfig={props.updateThemeConfig}
                resetThemeConfig={props.resetThemeConfig}
            />
        </>
    );
}

export default UserQuestionCard;
