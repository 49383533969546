import React from "react";
import { PulseLoader } from "react-spinners";
import LeftNavbar from "../utils/left-navbar";
import { PageHeading, SectionHeading } from "../utils/typography";
import OnboardingSteps from "./onboarding-steps";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa";
import UpgradeNudge from "../utils/upgrade-nudge";

function DashboardView(props) {
    return (
        <>
            <div className="flex">
                <LeftNavbar />

                <div className="pr-12 pl-28 lg:pl-[17rem] w-full pb-20">
                    <UpgradeNudge />
                    <div className="pt-12 pb-8">
                        <PageHeading text="Welcome to hagrid!" />
                    </div>
                    {props.pageIsLoading ? (
                        <div className="flex justify-center mt-40">
                            <PulseLoader color="#e879f9" />
                        </div>
                    ) : (
                        <div className="bg-white rounded-2xl px-12 py-10 w-full max-w-3xl shadow-container">
                            <SectionHeading text="Get hagrid setup in less than 10 minutes" />
                            <p className="antialiased mt-2 mb-4 text-base text-slate-800">
                                Or just get on a call with a founder, for a live guided setup.
                            </p>
                            <button
                                className="mb-8 text-purple-500 text-base flex items-center hover:text-purple-700"
                                onClick={() => {
                                    window.$crisp.push(["do", "chat:open"]);
                                }}
                            >
                                Start live guided setup <FaChevronRight className="text-sm ml-2" />
                            </button>
                            <OnboardingSteps updateOnboardingStep={props.updateOnboardingStep} steps={props.steps} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default DashboardView;
