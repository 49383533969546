import React, { useState, useEffect } from "react";
import { createPreviewHighlighter } from "../../../utils/utils";

function PreviewScreen(props) {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    useEffect(() => {
        createPreviewHighlighter(
            document.getElementsByTagName("iframe")[0].contentDocument,
            props.selectedElementClass
        );
    }, [props.selectedElementClass, props.device]);

    return (
        <div className="hg-widget-container">
            <div className="hg-post-container">
                <p className="hg-heading">Frequently Asked Questions</p>
                <div className="hg-qtextbox">
                    <p className="hg-qtextbox-text">What's your name?</p>
                </div>

                <div className="flex flex-row justify-end items-center border-none outline-none">
                    <div className="flex flex-row items-center">
                        <label className="inline-flex items-center justify-start mr-3.5 font-Lato outline-none border-none">
                            <input
                                type="checkbox"
                                className="h-4 w-4 appearance-none hidden peer"
                                checked={true}
                                readOnly
                            />
                            <span className="w-5 h-5 m-0 p-0 bg-white border border-solid border-mine-shaft rounded-sm block peer-checked:hidden outline-none cursor-pointer"></span>
                            <span className="w-5 h-5 m-0 p-0 bg-primary-btn-bg-color border border-solid border-mine-shaft rounded-sm hidden peer-checked:block outline-none cursor-pointer">
                                <img
                                    src="https://hagrid-public.s3.us-east-2.amazonaws.com/public/assets/check.svg"
                                    width="20px"
                                    className="w-5 h-5 block"
                                />
                            </span>
                            <span className="font-widget-font-family leading-5 tracking-normal ml-2.5 text-subheading-font-color-mobile text-sm font-normal">
                                {"Post anonymously"}
                            </span>
                        </label>
                        <div className="hg-primary-btn  hg-primary-btn-border-property">Post Question</div>
                    </div>
                </div>
            </div>

            <div className="hg-question-list-title-container">
                <p className="hg-subheading">All questions</p>

                <div
                    className="hg-secondary-btn  hg-secondary-btn-border-property"
                    onClick={() => setIsLoginModalOpen(true)}
                >
                    Login
                </div>
            </div>

            <div>
                {props.selectedTheme === "simple" ? (
                    <>
                        <div className="hg-question-card relative">
                            <div className="hg-question">
                                <p className="hg-question-text">What kind of question card is this?</p>
                            </div>
                            <div className="hg-answer">
                                <p className="hg-answer-text">
                                    This is how a question answer would look when you add a question from your side
                                </p>
                            </div>
                        </div>
                        <div className="hg-user-question-card relative">
                            <div className="hg-question">
                                <p className="hg-question-text">What kind of question card is this?</p>
                                <div className="flex flex-row items-center mt-2">
                                    <img
                                        className="hg-profile-img"
                                        src={
                                            "https://lh3.googleusercontent.com/a/AATXAJxCRJnEkAB4VLWhf0DbnVOkxlBX9hU6SU7sp3pu=s96-c"
                                        }
                                        alt="User Profile Picture"
                                    />
                                    <p className="hg-qusername">User name</p>
                                </div>
                            </div>
                            <div className="hg-answer">
                                <p className="hg-answer-text">
                                    This is how a question answer would look to your visitors when their question is
                                    answered
                                </p>
                            </div>
                        </div>
                        <div className="hg-highlighted-question-card relative">
                            <div className="hg-question">
                                <p className="hg-question-text">What kind of question card is this?</p>
                                <div className="flex flex-row items-center mt-2">
                                    <img
                                        className="hg-profile-img"
                                        src={
                                            "https://lh3.googleusercontent.com/a/AATXAJxCRJnEkAB4VLWhf0DbnVOkxlBX9hU6SU7sp3pu=s96-c"
                                        }
                                        alt="User Profile Picture"
                                    />
                                    <p className="hg-qusername">User name</p>
                                </div>
                            </div>
                            <div className="hg-answer">
                                <p className="hg-answer-text">
                                    This is how a question answer would look when they come to the widget through email
                                    link
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="hg-question-card relative">
                            <div className="hg-question cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                                <div className="flex flex-row justify-between items-center">
                                    <p className="hg-question-text">What kind of question card is this?</p>
                                    <i
                                        className={`icon-down-open hg-accordion-dropdown-icon ${
                                            isOpen1 ? "rotate-180" : "rotate-0"
                                        }`}
                                    />
                                </div>
                            </div>
                            {isOpen1 ? (
                                <div className="hg-answer">
                                    <p className="hg-answer-text">
                                        This is how a question answer would look when you add a question from your side
                                    </p>
                                </div>
                            ) : null}
                        </div>
                        <div className="hg-user-question-card relative">
                            <div className="hg-question cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                                <div className="flex flex-row justify-between items-center">
                                    <p className="hg-question-text">What kind of question card is this?</p>
                                    <i
                                        className={`icon-down-open hg-accordion-dropdown-icon ${
                                            isOpen2 ? "rotate-180" : "rotate-0"
                                        }`}
                                    />
                                </div>
                                <div className="flex flex-row items-center mt-2">
                                    <img
                                        className="hg-profile-img"
                                        src={
                                            "https://lh3.googleusercontent.com/a/AATXAJxCRJnEkAB4VLWhf0DbnVOkxlBX9hU6SU7sp3pu=s96-c"
                                        }
                                        alt="User Profile Picture"
                                    />
                                    <p className="hg-qusername">User name</p>
                                </div>
                            </div>
                            {isOpen2 ? (
                                <div className="hg-answer">
                                    <p className="hg-answer-text">
                                        This is how a question answer would look when you add a question from your side
                                    </p>
                                </div>
                            ) : null}
                        </div>
                        <div className="hg-highlighted-question-card relative">
                            <div className="hg-question cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                                <div className="flex flex-row justify-between items-center">
                                    <p className="hg-question-text">What kind of question card is this?</p>
                                    <i
                                        className={`icon-down-open hg-accordion-dropdown-icon ${
                                            isOpen3 ? "rotate-180" : "rotate-0"
                                        }`}
                                    />
                                </div>
                                <div className="flex flex-row items-center mt-2">
                                    <img
                                        className="hg-profile-img"
                                        src={
                                            "https://lh3.googleusercontent.com/a/AATXAJxCRJnEkAB4VLWhf0DbnVOkxlBX9hU6SU7sp3pu=s96-c"
                                        }
                                        alt="User Profile Picture"
                                    />
                                    <p className="hg-qusername">User name</p>
                                </div>
                            </div>
                            {isOpen3 ? (
                                <div className="hg-answer">
                                    <p className="hg-answer-text">
                                        This is how a question answer would look when you add a question from your side
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
            </div>

            <div className="flex flex-row justify-center items-center py-2 my-0 mx-0 border-none outline-none">
                <div className="hg-secondary-btn  hg-secondary-btn-border-property">Load more questions</div>
            </div>

            {isLoginModalOpen ? (
                <div className="hg-login-modal-container">
                    <div className="hg-login-modal">
                        <div
                            className="flex flex-row items-center justify-center absolute top-6 right-6 cursor-pointer w-6 h-6 outline-none border-none"
                            onClick={() => setIsLoginModalOpen(false)}
                        >
                            <img
                                className="w-6 h-6 outline-none border-none"
                                src="https://hagrid-public.s3.us-east-2.amazonaws.com/public/assets/close.svg"
                                alt="close modal"
                            />
                        </div>

                        <p className="hg-subheading">Login to see your posts</p>
                        <p className="hg-login-description">Access all your posts and the responses.</p>
                        <div className="hg-login-option hg-login-option-border-property">
                            <div className="flex flex-row items-center justify-center w-5 h-5 mr-3 ml-0 my-0 p-0 outline-none border-none">
                                <img
                                    src="https://hagrid-public.s3.us-east-2.amazonaws.com/public/assets/facebook.svg"
                                    width="20px"
                                    className="w-5 h-5"
                                />
                            </div>
                            Login with Facebook
                        </div>
                        <div className="hg-login-option hg-login-option-border-property">
                            <div className="flex flex-row items-center justify-center w-5 h-5 mr-3 ml-0 my-0 p-0 outline-none border-none">
                                <img
                                    src="https://hagrid-public.s3.us-east-2.amazonaws.com/public/assets/google.svg"
                                    width="20px"
                                    className="w-5 h-5"
                                />
                            </div>
                            Login with Google
                        </div>
                        <div className="hg-login-option hg-login-option-border-property">
                            <div className="flex flex-row items-center justify-center w-5.5 h-4.5 mr-3 ml-0 my-0 p-0 outline-none border-none">
                                <img
                                    src="https://hagrid-public.s3.us-east-2.amazonaws.com/public/assets/twitter.svg"
                                    width="22px"
                                    className="w-5.5 h-4.5"
                                />
                            </div>
                            Login with Twitter
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default PreviewScreen;
