import React, { useRef, useEffect } from "react";

function ConfirmationModal(props) {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.closeModal();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className="flex justify-center items-center overflow-y-scroll fixed inset-0 z-40 bg-black bg-opacity-80">
            <div
                ref={wrapperRef}
                className="relative z-50 bg-white px-6 py-10 rounded-xl w-80 sm:w-96 shadow flex flex-col items-center"
            >
                <div className="text-4xl text-masala">{props.icon}</div>
                <p className="text-masala text-lg font-normal mt-4 px-6">{props.text}</p>
                <div className="flex items-center justify-center mt-8">
                    <button
                        className="px-8 py-3 bg-red-400 hover:bg-red-600 text-sm text-white font-semibold rounded-lg"
                        onClick={props.onButtonClick}
                    >
                        {props.buttonText}
                    </button>
                    <button
                        className="ml-3 px-8 py-3 bg-slate-50 hover:bg-slate-200 text-sm text-slate-800 font-semibold rounded-lg"
                        onClick={props.closeModal}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationModal;
