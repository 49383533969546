import React from "react";
import { VscComment, VscCreditCard, VscGear, VscHome, VscSignOut } from "react-icons/vsc";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { getAPIBaseUrl, getItemFromLocalStorage } from "../utils/utils";
import { NavLink } from "react-router-dom";

function TopNavbar(props) {
    const openStripeBilling = async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
        }

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .get(`${apiBaseUrl}/oauth/billing-portal/`, { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response.data);
                    window.open(response.data.billing_portal_url, "_self");
                }
            })
            .catch((error) => {
                console.log("No subscription created for this user");
                console.log(error);
                if (error.response.data.detail === "Signature has expired.") {
                    // remove access token from local storage
                    localStorage.removeItem("access-token");
                    this.props.history.push("/login");
                }
            });
    };

    return (
        <nav className="bg-white h-[4.375rem] flex justify-between items-center px-24 fixed top-0 w-full z-10">
            <div className="flex items-center cursor-pointer" onClick={() => props.history.push("/")}>
                <img src="/assets/images/hagrid-logo-alt.svg" alt="logo" loading="lazy" />
            </div>
            <div className="flex items-center justify-center">
                <NavLink
                    className="hover:bg-slate-50 rounded-lg text-slate-800 py-2.5 px-5 flex items-center justify-center"
                    activeClassName=" py-2.5 px-5 w-full flex justify-center items-center bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                    exact
                    to="/"
                >
                    <VscHome className="text-xl" />
                    <p className="text-base ml-2 font-normal">Home</p>
                </NavLink>
                <NavLink
                    className="hover:bg-slate-50 rounded-lg text-slate-800 py-2.5 px-5 flex items-center justify-center"
                    activeClassName=" py-2.5 px-5 w-full flex justify-center items-center bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                    exact
                    to="/questions"
                >
                    <VscComment className="text-xl" />
                    <p className="text-base ml-2 font-normal">Questions</p>
                </NavLink>
                <NavLink
                    className="hover:bg-slate-50 rounded-lg text-slate-800 py-2.5 px-5 flex items-center justify-center"
                    activeClassName=" py-2.5 px-5 w-full flex justify-center items-center bg-fuchsia-50 rounded-lg hover:bg-fuchsia-50 border border-purple-200"
                    exact
                    to="/settings/design"
                >
                    <VscGear className="text-xl" />
                    <p className="text-base ml-2 font-normal">Settings</p>
                </NavLink>
                <button
                    className="hover:bg-slate-50 rounded-lg text-slate-800 py-2.5 px-5 flex items-center justify-center"
                    onClick={openStripeBilling}
                >
                    <VscCreditCard className="text-xl" />
                    <p className="text-base ml-2 font-normal">Billing</p>
                </button>
                <button
                    className="hover:bg-slate-50 rounded-lg text-slate-800 py-2.5 px-5 flex items-center justify-center"
                    onClick={() => {
                        props.history.push("/logout");
                    }}
                >
                    <VscSignOut className="text-xl" />
                    <p className="text-base ml-2 font-normal">Logout</p>
                </button>
            </div>
        </nav>
    );
}

export default withRouter(TopNavbar);
