import React from "react";
import { PulseLoader } from 'react-spinners';

class MagicLogout extends React.Component{
    constructor(props){
        super();
    }

    componentDidMount(){

        // Remove access token from local storage
        localStorage.removeItem('access-token');

        const checkExistingScript = document.getElementById("magic-logout-script");
        if(checkExistingScript){
            return;
        }
        const script = document.createElement("script");
        script.type = "application/javascript";
        script.src = "https://auth.magic.link/pnp/logout";
        script.id = "magic-logout-script";
        script.setAttribute('data-magic-publishable-api-key', 'pk_live_06972B21F7C0450F');
        script.setAttribute('data-redirect-uri', '/login');
        document.body.appendChild(script);
    }

    render(){
        return(
            <div className="h-screen flex items-center justify-center">
                <PulseLoader color="#e879f9" />
            </div>
        )
    }
}
export default MagicLogout;
