import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { BiSortAlt2 } from "react-icons/bi";
import SortQuestionsPopup from "../utils/sort-questions-popup";

function FaqFilters(props) {
    const [isPageDropdownOpen, setIsPageDropdownOpen] = useState(false);
    const [isSortPopupOpen, setIsSortPopupOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState("");

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsPageDropdownOpen(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const pageSelectRef = useRef(null);
    useOutsideAlerter(pageSelectRef);

    let pageLinksList;
    if (props.pageLinks) {
        pageLinksList = (props.pageLinks || []).map((pageLink) => {
            return (
                <div
                    key={pageLink.id}
                    className={`${
                        props.selectedPageLink === pageLink.url
                            ? "bg-zinc-100 font-semibold"
                            : "bg-white hover:bg-zinc-50"
                    } px-6 py-3 cursor-pointer first:hover:rounded-t-lg last:hover:rounded-b-lg`}
                    onClick={() => {
                        props.onPageLinkChange(pageLink.url, pageLink.title, pageLink.id);
                        setIsPageDropdownOpen(false);
                    }}
                >
                    <p className="text-zinc-800 text-sm mb-1 line-clamp-2 empty:hidden">{pageLink.title}</p>
                    <p className="text-zinc-600 text-xs line-clamp-2 font-normal">{pageLink.url}</p>
                </div>
            );
        });
    }

    useEffect(() => {
        if (props.pageLinks.length > 0) {
            console.log(props.pageLinks);
            const questionSortOrder = props.pageLinks.find(
                (pageLink) => pageLink.url === props.selectedPageLink
            ).sort_order;
            setSortOrder(questionSortOrder);
        }
    }, [props.selectedPageLink, props.pageLinks]);

    return (
        <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex flex-row items-center w-full">
                {/* Question status filter */}
                <div className="flex flex-col max-w-xs w-full">
                    <p className="text-xs text-zinc-400 font-semibold mb-2 antialiased">STATUS</p>
                    <div className="flex flex-row items-center bg-white border border-zinc-200 rounded-lg mr-4">
                        <button
                            className={`${
                                props.statusFilter === "PUBLISHED"
                                    ? "bg-fuchsia-50 font-semibold border border-purple-200"
                                    : "font-normal hover:bg-zinc-50"
                            } w-1/2 text-sm text-zinc-800 py-3 px-6 rounded-lg`}
                            onClick={() => props.changeStatusFilter("PUBLISHED")}
                        >
                            Public
                        </button>
                        <button
                            className={`${
                                props.statusFilter === "PRIVATE"
                                    ? "bg-fuchsia-50 font-semibold border border-purple-200"
                                    : "font-normal hover:bg-zinc-50"
                            } w-1/2 text-sm text-zinc-800 py-3 px-6 rounded-lg`}
                            onClick={() => props.changeStatusFilter("PRIVATE")}
                        >
                            Private
                        </button>
                    </div>
                </div>

                {/* Select page filter */}
                <div className="flex flex-col max-w-xs w-full relative" ref={pageSelectRef}>
                    <p className="text-xs text-zinc-400 font-semibold mb-2 antialiased">SELECT PAGE</p>
                    <div
                        className="border border-zinc-200 bg-white py-3 px-6  flex items-center justify-between rounded-lg cursor-pointer"
                        onClick={() => setIsPageDropdownOpen(!isPageDropdownOpen)}
                    >
                        <p className="text-sm text-zinc-800 line-clamp-1">
                            {props.selectedPageTitle || props.selectedPageLink}
                        </p>
                        <FiChevronDown className="text-lg text-zinc-800" />
                    </div>
                    <div
                        className={`${
                            isPageDropdownOpen ? "flex flex-col" : "hidden"
                        } bg-white mt-1 rounded-lg border border-zinc-200 z-10 absolute top-[4.5rem] shadow-lg divide-y divide-zinc-100 overflow-scroll w-full`}
                        style={{ maxHeight: "min(calc(100vh - 14rem), 24rem)" }}
                    >
                        {pageLinksList}
                    </div>
                </div>
            </div>

            {/* Sort */}
            <div
                className={`${
                    props.statusFilter === "PUBLISHED" && props.totalQuestions > 0 ? "flex" : "hidden"
                } flex flex-col max-w-[12rem] w-full`}
            >
                <p className="text-xs text-zinc-400 font-semibold mb-2 antialiased">SORT</p>
                <button
                    className="border border-zinc-200 bg-white py-3 px-6  flex items-center justify-between rounded-lg cursor-pointer text-sm text-zinc-800 antialiased"
                    onClick={() => setIsSortPopupOpen(true)}
                >
                    {sortOrder === "newest" ? "Newest first" : sortOrder === "oldest" ? "Oldest first" : "Manually"}
                    <BiSortAlt2 className="text-lg" />
                </button>
            </div>

            {isSortPopupOpen ? (
                <SortQuestionsPopup
                    setIsSortPopupOpen={setIsSortPopupOpen}
                    sortOrder={sortOrder}
                    totalQuestions={props.totalQuestions}
                    selectedPageLink={props.selectedPageLink}
                    selectedPageId={props.selectedPageId}
                    setSortOrder={setSortOrder}
                    refreshQuestions={props.refreshQuestions}
                />
            ) : null}
        </div>
    );
}
export default FaqFilters;
