import React from "react";
import FaqView from "./faq-view";
import { addQuestion, getPages, getQuestions, updateQuestion } from "../utils/apis";
import { getApiVersion, getItemFromLocalStorage, getUrlParameter, setUrlParameter } from "../utils/utils";

class FaqContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPageLink: "",
            selectedPageTitle: "",
            selectedPageId: "",
            currentPage: 1,
            pageSize: 10,
            pageLinks: [],
            addFaqModalIsOpen: false,
            searchQuery: "",
            questions: [],
            totalQuestions: null,
            questionsAreLoading: true,
            questionAdded: false,
            questionIsUpdating: false,
            pageLinksAreLoading: true,
            pageLinksForAddingFaq: [],
            statusFilter: "PUBLISHED"
        };
        // this.getPageLinks = this.debounce(this.getPageLinks, 200);
        this.getPageLinks = this.getPageLinks.bind(this);
        this.changeStatusFilter = this.changeStatusFilter.bind(this);
    }

    getFaqQuestions = async (pageUrl, currentPage, pageSize, statusFilter) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        this.setState({
            questionsAreLoading: true
        });

        const resp = await getQuestions(pageUrl, currentPage, pageSize, statusFilter);
        if (resp.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }
        this.setState({
            questions: resp.data.posts,
            totalQuestions: resp.data.total_results,
            questionsAreLoading: false
        });
    };

    updateQuestionAddedState = () => {
        this.setState({
            questionAdded: false
        });
    };

    addFaqQuestion = async (question, answer, pageUrls) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        this.setState({
            questionAdded: false,
            questionIsUpdating: true
        });

        const resp = await addQuestion(question, answer, pageUrls);
        if (resp.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }
        this.setState({
            questionAdded: true,
            questionIsUpdating: false
        });
        this.getFaqQuestions(
            this.state.selectedPageLink,
            this.state.currentPage,
            this.state.pageSize,
            this.state.statusFilter
        );
    };

    refreshQuestions = async () => {
        await this.getFaqQuestions(
            this.state.selectedPageLink,
            this.state.currentPage,
            this.state.pageSize,
            this.state.statusFilter
        );
    };

    updateFaqQuestion = async (questionId, questionStatus, question, answer, pageUrls, answerId) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        this.setState({
            questionAdded: false,
            questionIsUpdating: true
        });
        const resp = await updateQuestion(questionId, questionStatus, question, answer, pageUrls, answerId);
        if (resp.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }
        console.log("Question updated successfully");
        this.setState({
            questionAdded: true,
            questionIsUpdating: false
        });
        this.getFaqQuestions(
            this.state.selectedPageLink,
            this.state.currentPage,
            this.state.pageSize,
            this.state.statusFilter
        );
    };

    getPageLinks = async (searchTerm) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        this.setState({
            pageLinksAreLoading: true
        });

        const resp = await getPages("app_user", searchTerm);
        if (resp.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }

        this.setState({
            pageLinksAreLoading: false
        });
        if (resp.data.pages.length > 0) {
            let selectedPageLink, selectedPageTitle, selectedPageId, pageNumber, status;
            if (getUrlParameter("page") && getUrlParameter("page") !== "") {
                pageNumber = getUrlParameter("page");
                this.setState({
                    currentPage: parseInt(pageNumber)
                });
            } else {
                pageNumber = this.state.currentPage;
            }
            if (getUrlParameter("status") && getUrlParameter("status") !== "") {
                status = getUrlParameter("status");
                this.setState({
                    statusFilter: status
                });
            } else {
                status = this.state.statusFilter;
            }
            if (getUrlParameter("url") && getUrlParameter("url") !== "") {
                selectedPageLink = getUrlParameter("url");
                resp.data.pages.find((page) => {
                    if (page.url === selectedPageLink) {
                        selectedPageTitle = page.title;
                        selectedPageId = page.id;
                    }
                });

                this.setState({
                    selectedPageLink: selectedPageLink,
                    selectedPageTitle: selectedPageTitle,
                    selectedPageId: selectedPageId
                });
            } else {
                selectedPageLink = resp.data.pages[0].url;
                selectedPageTitle = resp.data.pages[0].title;
                selectedPageId = resp.data.pages[0].id;
            }

            this.setState({
                selectedPageLink: selectedPageLink,
                selectedPageTitle: selectedPageTitle,
                selectedPageId: selectedPageId,
                pageLinks: resp.data.pages
            });
            this.getFaqQuestions(
                selectedPageLink,
                this.state.currentPage,
                this.state.pageSize,
                this.state.statusFilter
            );
        }
    };

    onPageLinkChange = async (url, title, pageId) => {
        await this.setState(
            {
                selectedPageLink: url,
                selectedPageTitle: title,
                selectedPageId: pageId,
                currentPage: 1
            },
            () => {
                this.getFaqQuestions(
                    this.state.selectedPageLink,
                    this.state.currentPage,
                    this.state.pageSize,
                    this.state.statusFilter
                );
            }
        );

        setUrlParameter("url", url);
    };

    debounce = (fn, delay) => {
        let timer = null;
        return function (...args) {
            const context = this;
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args);
            }, delay);
        };
    };

    handleSearchForm = async (event) => {
        await this.setState(
            {
                searchQuery: event.target.value
            },
            () => {
                this.getPageLinks(this.state.searchQuery);
            }
        );
    };

    changeFaqModalState = (value) => {
        this.setState({
            addFaqModalIsOpen: value
        });
    };

    changePageNumber = async (pageNumber) => {
        await this.setState(
            {
                currentPage: pageNumber
            },
            () => {
                this.getFaqQuestions(
                    this.state.selectedPageLink,
                    this.state.currentPage,
                    this.state.pageSize,
                    this.state.statusFilter
                );
            }
        );
        setUrlParameter("page", pageNumber);
    };

    changeStatusFilter = async (status) => {
        await this.setState(
            {
                statusFilter: status
            },
            () => {
                this.getFaqQuestions(
                    this.state.selectedPageLink,
                    this.state.currentPage,
                    this.state.pageSize,
                    this.state.statusFilter
                );
            }
        );
        setUrlParameter("status", status);
    };

    componentDidMount() {
        document.title = "Questions - hagrid";
        this.getPageLinks();
    }

    render() {
        // console.log(this.state);
        return (
            <>
                <FaqView
                    selectedPageLink={this.state.selectedPageLink}
                    selectedPageTitle={this.state.selectedPageTitle}
                    selectedPageId={this.state.selectedPageId}
                    pageLinks={this.state.pageLinks}
                    onPageLinkChange={this.onPageLinkChange}
                    addFaqModalIsOpen={this.state.addFaqModalIsOpen}
                    changeFaqModalState={this.changeFaqModalState}
                    handleSearchForm={this.handleSearchForm}
                    questions={this.state.questions}
                    questionsAreLoading={this.state.questionsAreLoading}
                    totalQuestions={this.state.totalQuestions}
                    currentPage={this.state.currentPage}
                    pageSize={this.state.pageSize}
                    addFaqQuestion={this.addFaqQuestion}
                    questionAdded={this.state.questionAdded}
                    updateQuestionAddedState={this.updateQuestionAddedState}
                    changePageNumber={this.changePageNumber}
                    pageLinksAreLoading={this.state.pageLinksAreLoading}
                    questionIsUpdating={this.state.questionIsUpdating}
                    updateFaqQuestion={this.updateFaqQuestion}
                    pageLinksForAddingFaq={this.state.pageLinksForAddingFaq}
                    changeStatusFilter={this.changeStatusFilter}
                    statusFilter={this.state.statusFilter}
                    refreshQuestions={this.refreshQuestions}
                />
            </>
        );
    }
}

export default FaqContainer;
