import React from "react";
import LeftNavbar from "../../utils/left-navbar";
import { PageHeading } from "../../utils/typography";
import SetupInstructions from "../../dashboard/setup-instructions";
import { PulseLoader } from "react-spinners";
import SettingsNav from "../settings-nav";

function SetupInstructionsView(props) {
    return (
        <div className="flex">
            <LeftNavbar />

            <div className="pr-12 pl-28 lg:pl-[17rem] w-full pb-20">
                <div className="pt-12 pb-8 flex flex-row justify-between items-center">
                    <PageHeading text="Setup Instructions" />
                    <SettingsNav />
                </div>
                {props.pageIsLoading ? (
                    <div className="flex justify-center mt-40">
                        <PulseLoader color="#e879f9" />
                    </div>
                ) : (
                    <div className="max-w-4xl">
                        <SetupInstructions websiteId={props.websiteId} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default SetupInstructionsView;
