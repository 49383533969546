import React from "react";
import LeftNavbar from "../../utils/left-navbar";
import { PageHeading } from "../../utils/typography";
import { PulseLoader } from "react-spinners";
import ProfileDetails from "../../dashboard/profile-details";
import SettingsNav from "../settings-nav";
import EditProfile from "../../dashboard/edit-profile";

function ProfileView(props) {
    return (
        <div className="flex">
            <LeftNavbar />

            <div className="pr-12 pl-28 lg:pl-[17rem] w-full pb-20">
                <div className="pt-12 pb-8 flex flex-row justify-between items-center">
                    <PageHeading text="Profile" />
                    <SettingsNav />
                </div>
                {props.pageIsLoading ? (
                    <div className="flex justify-center mt-40">
                        <PulseLoader color="#e879f9" />
                    </div>
                ) : (
                    <div className="max-w-4xl">
                        {props.isEditProfileOpen ? (
                            <EditProfile
                                profileImageUrl={props.profileImageUrl}
                                getProfileImage={props.getProfileImage}
                                onProfileInputChange={props.onProfileInputChange}
                                onProfileUpdate={props.onProfileUpdate}
                                userName={props.userName}
                            />
                        ) : (
                            <ProfileDetails
                                userName={props.userName}
                                profileImageUrl={props.profileImageUrl}
                                toggleEditProfileState={props.toggleEditProfileState}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProfileView;
