import React from "react";
import { PrimaryButton, SecondaryButton } from "../../utils/buttons";

function EditText(props) {
    const arrayOfBlacklistedConfigKeys = [
        "language",
        "login-subheading-post-question",
        "fb-login-button",
        "google-login-button",
        "twitter-login-button",
        "textbox-placeholder"
    ];

    let renderLabels;
    const arrayOfContentConfigKeys = Object.keys(props.contentConfig);
    if (props.contentConfig) {
        renderLabels = arrayOfContentConfigKeys.map((key, index) => {
            if (!arrayOfBlacklistedConfigKeys.includes(key)) {
                return (
                    <div key={index} className="flex flex-col items-start py-4 antialiased">
                        <label className="text-mine-shaft text-base pb-4 capitalize">{key.replace(/-/g, " ")}</label>
                        <textarea
                            type="text"
                            name={key}
                            value={props.contentConfig[key]}
                            onChange={props.changeContentConfig}
                            className="w-full max-w-xl outline-none focus:outline-none text-base text-mine-shaft border border-slate-400 focus:ring-0 focus:border-purple-300 rounded-md"
                            rows="3"
                        />
                    </div>
                );
            }
        });
    }

    return (
        <div className="overflow-scroll relative pt-4">
            {renderLabels}

            <div className="sticky bottom-0 pt-4 bg-white border-t">
                <PrimaryButton
                    buttonText={"Save Changes"}
                    onClick={() => props.updateContentConfig(props.contentConfig)}
                />
            </div>
        </div>
    );
}
export default EditText;
