import React from "react";
import axios from 'axios';
import qs from 'qs';
import { getAPIBaseUrl } from '../utils/utils';
import { getItemFromLocalStorage } from "../utils/utils";
import CollectInfoView from "./collect-info-view";

class CollectInfoContainer extends React.Component{
    constructor(props){
        super();
        this.state = {
            websiteName: '',
            websiteUrl: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.saveWebsiteDetails = this.saveWebsiteDetails.bind(this);
    }

    handleInputChange = async(e) => {
        await this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleSubmit = async() => {
        this.saveWebsiteDetails( this.state.websiteName, this.state.websiteUrl );
    };


    saveWebsiteDetails = async(websiteName, websiteUrl) => {
        let accessToken=getItemFromLocalStorage('access-token');
        
        let payload = {
            'domain': websiteUrl,
            'name': websiteName,
        }

        let apiBaseUrl = getAPIBaseUrl();

        await axios.post(`${apiBaseUrl}/oauth/websites/`, qs.stringify(payload), {headers: {'accessToken': accessToken}})
        .then(response => {
            if(response.status===200){
                this.props.history.push('/');
            }
        })
        .catch(error => {
            console.log('Error in saving details');
            console.log(error);
        })
    }

    componentDidMount(){
        document.title ='Onboarding - hagrid';
        if(!getItemFromLocalStorage('access-token')){
            this.props.history.push('/login');
        }
    }

    render(){
        return(
            <CollectInfoView 
                handleInputChange={this.handleInputChange}
                handleSubmit={this.handleSubmit}
                websiteName={this.state.websiteName}
                websiteUrl={this.state.websiteUrl}
            />
        )
    }
}

export default CollectInfoContainer;