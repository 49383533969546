import React from "react";
import axios from "axios";
import qs from "qs";
import { getAPIBaseUrl } from "../../utils/utils";
import { getItemFromLocalStorage } from "../../utils/utils";
import TextSettingsView from "./text-settings-view";

// Display toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTextConfig } from "../../utils/apis";

class TextSettingsContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            listOfLanguages: [],
            pageIsLoading: true,
            languageSelected: "",
            contentConfig: {},
            defaultContentConfig: {},
            textSettingsStep: "set-language"
        };
        // this.getDefaultContentConfig = this.getDefaultContentConfig.bind(this);
        this.selectLanguageFromList = this.selectLanguageFromList.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.changeTextSettingsStep = this.changeTextSettingsStep.bind(this);
        this.changeContentConfig = this.changeContentConfig.bind(this);
    }

    getWebsiteConfig = async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!getItemFromLocalStorage("access-token")) {
            this.props.history.push("/login");
        }

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .get(`${apiBaseUrl}/posts/config/`, { headers: { accessToken: accessToken } })
            .then((response) => {
                // console.log(response);
                this.setState(
                    {
                        languageSelected: response.data.website.configuration.language,
                        contentConfig: response.data.website.configuration.content_config
                    },
                    () => {
                        if (!response.data.website.configuration.content_config) {
                            this.setDefaultConfig(response.data.website.configuration.language);
                        }
                    }
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };

    setDefaultConfig = async (language) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        const response = await getTextConfig();
        if (response.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }

        this.setState(
            {
                contentConfig: response.data.records.find((record) => {
                    return record.fields.language === language;
                }).fields
            },
            () => {
                this.updateContentConfig(this.state.contentConfig);
            }
        );
    };

    getLanguages = async () => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!accessToken) {
            this.props.history.push("/login");
            return;
        }
        let websiteId = getItemFromLocalStorage("website-id");
        if (!websiteId) {
            this.props.history.push("/");
            return;
        }

        this.setState({
            pageIsLoading: true
        });

        const response = await getTextConfig();
        if (response.error === "Signature has expired.") {
            this.props.history.push("/login");
            return;
        }

        this.setState({
            listOfLanguages: response.data.records.map((record) => {
                return record.fields.language;
            }),
            pageIsLoading: false
        });
    };

    changeLanguage = async (language) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!getItemFromLocalStorage("access-token")) {
            this.props.history.push("/login");
        }

        let payload = {
            language: language
        };

        this.setState({
            showSuccessMessage: false
        });

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .post(`${apiBaseUrl}/posts/config/`, payload, { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Updated language");
                    this.setState(
                        {
                            languageSelected: response.data.website.configuration.language,
                            showSuccessMessage: true
                        },
                        () => {
                            this.setDefaultConfig(response.data.website.configuration.language);
                        }
                    );
                }
            })
            .catch((error) => {
                console.log("Error in updating language");
                console.log(error);
            });
    };

    changeTextSettingsStep = (step) => {
        this.setState({
            textSettingsStep: step
        });
    };

    changeContentConfig = (event) => {
        this.setState((prevState) => ({
            contentConfig: {
                ...prevState.contentConfig,
                [event.target.name]: event.target.value
            }
        }));
    };

    selectLanguageFromList = (event) => {
        this.setState({
            languageSelected: event.target.value
        });
    };

    updateContentConfig = async (contentConfig) => {
        let accessToken = getItemFromLocalStorage("access-token");
        if (!getItemFromLocalStorage("access-token")) {
            this.props.history.push("/login");
        }

        let payload = {
            content_config: contentConfig
        };

        let apiBaseUrl = getAPIBaseUrl();

        await axios
            .post(`${apiBaseUrl}/posts/config/`, payload, { headers: { accessToken: accessToken } })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Updated content layout");
                    this.setState({
                        contentConfig: response.data.website.configuration.content_config
                    });
                }
                toast.success("Content updated!");
            })
            .catch((error) => {
                console.log("Error in updating content config");
                console.log(error);
                // toast.error("Error in updating text. Please contact us.");
            });
    };

    componentDidMount() {
        document.title = "Text Settings - hagrid";
        if (!getItemFromLocalStorage("access-token")) {
            this.props.history.push("/login");
        }
        this.getWebsiteConfig();
        this.getLanguages();
    }

    render() {
        console.log(this.state);
        return (
            <>
                <TextSettingsView
                    listOfLanguages={this.state.listOfLanguages}
                    languageSelected={this.state.languageSelected}
                    selectLanguageFromList={this.selectLanguageFromList}
                    changeLanguage={this.changeLanguage}
                    pageIsLoading={this.state.pageIsLoading}
                    contentConfig={this.state.contentConfig}
                    changeTextSettingsStep={this.changeTextSettingsStep}
                    textSettingsStep={this.state.textSettingsStep}
                    changeContentConfig={this.changeContentConfig}
                    updateContentConfig={this.updateContentConfig}
                />
                <ToastContainer />
            </>
        );
    }
}

export default TextSettingsContainer;
