import React, { useState, useEffect, useRef } from "react";
import { VscEdit, VscTrash } from "react-icons/vsc";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ConfirmationModal from "../confirmation-modal";
import Editor from "../editor";
import { DropdownOptions } from "../buttons";

function AnswerView(props) {
    // console.log(props.question);
    const [answerOptionModalIsOpen, changeAnswerModalState] = useState(false);

    // Handle click outside of the modal
    function useOutsideClick(ref, changeModalState) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    changeModalState(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const answerModalRef = useRef(null);
    useOutsideClick(answerModalRef, changeAnswerModalState);

    let answerList;
    if (props.question.comments !== undefined && props.question.comments !== null) {
        answerList = (props.question.comments || []).map((answer) => {
            return (
                <div key={answer.id}>
                    <div className="flex justify-between items-center">
                        <p className="text-sm text-gray-500 font-normal">Answer</p>
                        <div className={`${props.question.created_by === "app_user" ? "hidden" : "relative"}`}>
                            <HiOutlineDotsVertical
                                className="text-xl text-masala cursor-pointer hover:text-black"
                                onClick={() => changeAnswerModalState(!answerOptionModalIsOpen)}
                            />
                            <div
                                ref={answerModalRef}
                                className={`${
                                    answerOptionModalIsOpen ? "block" : "hidden"
                                } z-10 shadow border border-slate-100 absolute top-7 right-0 rounded-lg bg-white whitespace-nowrap py-3`}
                            >
                                <DropdownOptions
                                    icon={<VscEdit className="text-lg" />}
                                    buttonText={"Edit"}
                                    onClick={() => {
                                        props.handleAnswerTextChange(answer.blocks);
                                        props.handleInitialAnswerTextChange(answer);
                                        props.updateAnswerToEdit(answer.id);
                                        props.handleNewAnswerState(false);
                                        props.handleAnswerTextboxState(true);
                                    }}
                                />
                                <DropdownOptions
                                    icon={<VscTrash className="text-lg" />}
                                    buttonText={"Delete"}
                                    onClick={() => {
                                        props.changeConfirmationModalState(true);
                                        props.changeConfirmationModalType("delete-answer");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-1 answer">
                        <Editor editorInstanceId={`answer-${answer.id}`} editorInitialData={answer} readOnly={true} />

                        <p
                            className={`${
                                props.question.created_by === "app_user" ? "hidden" : "block"
                            }  text-gray-500 text-xs font-light mt-1`}
                        >
                            {answer.display_time}
                        </p>
                    </div>

                    {/* Render confirmation modal for deleting the question */}
                    {props.confirmationModalIsOpen && props.confirmationModalType === "delete-answer" ? (
                        <ConfirmationModal
                            icon={<VscTrash />}
                            text="Are you sure you want to delete this answer?"
                            buttonText="Delete"
                            onButtonClick={() =>
                                props.updateAnswer(props.questionId, answer.id, "DELETED", answer.content)
                            }
                            closeModal={props.closeConfirmationModal}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            );
        });
    }

    return <>{answerList}</>;
}

export default AnswerView;
